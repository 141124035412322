/*
 * @Description:
 * @version:
 * @Date: 2023-08-25 17:47:46
 * @LastEditTime: 2024-07-30 20:33:29
 */
// const DEVELOP_URL = `https://alpha.tootools.cn/api`;
const DEVELOP_URL = `https://test.crmeb-mer.tootools.cn/api`
// const DEVELOP_URL = `http://192.168.1.159:28238/api`;

// const PRODUCT_URL = `https://mp.tootools.cn/api`;
const PRODUCT_URL = `https://crmeb-mer.tootools.cn/api`

const DEVELOP_NODE_URL = `https://alpha.tootools.cn/node`
const PRODUCT_NODE_URL = `https://mp.tootools.cn/node`

export const HTTP_REQUEST_URL_AI = "https://uat-tooverse.tootools.cn:3999"

// let http_request_url = process.env.VUE_APP_ENV === "production" ? PRODUCT_URL : DEVELOP_URL;
// http_request_url = PRODUCT_URL;// 调试线上bug

// export const HTTP_REQUEST_URL = http_request_url
export const HTTP_REQUEST_URL = process.env.VUE_APP_ENV === "production" ? PRODUCT_URL : DEVELOP_URL
// export const HTTP_REQUEST_URL = process.env.VUE_APP_ENV === "production" ? PRODUCT_URL : PRODUCT_URL;// 调试线上bug

export const HTTP_REQUEST_URL_NODE = process.env.VUE_APP_ENV === "production" ? PRODUCT_NODE_URL : DEVELOP_NODE_URL

export const AIGC_URL =
  process.env.VUE_APP_ENV === "production" ? "https://aigc.tootools.cn/" : process.env.VUE_APP_ENV === "development" ? "https://alpha-aigc.tootools.cn/" : "http://local-aigc.tootools.cn:8082/"
console.log("-------------------当前aigc跳转路径----------------", AIGC_URL)

export const api = {
  get_font_list: "/creation/getFontList",
  get_element: `/element/index`,
  get_series: `/series/index`,
  // search: `/shopSearch`,
  search: `/creation/ip/getSeries`,
  get_ip_list: `/creation/ip/getIps`,
  post_cart_create: `/user/cart/create`,
  get_ip_detail: `/creation/ip/getSeries`,
  get_productCarrierDetailList: "/product/carrierDetailList",
  // post_productSaveDesignProduct: "/product/saveDesignProduct",
  post_productSaveDesignProduct: "/creation/saveDesign",
  get_color_list: "/creation/getColorList",
  get_productDetail: "/product/detail/",
  get_shopElementsList: "/creation/getShopElementsList",
  // get_shopElementsList: "/creation/free/getSeries/2",

  get_shopSearchList: "/getShopSearchList",
  post_uploadImage: "/upload/image/file",
  getClearSearch: "/v2/user/clean_search",
  post_setCollection: "/series/setCollections",
  postSetCollections: "/element/setCollections",
  get_elementPriceInfo: "/element/getElementPriceInfo",
  get_getTemplateInfo: "/product/getSemiCustomTempInfo/",
  get_MCCustomInfo: "/mass/getCustomInfo/",
  get_AIFace: "/toopeeks/getAIFace",
  get_carrierConfig: "/creation/getCarrierConfig",
  get_AVATAR_DETAIL: "/toopeeks/detail",
  post_UPLOAD_AVATAR: "/upload",
  get_USER_INFO_AVATAR: "/user/getOwnerInfo",
  // get_CATEGORY_LIST: "/getCategory",
  get_CATEGORY_LIST: "/creation/getCarrierCategory",
  // get_CATEGORY_PRODUCT_LIST: "/getCategoryProductList",
  get_CATEGORY_PRODUCT_LIST: "/creation/getCarriers",

  // get_PRODUCT_DETAIL: "/product/carrierDetailList",
  get_PRODUCT_DETAIL: "/creation/getCarrierDetail",

  // get_OSS_TOKEN: "/getOssToken",
  get_OSS_TOKEN: "/aigc/getOssToken",

  get_SERIES_LIST: "/creation/free/getSeries",
  get_SERIES_DETAIL: "/creation/free/getSeriesDetail",
  get_IP_LIST: "/user/getShopIpList",
  post_USER_DO_FOCUS: "/user/doFocus",
  get_IP_INFO: "/user/getIpInfo",
  post_EXPORT_CANVAS: "/exportCanvas",
  get_USER_ELEMENTS: "/user/getUserElements", // 获取用户收藏的元素
  post_DEL_AVATAR: "/toopeeks/avatarDel",
  post_DEL_USER_ELE: "/user/deleteUserElements", // 删除用户收藏的元素
  post_SAVE_USER_ELEMENTS: "/user/saveUserElements", // 保存素材至我的元素
  get_CART_PRODUCT_DESIGN_DETAIL: "/creation/getCartProductDesignDetail", // 获取购物车商品详情，包含设计JSON信息
  get_DESIGN_TEMP_LIST: "/creation/getDesignTempList", // 获取设计模板列表
  get_DESIGN_JSON: "/creation/getDesignTempJson", // 在设计模版列表，点击后，获取设计模板JSON
  post_BIND_SHARE_DESIGN_POSTER: "/creation/bindShareDesignPoster", // 保存创作中心的分享海报

  get_USER_AI_ELEMENTS: "/user/getUserAiElements", // 获取用户收藏的ai元素
  post_DEL_USER_AI_ELE: "/user/deleteUserAiElements", // 删除用户收藏的ai元素
  get_AI_DESIGN_HISTORY: "/aigc/getDesignHistory", // 获取AIGC 贴纸设计历史

  post_CART_ATTR_PRICE: "/user/cart/getCartAttrPrice", // 根据sku数量计算总价
  post_ADD_ALL_TO_CART: "/creation/setCreationAttr", // 批量加入购物车 /creation/setCreationAttr

  // 图片变高清相关功能
  post_AI_HD_IMAGE: "/aigc/txt2img", // aigc 图片变高清接口，使用aigc的文生图接口，参数固定
  get_AI_HD_IMAGE_STATUS: "/aigc/get_status", // 获取aigc，任务的状态
  get_AI_HD_IMAGE_RESULT: "/aigc/get_result", // 获取aigc，任务的结果
  delete_AI_HD_IMAGE_TASK: "/aigc/destroy", // 删除aigc高清图，任务
}

export const apiAi = {
  post_predict: "/tf-model/predict",
}
