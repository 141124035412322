<template>


  <div v-if="isArtist" class="pb-5 w-full px-3">
    <div v-for="i in itemObj" :key="i.id">
      <div class="flex justify-between pr-3">
        <span class="text-xs leading-5">{{ i.title }}</span>
        <div class="price-box">
          <div v-if="i.price && !judgePrice(i.price) == 0" class="price_icon">
            <span class="price-text">￥</span>
            <span class="price_count"> {{ i.price }}</span>
          </div>
        </div>
      </div>
      <div class="wrapper-box mt-3 mb-6">
        <div class="img_item" @click="checkedItem(item)" v-for="item in i.elements" :key="item.id" :style="{
            backgroundColor: item.bg_color_value ? item.bg_color_value : '#f5f5f5',
          }">
          <tags-item :is-padding="true" :imgValue="item.value"></tags-item>
        </div>
      </div>
    </div>
  </div>

  <div v-if="!isArtist" class="pb-5 w-full px-3">
    <template v-if="itemObj.constructor !== Array">
      <!-- <span class="text-xs leading-5">{{ itemObj.title }}</span> -->

      <div class="flex justify-between items-center">
        <div class="series-box text-xs h-[20px] flex items-center leading-4">
          <div v-if="itemObj.ip_name && itemObj.ip_name.length" class="flex items-center">
            <span>{{ itemObj.ip_name }}</span>
            <span class="mx-[3px]">-</span>
          </div>
          {{ itemObj.title }}
          <!-- IP名字 - 系列名字 -->
        </div>
        <div class="price-box price_icon" v-if="itemObj.price && !judgePrice(itemObj.price) == 0">
          <span class="price-text">￥</span>
          <span class="price_count"> {{ itemObj.price }}</span>
        </div>
      </div>

      <div class="wrapper-box mt-3 mb-6">
        <div class="img_item" @click="checkedItem(item)" v-for="item in itemObj[menuType]" :key="item.id" :style="{
            backgroundColor: item.bg_color_value ? item.bg_color_value : '#f5f5f5',
          }">
          <tags-item v-if="seriesType === 'font'" :imgValue="item.cover_img"></tags-item>
          <tags-item :is-padding="true" v-else :imgValue="item.value"></tags-item>
        </div>
      </div>
    </template>
    <template v-if="itemObj.constructor === Array">
      <span class="text-xs leading-5">{{ title }}</span>
      <div v-if="isShape" class="wrapper-box_shape mt-3 mb-6">
        <div class="img_item" @click="checkedItem(item)" v-for="item in itemObj" :key="item.id" :style="{
            backgroundColor: item.bg_color_value ? item.bg_color_value : '#f5f5f5',
          }">
          <tags-item v-if="seriesType === 'font'" :imgValue="item.cover_img"></tags-item>
          <tags-item :is-padding="true" v-else :imgValue="item.value"></tags-item>
        </div>
      </div>
      <div v-if="!isShape" class="wrapper-box mt-3 mb-6">
        <div class="img_item" @click="checkedItem(item)" v-for="item in itemObj" :key="item.id" :style="{
            backgroundColor: item.bg_color_value ? item.bg_color_value : '#f5f5f5',
          }">
          <tags-item v-if="seriesType === 'font'" :imgValue="item.cover_img"></tags-item>
          <tags-item :is-padding="true" v-else :imgValue="item.value"></tags-item>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
  import tagsItem from "@/components/content/tagsItem/tagsItem.vue";
  export default {
    props: {
      title: {
        type: String,
        default() {
          return "";
        },
      },
      isShape: {
        type: Boolean,
        default() {
          return false;
        },
      },
      fontList: {
        type: Object,
        require: true,
      },
      itemObj: {
        type: [Array, Object],
        require: true,
      },
      seriesType: {
        type: String,
        default() {
          return "";
        },
      },
      menuType: {
        type: String,
        default() {
          return "elements";
        },
      },
      isArtist: {
        type: Boolean,
        default() {
          return false;
        },
      },
    },
    components: {
      tagsItem,
    },
    computed: {},
    created() { },
    methods: {
      judgePrice(price) {
        return parseFloat(price);
      },
      checkedItem(item) {
        window._hmt.push(["_trackEvent", "clickImage", `id:${item.id},title:${item.title}`]);

        let url;
        let options;
        switch (this.seriesType) {
          case "album":
            url = item.value || item.cover_img;
            options = {
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              ipId: this.itemObj.ip_id,
              elementId: item.id,
              type: 5,
            };
            this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", { url, options });
            break;
          case "artist":
            url = item.value;
            options = {
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              ipId: this.itemObj.ip_id,
              elementId: item.id,
              type: 5,
            };
            this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", { url, options });
            break;
          case "sticker":

            // this.$logs("点击了贴纸", { ...item });

            url = item.value;
            options = {
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              elementId: item.id,
              ipId: item.ip_id || 0,
              // type: item.is_both_use ? 15 : 5,
              // seriesId: 
              type: item.is_both_use == 1 ? 5 : 15,
              series_id: item.series_id,// 每一个贴纸都添加上一个系列ID
            };

            this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", { url, options });
            break;
          case "shape":
            url = item.value;
            options = {
              value1: item.value1,
              value2: item.value2,
              value3: item.value3,
              elementId: item.id,
              ipId: item.ip_id || 0,
              type: 4,
            };

            this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", { url, options });

            break;
          case "font":
            url = item.value;
            options = {
              fontFamily: item.font_family,
              elementId: item.id,
              ipId: item.ip_id || 0,
              type: 2,
            };
            this.$bus.$emit(this.$store.state.currentPerspective + ":addFont", { url, options });
            break;
          // case "avatar":
          //   this.$bus.$emit(this.$store.state.currentPerspective + ":addSvg", {
          //     url: item.url,
          //     options: { fromAvatar: true, value1: item.value1, value2: item.value2, value3: item.value3, type: 1, styleType: item.style_type },
          //   });
          //   break;
        }
      },
    },
  };
</script>

<style lang="scss" scoped>
  .wrapper-box_shape {
    display: grid;
    grid-template-columns: repeat(6, 52px);
    grid-template-rows: repeat(auto-fill, 52px);
    gap: 8px;
    grid-auto-rows: 52px;

    .img_item {
      // background-color: #f5f5f5;
      width: 52px;
      height: 52px;
      border-radius: 6px;

      .img-size {
        // background-color: #f5f5f5;
        width: 52px;
        height: 52px;
        border-radius: 6px;
      }
    }
  }

  .wrapper-box {
    display: grid;
    grid-template-columns: repeat(4, 79px);
    grid-template-rows: repeat(auto-fill, 79px);
    gap: 12px;
    grid-auto-rows: 79px;

    .img_item {
      border: #f5f5f5 1px solid;
      overflow: hidden;
      width: 79px;
      height: 79px;
      border-radius: 6px;
      background-color: #f5f5f5;

      .img-size {
        width: 79px;
        height: 79px;
        border-radius: 6px;
      }
    }
  }


  .series-box {
    padding-top: 2px;
  }


  .price-box,
  .price_icon {

    margin-right: 12rpx;
    margin-left: auto;

    display: flex;
    border-radius: 12px;
    height: 20px;
    padding: 0.8px 11px 0 9px;
    line-height: 20px;
    background-color: #fcebb8;
    border-radius: 12px;
    
    // display: flex;
    // align-items: center;
    
    .price-text {
      font-size: 12px;
      color: #ffb400;
      line-height: 20px;
    }

    .price_count {
      font-size: 12px;
      color: #ffb400;
      line-height: 20px;
      // display: inline-block;
    }
  }
</style>