<template>
  <div class="w-full h-full flex justify-center items-center relative editor-container">
    <!-- 创作区域，canvas画布 -->
    <van-swipe ref="slider" class="w-full h-full" :touchable="false" :show-indicators="false">
      <van-swipe-item v-for="(item, index) in perspectives" :key="index" class="w-full h-full">
        <EditorCanvas :canvasId="item.key" :perspective="item.key" />
      </van-swipe-item>
    </van-swipe>

    <!-- 左上角, 智能提示 -->
    <tt-tips />

    <!-- 右下角 -->
    <ToolBar @switchPerspective="switchPerspective" />

    <!-- 字体，相关操作区域 -->
    <ColorPicker />

    <!-- 右上角 -->
    <!-- <div v-if="!mcId" class="absolute left-1/2 -translate-x-1/2 top-3 flex w-full justify-end px-3">
      <button class="text-sm h-[1.875rem] px-4 py-1 leading-4 rounded-full bg-white flex space-x-1 items-center justify-center" @click="goToSavePreview">
        <div class="leading-5"> {{ mcId }}{{ channel == "admin" ? "保存模版" : "完成设计" }}</div>
        <img class="rotate-90 w-[0.7rem]" src="@/static/arrow_top.png" alt="" />
      </button>
    </div>

    <button v-else class="text-xs px-4 py-1 leading-4 rounded-full bg-white absolute right-3 top-3 flex space-x-1 items-center justify-center" @click="goToSavePreview">
      <div class="leading-5">{{ channel == "admin" ? "保存模版" : "完成设计" }}</div>
      <img class="w-2 rotate-90" src="@/static/arrow_top.png" alt="" />
    </button> -->

    <button class="text-sm h-[1.875rem] px-4 py-1 leading-4 rounded-full bg-white absolute right-3 top-3 flex space-x-1 items-center justify-center" @click="goToSavePreview">
      <div class="leading-5">{{ channel == "admin" ? "保存模版" : "完成设计" }}</div>
      <img class="rotate-90 h-[0.45rem]" src="@/static/arrow_top.png" alt="" />
    </button>

    <!-- 左下角切换载体区域 -->
    <div v-if="!isAigdMode" class="absolute left-3 bottom-3 flex justify-between">
      <div v-show="$store.getters.getCarrierConfigSize" class="absolute mb-[2px] text-xs text-[#D6B67D] left-1/2 -translate-x-1/2 bottom-8 leading-5 w-full text-center van-multi-ellipsis--l2">
        规格: {{ $store.getters.getCarrierConfigSize }}
      </div>

      <button :class="{ 'active-btn': mcId }" class="text-xs px-4 py-1 leading-4 rounded-full bg-[#D6B67D] flex space-x-1 items-center justify-center" @click="switchCarrier">
        <div class="leading-5 text-whiteMain mr-[-3px]">更改载体</div>
        <van-icon name="arrow" color="#fff" />
      </button>
    </div>
  </div>

  <!-- 分享海报 -->
  <canvas-share class="relative bottom-[-100%] h-0" ref="posterTarget" :showPopupStatus="showPopup"></canvas-share>
</template>
<script>
import { mapMutations, mapGetters, mapActions, mapState } from "vuex"
import canvasShare from "@/components/content/canvasShare/index.vue"
import ttTips from "@/components/common/tt-tips.vue"
import { bindShareDesignPoster } from "@/api/app"
import "keen-slider/keen-slider.min.css"
import b1 from "@/static/buddha_icon.png"
import EditorCanvas from "@/components/EditorCanvas.vue"
import ToolBar from "./ToolBar.vue"
import ColorPicker from "./ColorPicker.vue"
import { Dialog, Toast } from "vant"

export default {
  data() {
    return {
      showPopup: false,
      fromAvatar: false, //是否从头像页面过来
      imgIcon: b1, // icon图片地址
      titleView: "", // 入口名
      isArtist: false, //是否为艺术家
    }
  },
  watch: {
    $route: {
      handler: async function (val) {
        // console.log("watch打印当前的路由", val);
        if (val.path.indexOf("/artist") > -1) {
          this.titleView = "切换官方自制"
        } else {
          this.titleView = "切换艺术家系列"
        }
        this.fromAvatar = this.$route.query.fromAvatar
      },
      immediate: true,
      // 深度观察监听
      // deep: true,
    },
    adminMessage: {
      handler: function (val, oldVal) {
        if (val == "savePreviewMode") {
          this.goToSavePreview()
          this.SET_ADMIN_MESSAGE(null)
        }
      },
      deep: true,
      immediate: true,
    },
    // getTemplateId: {
    //   handler: async function (val, oldVal) {
    //     console.log("watch打印当前的路由", val, oldVal)
    //     if (val || oldVal) {
    //       await this.fetchDesignJSON()
    //     }
    //   },
    //   deep: true,
    //   immediate: true,
    // },
  },
  // async created() {
  //   console.log(this.templateId, this.productId, "---------")

  //   let design_id = this.templateId || this.productId

  //   console.log(`\n 🚀🚀 %c  this.templateId, this.productId  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, design_id);
    

  //   // await this.fetchFontList()
  //   if (this.templateId || this.productId) {


  //     await this.fetchDesignJSON()
  //     // this.$bus.$emit("loadFromTemplate")
  //   }
  // },
  methods: {
    ...mapMutations(["SET_ADMIN_MESSAGE", "SET_COLOR_CONFIG", "CHANGE_MENU_STATUS", "CARRIER_SWITCH_STATUS", "SET_SHARE_CANVAS_DATA", "SET_SHARE_POSTER_IMG"]),
    ...mapActions(["fetchColorLibrary", "fetchCarrierConfig", "fetchDesignJSON", "fetchFontList", "postProductSavePosterActions"]),
    // 路由切换 艺术家 和官方
    switchRoute() {
      let currentRoute = this.$route.path
      console.log("切换路由", currentRoute)
      Dialog.confirm({
        title: "注意!",
        message: `切换至${this.titleView},会清空当前设计。`,
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          //【ID1000216】4.5 创作中心-用户-切换模式
          if (currentRoute.indexOf("/sticker") > -1) {
            this.$router.replace({
              path: "/artist/artist1",
            })
          } else if (currentRoute.indexOf("/artist") > -1) {
            this.$router.replace({
              path: "/sticker",
            })
          }
          window._hmt.push(["_trackEvent", "seriesSwitch", "switchSeries"])
          setTimeout(() => {
            window.location.reload()
          }, 500)

          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    },
    //切换载体
    switchCarrier() {

      // if (this.isAigdMode) return Toast("成品设计不支持更改载体")

      if (this.mcId) {
        Dialog.confirm({
          title: "注意!",
          message: `更改载体后将丢失所有设计，是否继续？`,
          confirmButtonColor: "#23d59c",
        })
          .then(() => {
            window._hmt.push(["_trackEvent", "carrierButton", "switchCarrier"])
            this.CARRIER_SWITCH_STATUS()
            // on confirm
          })
          .catch(() => {
            // on cancel
          })
      } else {
        // Dialog.confirm({
        //   title: "注意!",
        //   message: `更改载体后将无法撤销，是否继续？`,
        //   confirmButtonColor: "#23d59c",
        // })
        //   .then(() => {
        //     this.CARRIER_SWITCH_STATUS();

        //     // on confirm
        //   })
        //   .catch(() => {
        //     // on cancel
        //   });
        this.CARRIER_SWITCH_STATUS()
      }
    },
    getColorList() {
      const colorConfig = { ...this.getColorConfig }
      // console.log("🚀 ~ file: EditorContainer.vue:181 ~ getColorList ~ colorConfig:", colorConfig)
      return
      colorConfig.defaultColor = {
        ...colorConfig.defaultColor,
        ...this.getColorLibrary.filter(item => item.id.toString() === this.carrierConfig.color_id)[0],
      } || {
        color: "",
        id: "",
        type: "",
        title: "",
        isLocked: true,
      }
      this.SET_COLOR_CONFIG(colorConfig)
    },

    // 完成设计
    async goToSavePreview() {
      // console.time("goToSavePreview");
      // console.log(this.carrierConfig, this.carrierConfig?.right, "-------------");

      // 如果未做任何设计，不可提交， 2023-08-24, 去掉判断是否设计
      // 23-12-04, 如果是中台场景， 需要检验是否有设计内容、
      if (this.channel == "admin" && Object.values(this.objectIsEmpty).indexOf(false) === -1) {
        Toast("请至少设计一个面")
        return
      }

      if (this.channel == "admin") {
        uni.postMessage({
          data: {
            action: "savingPreviewMode",
          },
        })
      }

      Toast.loading({
        message: "正在生成设计...",
        forbidClick: true,
        duration: 0,
      })

      // 待解决: 这一步的目的
      if (this.carrierConfig?.left && this.carrierConfig?.right) {
        // this.$bus.$emit("left:exportPerspectiveCanvas");
        // this.$bus.$emit("right:exportPerspectiveCanvas");
        // this.$bus.$emit("front:renderPerspectiveCanvas");
        // this.$bus.$emit("back:renderPerspectiveCanvas");
      }
      // console.log("goToSavePreview");
      this.$bus.$emit("hideMenuPopup")

      // this.$bus.$emit(this.$store.state.currentPerspective + ":renderAllPerspectiveCanvas");
      // this.$bus.$emit(this.$store.state.currentPerspective + ":zoomIn");

      console.time("exportCanvasTime")
      for (let i = 0; i < this.perspectives.length; i++) {
        await new Promise(resolve => {
          this.$bus.$emit(this.perspectives[i].key + ":renderAllPerspectiveCanvas", resolve)
        })
      }
      console.timeEnd("exportCanvasTime")

      // TODO: 完成设计调用接口 获取制作分享海报
      // console.time("postProductSavePosterActions");
      // 瓶颈函数



      try {

        let res = await this.postProductSavePosterActions(4);
        if (!res) return Toast("保存设计失败");

        console.log("保存设计接口", res)

        this.SET_SHARE_CANVAS_DATA(res)
        console.timeEnd("postProductSavePosterActions")

        console.time("posterImg")
        console.log("canvas res--->", res)

        let posterImg = await this.$refs.posterTarget.initCanvas(res)
        // console.log(`\n 🚀🚀 %c  posterImg  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, posterImg);
        // return
        this.SET_SHARE_POSTER_IMG(posterImg)
        bindShareDesignPoster({ design_id: res.design_id, share_poster: posterImg })
      } catch (err) {
        console.log(`\n 🚀🚀 %c err `, `color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, err)
        Toast.clear()
        return Toast("保存设计失败")
      }
      // console.timeEnd("posterImg");
      Toast.clear()

      // 如果是中台的场景， 返回修改模版先传消息给中台
      if (this.channel == "admin") {
        uni.postMessage({
          data: {
            action: "savePreviewMode",
          },
        })
      }

      this.$router.push({
        name: "savePreview",
        query: {
          isArtist: this.$route.matched[1].path.indexOf("artist") > -1,
        },
      })
      window._hmt.push(["_trackEvent", "completeButton", "Complete"])
      // console.timeEnd("goToSavePreview");
    },
    editAvatar() {
      Dialog.confirm({
        message: "修改头像后会丢失当前设计，确定继续？",
        confirmButtonColor: "#23d59c",
      })
        .then(() => {
          this.$router.back()
          // on confirm
        })
        .catch(() => {
          // on cancel
        })
    },
    switchPerspective(e) {
      this.$refs.slider.swipeTo(e)
    },
    saveSnapshot() {
      this.$bus.$emit(this.currentPerspective + ":saveSnapshot")
    },
    // initPerspective() {
    //   const perspectives = ["front", "back", "left", "right"];
    //   perspectives.forEach((item, index) => {
    //     if (!this.carrierConfig[item]) {
    //       return;
    //     }
    //     this.perspectives.push({
    //       index,
    //       key: item,
    //       ...this.carrierConfig[item],
    //     });
    //   });
    // },
  },

  async mounted() {
    // await this.fetchColorLibrary()
    // await this.fetchCarrierConfig()
    // this.getColorList()
    this.fromAvatar = this.$route.query.fromAvatar
    this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1
    // this.$nextTick(() => {
    //   this.slider = new KeenSlider(this.$refs.slider, {
    //     drag: false,
    //     slides: this.perspectives.length,
    //   });
    // });
  },

  // 页面进入时
  activated() {
    this.isArtist = this.$route.matched[1].path.indexOf("artist") > -1
  },

  computed: {
    ...mapGetters(["getColorConfig", "getColorId", "getColorLibrary"]),
    ...mapState(["adminMessage", "channel", "currentPerspective", "carrierConfig", "mcId", "mcConfig", "menuStatus", "templateId", "productId", "designJSON", "objectIsEmpty", "isAigdMode"]),
    perspectives() {
      // 处理每个面的数据,以及该载体拥有哪些面
      const perspectiveKeys = ["front", "back", "left", "right"]
      let perspectiveArr = []

      perspectiveKeys.forEach((item, index) => {
        if (!this.carrierConfig[item]) {
          return
        }
        perspectiveArr.push({
          index,
          key: item,
          ...this.carrierConfig[item],
        })
      })

      return perspectiveArr
    },

    // getTemplateId() {
    //   return this.templateId || this.productId
    // }
  },
  components: {
    canvasShare,
    EditorCanvas,
    ToolBar,
    ColorPicker,
    ttTips
  },
}
</script>

<style>
.active-btn {
  bottom: 32px !important;
}

.disabled-btn {
  /* pointer-events: none !important; */
  background: #e5e5e5 !important;
  color: #b3b3b3 !important;
}

.editor-container {
  /* background: #f0f0f0; */
}

.editor-grid {
  background: #f0f0f0;
}
</style>
