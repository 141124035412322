<template>
  <div>
    <div :style="{ backgroundColor: !isSizeStatus ? '#F0F0F0' : '' }" class="main_content">

      <template v-if="!isSizeStatus">
        <img class="main_img" :src="carrierList.image" alt="" />
      </template>

      <template v-if="carouselImgs.length">
        <div class="product-content">
          <van-swipe lazy-render :loop="false" @change="getDetailIndex" ref="swipeLL" class="swiper-container"
            :show-indicators="false">
            <van-swipe-item v-for="(swiperItem, swiperIndex) in  carouselImgs" :key="swiperIndex">
              <img class="swiper-img" :src="swiperItem" alt="" />
            </van-swipe-item>
          </van-swipe>
        </div>
        <div v-if="carouselImgs.length" class=" indicator-container">
          <div class="dots-box">
            <template v-for="(itemLine, indexLine) in carouselImgs.length" :key="indexLine">
              <div :style="{ width: itemWidth }" :class="SwiperCurrentIndex === indexLine ? 'active_i' : ''"
                class="line_btm"></div>
            </template>
          </div>
        </div>
      </template>

      <div class="popup-container">
        <div class="span-content">
          <div class="span-mian-content">

            <better-scroll class="scroll-div" style="width: 100%; height: 295px">

              <!-- <div class="bar_title">
                <div style="line-height: 45px" class="left_span">
                  {{ carrierDetail.title }}
                </div>
                <div class="price-content">
                  <div style="span-align: right" class="price-all">
                    <div style="margin-right: 4px" class="zao_price">销售价</div>
                    <div style="color: #3f3f3f; font-weight: 600" class="">
                      <span style="font-size: 12px" class="span-icon">￥</span>
                      <span style="font-size: 20px" class="price">{{ attr.carrierSelect.price }}</span>
                    </div>
                  </div>
                  <div style="span-align: right" class="price-all">
                    <div style="margin-right: 4px" class="yuan_price">原价</div>
                    <div style="color: #ababab; font-weight: 600; text-decoration: line-through" class="">
                      <span style="font-size: 12px; font-weight: 400; line-height: 20px" class="yuan-icon">￥</span>
                      <span style="font-size: 20px" class="price">{{ attr.carrierSelect.ot_price }}</span>
                    </div>
                  </div>
                </div>
              </div> -->
 
              <!-- 商品名称规格 -->
              <tt-price :title="carrierDetail.title" :unique="priceData.unique" :price="priceData.price" :prices="priceData.prices" />

              <!-- 规格信息 -->
              <div class="productWinList  overflow-auto">

                <div class="box-border" v-for="(item, indexw) in attr.carrierAttr" :key="indexw">
                  <div class="font-[500] text-[14px] text-[#3f3f3f] mt-[24px] mb-[12px]">{{ item.attr_name }}</div>
                  <div v-if="item.type != 2" class=" flex flex-wrap w-full box-border items-center">
                    <div class="border border-[#bbb] text-sm py-[3.5px] px-[16.5px] mt-[7px] mr-[7px] rounded-md"
                      :class="item.index === itemn.attr ? 'on' : ''" v-for="(itemn, indexn) in item.attr_value"
                      @click="tapAttr(indexw, indexn, item.type)" :key="indexn">
                      {{ itemn.attr }}
                    </div>
                  </div>
                  <div v-else class="flex flex-wrap w-full box-border items-center ">
                    <div class="itemn2" :class="item.index === itemn.attr ? 'on2' : ''"
                      v-for="(itemn, indexn) in item.attr_value" @click="tapAttr(indexw, indexn, item.type)"
                      :key="indexn">
                      <img :src="itemn.attr" alt="">
                    </div>
                  </div>
                </div>
              </div>
              <div style="height: 200px;"></div>
            </better-scroll>
            <!-- <div v-show="!isSizeStatus" class="select_size">
              <div class="tab_control">
                <div class="tab_item">
                  <span class="font">尺码表</span>
                  <div class="line_bottom"></div>
                </div>
              </div>
              <div class="size_container">
                <img class="object-contain" :src="carrierList.carrier_size_image" alt="" />
              </div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <!-- TODO: 点击确定替换载体 -->
    <div @click="querySelect()" v-show="isSizeStatus && carrierSwitchStatus" class="start-btn-box">
      <div hover-class="start-btn-hover" class="start-btn">
        <span class="text">确定</span>
      </div>
    </div>

    <div @click="goBack()" v-show="!isSizeStatus && carrierSwitchStatus" class="start-btn-box">
      <div hover-class="start-btn-hover" class="start-btn">
        <span class="text">返回</span>
      </div>
    </div>
  </div>
</template>

<script>
import BetterScroll from "@/components/common/betterScroll.vue";
import { mapState, mapMutations, mapActions, mapGetters } from "vuex";
import { setItem } from "@/utils/utils";
import ttPrice from "@/components/common/tt-price.vue";

export default {
  data() {
    return {
      SwiperCurrentIndex: 0,
      setPriceObj: {},
      sizeList: ["尺码", "身高", "体重", "后衣长", "胸围", "肩宽"],
      imgCount: 0,
      colorCurrentIndex: 0,
      carouselImgs: [],
      // 当前载体，不同规格组合后的所有数据
			priceData: {
				unique: "",
				price: 0,
        prices: []
			}
    };
  },
  props: {
    attr: {
      type: Object,
      default() {
        return {}
      }
    },
    selectSizeIndex: {
      type: Number,
      default() {
        return 0;
      },
    },
    productSize: {
      type: [String, Number],
    },
    carrierDetail: {
      type: Object,
      default() {
        return {};
      },
    },
    isSizeStatus: {
      type: Boolean,
      require: true,
    },
    colorIndex: {
      type: Number,
      require: true,
    },
  },
  components: {
    BetterScroll,
    ttPrice
  },
  watch: {
    attr: {
      handler(newVal) {
        // console.log("🚀 ~ file: carrierDetailInfo.vue:160 ~ handler ~ newVal:", newVal)

        this.carouselImgs = []
        if (this.attr && this.carrierDetail) {

          const carrierSelect = newVal.carrierSelect;

          // this.$logs("规格选择完毕", carrierSelect);

          let { unique, price, multi_pricing:prices = [], spec_detail_image } = carrierSelect;
          this.priceData = {
            unique,
            price,
            prices,
          }

          let mainPic = this.attr.carrierSelect.spec_image
          let carousels = []
          if (this.carrierDetail.carousel_img instanceof Array) {
            carousels = this.carrierDetail.carousel_img[this.colorCurrentIndex]
          }
          if (mainPic) this.carouselImgs.push(mainPic)
          if (carousels instanceof Array && carousels.length) this.carouselImgs.push(...carousels)
        }
      },
      deep: true,
      immediate: true
    }
  },
  computed: {
    ...mapGetters(["getColorSeriesId"]),
    ...mapState(["carrierSwitchStatus", "carrierConfig"]),
    itemWidth() {
      return 140 / this.carouselImgs.length + "px";
    },
  },

  methods: {
    ...mapMutations(["CARRIER_SWITCH_STATUS", "CARRIER_SWITCH_MAIN", "SET_CARRIER_ID", "SET_UNIQUE"]),
    ...mapActions(["fetchCarrierConfig"]),
    goBack() {
      this.$emit("sizeStatus", true);
    },
    //选择规格
    tapAttr(indexw, indexn, type) {
      if (type == 2) {
        this.colorCurrentIndex = indexn
      }
      let that = this;
      that.$emit("attrVal", {
        indexw: indexw,
        indexn: indexn
      });
      this.attr.carrierAttr[indexw].index = this.attr.carrierAttr[indexw].attr_values[indexn]
      let value = that.getCheckedValue().join(",");
      that.$emit("ChangeAttr", value);
    },			//获取被选中属性；
    getCheckedValue: function () {
      let carrierAttr = this.attr.carrierAttr;
      let value = [];
      for (let i = 0; i < carrierAttr.length; i++) {
        for (let j = 0; j < carrierAttr[i].attr_values.length; j++) {
          if (carrierAttr[i].index === carrierAttr[i].attr_values[j]) {
            value.push(carrierAttr[i].attr_values[j]);
          }
        }
      }
      return value;
    },
    openSize() {
      this.$emit("sizeStatus", false);
    },

    replace(size, index) {
      console.log(size, index);
      this.$emit("changeSize", size);
      this.$emit("changeSizeIndex", index);
      this.SwiperCurrentIndex = 0;
      window._hmt.push(["_trackEvent", "selectSize", "载体尺码"]);
    },
    //点击确定按钮
    async querySelect() {
      if (!this.attr.carrierSelect.unique) return this.$toast("该载体数据有误，请重新选择");
      //通知父组件改变size
      this.$emit("sizeStatus", true);
      //通知父组件状态
      this.$emit("confirmStatus");
      //popup状态改变
      this.CARRIER_SWITCH_MAIN();
      this.CARRIER_SWITCH_STATUS();
      //载体id存到vuex
      this.SET_CARRIER_ID(this.carrierDetail.id);
      //将载体的unique存到vuex
      this.SET_UNIQUE(this.attr.carrierSelect.unique);
      setItem("carrier_id", this.carrierDetail.id);
      setItem("unique", this.attr.carrierSelect.unique);
      //初始化颜色

      // this.$emit("changeColor", 0);

      await this.fetchCarrierConfig();

      this.$bus.$emit("front:setCarrier");
      this.carrierConfig?.back && this.$bus.$emit("back:setCarrier");
      this.carrierConfig?.left && this.$bus.$emit("left:setCarrier");
      this.carrierConfig?.right && this.$bus.$emit("right:setCarrier");
      // this.isSizeStatus = true;

      // 切换载体，重置历史操作步骤
      this.$store.commit("SET_HISTORY_STEPS", {});
    },
    selectColor(indexx) {
      window._hmt.push(["_trackEvent", "selectColor", "载体颜色"]);
      this.SwiperCurrentIndex = 0;
      // this.colorIndex = indexx;
      this.$emit("changeColor", indexx);
    },

    getDetailIndex(e) {
      this.SwiperCurrentIndex = e;
    },
  },
};
</script>


<style lang="scss" scoped>
.on {
  color: #fff;
  background-color: #3F3F3F;
  border-color: #3F3F3F;
}

.itemn2 {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  margin: 12px 0 0 7px;
  border: 1px solid #FFF;

  img {
    border-radius: 50%;
    height: 30px;
    width: 30px;
  }

}

.on2 {
  border-color: #3F3F3F;
}


.active_border {
  border-color: #3f3f3f !important;
}

.main_content {
  border-radius: 12px 12px 0 0;
  height: 638px;
  overflow: hidden;

  .main_img {
    margin: 0 34px 0 34px;
    width: 307px;
    height: 307px;

    @media (min-width: 768px) {
      height: 400px;
      width: 400px;
      margin: 0 auto 0 auto;
    }
  }

  .product-content {
    margin-top: 32px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: 307px;
    border-radius: 12px;

    @media (min-width: 768px) {
      height: 400px;
    }

    .swiper-container {
      height: 307px;
      width: 310px;
      overflow: hidden;

      @media (min-width: 768px) {
        width: 400px;

        height: 400px;
      }

      .swiper-img {
        height: 100%;
        width: 100%;
        border-radius: 12px;
      }
    }
  }

  .indicator-container {
    box-sizing: border-box;
    height: 2px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    margin-bottom: 24px;
    width: 100%;

    .dots-box {
      display: flex;
      justify-content: center;
      height: 2px;
      box-sizing: border-box;

      .line_btm {
        height: 2px;
        background-color: #ababab;
      }

      .active_i {
        background-color: #272727 !important;
      }
    }
  }

  .popup-container {
    overflow: hidden;
    width: 100%;
    height: 100%;
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 0 32px;

    .span-content {
      width: 100%;
      background: #ffffff;

      .span-title {
        font-size: 14px;
        font-weight: 600;
        margin-bottom: 8px;
      }

      .price-all {
        .span-icon {
          font-weight: 300px;
          font-size: 12px;
        }

        .price {
          font-weight: 600;
          font-size: 16px;
        }
      }

      .span-mian-content {
        display: flex;
        width: 100%;

        .scroll-view {
          max-height: 258px;
        }

        .scroll-view ::-webkit-scrollbar {
          width: 0;
          height: 0;
          color: transparent;
          display: none;
        }

        .bar_title {
          padding-bottom: 12px;
          box-sizing: content-box;
          position: relative;
          display: flex;
          justify-content: space-between;

          .left_span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            /* 主要文案-2 */
            color: #3f3f3f;
          }

          .price-content {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            flex-direction: column;

            .price-all {
              display: flex;
              align-items: center;
              white-space: nowrap;
              line-height: 20px;
              color: #ffffff;
              font-size: 14px;
              font-weight: 500;

              .zao_price {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #000000;
              }

              .yuan_price {
                font-weight: 400;
                font-size: 12px;
                line-height: 20px;
                color: #ababab;
              }

              .span-icon {
                font-weight: 300px;
                font-size: 12px;
                font-weight: 400;
                line-height: 20px;
                color: #000000;
              }

              .price {
                line-height: 20px;
                font-weight: 600;
                font-size: 16px;
              }
            }
          }
        }

        .control_bar {
          margin: 12px 0;
          height: 20px;
          display: flex;
          align-items: center;
          width: 100%;

          .left_span {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
          }

          .right_box {
            width: 45px;
            white-space: nowrap;
            margin-left: auto;
            line-height: 16px;

            // display: flex;
            // align-items: center;
            // justify-content: space-between;

            .right_span {
              margin-right: 2px;
              display: inline-block;
              font-weight: 400;
              color: #ababab;
              white-space: nowrap;
              line-height: 16px;
            }

            .right_icon {
              display: inline-block;
              height: 1em;
              line-height: 16px;
              vertical-align: -0.15em;
            }
          }
        }

        .color_box::-webkit-scrollbar {
          display: none !important;
        }

        .color_box {
          margin-bottom: 32px;
          width: 100%;
          overflow-x: scroll;
          white-space: nowrap;
          height: 45px;

          .color_item {
            display: inline-block;
            overflow: hidden;
            background-color: #fff;
            padding: 2px;
            box-sizing: border-box;
            margin-right: 20px;
            border-radius: 50%;
            height: 40px;
            width: 40px;
            border: 1.5px solid #fff;

            .item-img {
              box-sizing: border-box;
              margin: 2px;
              border-radius: 50%;
              height: calc(100% - 4px);
              background: #f5f5f5;
              width: calc(100% - 4px);
            }
          }
        }

        .size_box::-webkit-scrollbar {
          display: none !important;
        }

        .size_box {
          display: flex;
          padding: 5px 0;
          flex-wrap: wrap;
          width: 100%;

          .size_item {
            margin-bottom: 5px;
            font-size: 14px;
            background: #f5f5f5;
            margin-right: 20px;
            border-radius: 16px;
            max-width: 130px;
            padding: 6px 16px;
            border: 1.5px solid #fff;

            .item-title {
              display: flex;
              font-size: 10px;
              justify-content: center;
              align-items: center;
            }
          }
        }

        .select_size {
          box-sizing: border-box;
          width: 100%;

          .tab_control {
            color: #3f3f3f;
            background-color: white;
            height: 44px;
            box-sizing: border-box;
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            border-bottom: 1px solid #f5f5f5;

            .tab_item {
              padding: 0 20px;
              height: 44px;
              line-height: 44px;
              font-size: 14px;
              font-weight: 500;
              position: relative;

              .line_bottom {
                height: 3px;
                width: 15px;
                transition-duration: 500ms;
                position: absolute;
                bottom: 2px;
                left: 50%;
                color: #3f3f3f;
                transform: translate(-50%);
                border-radius: 1.5px;
                background-color: #3f3f3f;
              }
            }
          }

          .size_container {
            @media screen and (max-width: 678px) {
              margin-top: 10px;
            }

            @media screen and (min-width: 678px) {
              height: calc(100% - 400px);
              overflow-y: scroll;
            }

            // flex-direction: column;
            // display: flex;
            // justify-content: space-around;

            .size_content {
              display: flex;
              justify-content: space-around;

              .size_content_item {
                @media screen and (max-width: 768px) {
                  height: 45px;
                }

                box-sizing: border-box;
                min-width: 50px;
                // height: 45px;
                font-size: 12px;
                display: flex;
                justify-content: center;
                align-items: center;
                white-space: nowrap;
                flex-wrap: nowrap;

                &:first-child {
                  margin-right: 10px;
                }
              }
            }

            .size_ruen_content {
              z-index: 0;
              display: flex;
              flex-direction: column;

              .szie_ruen {
                display: flex;

                justify-content: space-around;

                .szie_ruen_item {
                  flex: 1;
                  transition-duration: 300ms;
                  box-sizing: border-box;
                  font-size: 12px;
                  display: flex;
                  justify-content: center;
                  align-items: center;
                  flex-wrap: nowrap;
                  white-space: nowrap;
                  // width: 50px;
                  // height: 45px;

                  &:first-child {
                    font-size: 14px;
                    margin-right: 10px;
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}

.start-btn-box-container {
  z-index: 9999;
  box-shadow: 0px -2px 10px rgba(171, 171, 171, 0.25);
  background-color: #ffffff;
  height: 100px;
  position: absolute;
  bottom: 0;
  // margin-top: 30rpx;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition-duration: 200ms;

  .start-btn {
    z-index: 9999;
    position: absolute;
    bottom: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    height: 44px;
    width: 451px;
    background-color: #3f3f3f;

    .price-all {
      white-space: nowrap;
      line-height: 17px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;

      .text-icon {
        // line-height: 32rpx;
        font-weight: 600;
        font-size: 12px;
      }

      .price {
        line-height: 32px;
        font-weight: 600;
        font-size: 16px;
      }
    }

    .text {
      margin-left: 4px;
      white-space: nowrap;
      line-height: 17px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .bottom-title {
    position: absolute;
    top: 11px;
    // margin-top: 24rpx;
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;

    .blue-title {
      color: #438bf6;
      text-decoration-line: underline;
    }
  }
}

.start-btn-box {
  z-index: 99999;
  // box-shadow: 0px -2px 10px rgba(171, 171, 171, 0.25);
  background-color: #ffffff;
  height: 44px;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  // margin-top: 30rpx;
  width: 100%;
  display: flex;
  justify-content: center;

  .start-btn {
    z-index: 9999;
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 100px;
    height: 44px;
    width: 351px;
    background-color: #3f3f3f;

    .text {
      white-space: nowrap;
      line-height: 17px;
      color: #ffffff;
      font-size: 14px;
      font-weight: 500;
    }
  }

  .bottom-title {
    position: absolute;
    top: 11px;
    // margin-top: 24rpx;
    color: #9a9a9a;
    font-size: 12px;
    font-weight: 400;
    white-space: nowrap;

    .blue-title {
      color: #438bf6;
      text-decoration-line: underline;
    }
  }
}
</style>