/*
 * @Description: 批量处理高清抠图的工具函数
 * @Author: Malone
 * @Date: 2024-01-25 15:00:38
 */


import { addSegmentImageTasks, getSegmentImageResult } from "@/api/app";// 后端抠图API

/**
 * @description: 定义处理高清图片的函数
 * @param {*} urls
 * @param {*} needConvertToOssUrl 是否需要转换为有效的OSS地址
 * @return {*}
 */
async function processSegmentImages(urls) {

    // 批量请求高清抠图任务
    const processResult = await requestSegmentTasks(urls)
    // console.log(`\n 🚀🚀 %c  processResult  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, processResult);

    // 判断是否全部是original，如果全部是original，直接返回
    const isAllOriginal = processResult.every(task => task.status == "original");
    if (isAllOriginal) return Promise.resolve(processResult);

    // 批量查询高清抠图任务结果
    const jobResult = await querySegmentHDResult(processResult);    
    return Promise.resolve(jobResult);

}

// 批量提交高清抠图任务
async function requestSegmentTasks(urls) {

    // 获取商户id
    const mer_id = localStorage.getItem("merId");

    // 取出 axios 的 status 和 接口的status、message、data
    const { status, data: { status:code, message, data: { queue_ids = [] } } } = await addSegmentImageTasks(urls, mer_id);

    let processResult = [];

    // 失败提示
    if (status !== 200 || code !== 200) {
        processResult = urls.map((url, index) => {
            return {
                queue_id: "",
                status: "original",
                url: url,
                originalUrl: url,
                sort: index,
            }
        });
    } else {// 请求成功，返回抠图任务列表
        processResult = queue_ids.map((id, index) => {
            return {
                queue_id: id,
                status: "processing",
                url: "",
                originalUrl: urls[index],
                sort: index,
            }
        });
    };

    return Promise.resolve(processResult);
    
}

// 批量查询高清抠图任务结果, 现阶段getAsyncJobResult并发限制为200，现一次性查询全部任务结果，如果有QPS并发限制，需要修改此函数
async function querySegmentHDResult(tasks) {

    let tasksBackup = [...tasks]; // 复制一份任务列表, 存储处理完成的结果

    return new Promise(resolve => {

        async function getJobResult() {
        
            // 过滤出所有未完成的任务
            let processingTasks = tasksBackup.filter(task => task.status == "processing");
    
            // 如果所有任务都已经完成，清除定时器
            if (!processingTasks.length) {
                clearTimer(timer);
                resolve(tasksBackup);
                return;
            };

            // 获取所有未完成任务的结果，每一秒查一次
            const { status, data: { data: { result: allRes }, message, status:code }} = await getSegmentImageResult(processingTasks.map(task => task.queue_id));
            // 请求失败，直接返回结果
            if (status != 200 || code != 200) {
                clearTimer(timer);
                resolve(tasksBackup);
                return;
            };

            // 状态 0待执行； 1已请求； 2待转存； 3已完成； 4失败
            allRes.map((task, taskIndex) => {

                const { id, status: task_status, original_image_url, image_url } = task;

                if (task_status == 3) {// 任务成功，返回抠图结果
                    const taskItem = tasksBackup.find(task => task.queue_id === id);
                    const taskItemIndex = tasksBackup.findIndex(task => task.queue_id === id);
                    tasksBackup.splice(taskItemIndex, 1, {
                        ...taskItem,
                        status: "success",
                        url: image_url,
                    });
                } else if (task_status == 4) {// 其他情况，默认是失败，将原图返回
                    const taskItem = tasksBackup.find(task => task.queue_id === id);
                    const taskItemIndex = tasksBackup.findIndex(task => task.queue_id === id);
                    tasksBackup.splice(taskItemIndex, 1, {
                        ...taskItem,
                        status: "original",
                        url: original_image_url,
                    });
                };
                // 0、1、2状态不处理，继续轮询
            });

        };

        // getJobResult(); // 先执行一次

        const timer = setInterval(getJobResult, 1000); // 1秒轮询一次

        // 如果超时, 无论结果怎么样，都清除定时器，返回结果
        const timeout = 10 * 60 * 1000;// 10分钟
        setTimeout(() => {
            // 如果定时器已经被清除，直接返回
            if (!timer) return;
            clearTimer(timer);
            // 过滤出所有未完成的任务, 将未完成的任务状态改为original
            let processingTasks = tasksBackup.filter(task => task.status == "processing");
            processingTasks.map(task => {
                const taskItemIndex = tasksBackup.findIndex(taskItem => taskItem.queue_id === task.queue_id);
                tasksBackup.splice(taskItemIndex, 1, {
                    ...task,
                    status: "original",
                    url: task.originalUrl,
                });
            });
            resolve(tasksBackup);
        }, timeout);

    });
}

function clearTimer(timer) {
    clearInterval(timer);
    timer = null;
}

// 导出处理高清图片的函数
export { processSegmentImages };