<!--
 * @Description: 价格组件，按载体配置的价格进行展示，载体相关的详细价格配置
 * @version: 
-->
<template>
  <div class="tt-price">
    <div v-if="title" class="title">
      <div class="">
        {{ title }}
      </div>
      <view v-if="!priceData.length && price" class="price-all">
        <text class="text-icon">￥</text>
        <text class="price">{{ price }}</text>
      </view>
    </div>

    <div class="tt-price-content" v-if="priceData.length">
      <div class="price-wrap" :class="priceData.length > 3 && 'price-wrap-scroll'">
        <!-- <div class="price-wrap price-wrap-scroll"> -->
        <div v-for="(item, index) in priceData" class="price-item" :style="{ marginRight: title ? '30px' : '30px' }">
          <div class="price-line" :class="themeType == 2 && 'price-line-mini'">
            <span class="unit">￥</span>
            <span class="price">{{ item.price }}</span>
          </div>
          <span class="price-desc">{{ item.description }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "tt-price",
  props: {
    title: {
      type: String,
      default: "",
    },
    unique: {
      type: String,
      default: "",
    },
    price: {
      type: String,
      default: "",
    },
    prices: {
      type: Array,
      default: () => [],
    },
    themeType: {
      type: [Array, String],
      default: 1,// 默认模式，价格需要橙色，大字号着重展示。
    }
  },
  data() {
    return {
      priceData: [],
    }
  },
  computed: {},
  methods: {},
  watch: {
    unique: {
      handler(newVal) {
        // this.$logs("价格显示组件", this.price, "price")
        this.priceData = [...this.prices]
        // this.priceData = [...this.prices,...this.prices,...this.prices,...this.prices,...this.prices]
        // this.$logs("价格显示组件", this.priceData)
      },
      deep: true,
      immediate: true,
    },
  },

  // 组件周期函数--监听组件挂载完毕
  mounted() {},
  // 组件周期函数--监听组件数据更新之前
  beforeUpdate() {},
  // 组件周期函数--监听组件数据更新之后
  updated() {},
  // 组件周期函数--监听组件激活(显示)
  activated() {},
  // 组件周期函数--监听组件停用(隐藏)
  deactivated() {},
  // 组件周期函数--监听组件销毁之前
  beforeDestroy() {},
}
</script>

<style lang="scss" scoped>
.tt-price {
  display: flex;
  flex-direction: column;
  justify-content: center;
  background: #fff;
  // padding: 14px;
  padding-bottom: 0;
  width: 100%;
  height: 100%;

  ::-webkit-scrollbar {
    display: none;
  }

  .title {
    display: flex;
    align-items: center;
    justify-content: space-between;

    color: #3f3f3f;
    font-family: PingFang SC;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 20px;
    /* 142.857% */

    .price-all {
      line-height: 32px;

      .text-icon {
        // line-height: 32rpx;
        font-weight: 300px;
        font-size: 12px;
      }

      .price {
        line-height: 32px;
        font-weight: 600;
        font-size: 16px;
      }
    }
  }

  .tt-price-content {
    // background: pink;
    width: 100%;
    height: 64px;
    // overflow-x: scroll;
    overflow: hidden;
    pointer-events: none;

    .price-wrap {
      width: 100%;
      height: 100%;
      // overflow-x: scroll;
      // background: red;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      // justify-content: space-between;
    }

    .price-wrap-scroll {
      width: 100%;
      overflow-x: scroll;
      // pointer-events: none;
      .price-item {
        margin-right: 30px;
        display: inline-block;
        text-align: center;
        pointer-events: auto;
      }
    }

    .price-item {
      // width: 84px;
      // min-width: 64px !important;
      // max-width: 64px !important;

      // height: 140rpx;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      .price-line {
        display: flex;
        // align-items: flex-end;

        font-family: PingFang SC;
        font-style: normal;
        // line-height: 40rpx;
        // margin-top: 16px;
        color: #f87028;
        // text-align: center;
        display: flex;
        justify-content: center;

        .unit {
          text-align: center;
          font-size: 12px;
          font-weight: 600;
          // line-height: 40rpx;
          margin-top: 8px;
        }

        .price {
          font-size: 20px;
          font-weight: 500;
        }
      }

      .price-line-mini {
        color: #3f3f3f;
        font-weight: 500;
        margin-bottom: -4px;
        // margin-top:
        // font-size: 12px;
        .unit {
          margin-top: 7px;
        }
        .price {
          margin-top: 4px;
          font-size: 16px;
        }
      }

      .price-line-through {
        color: #ababab;
        text-align: center;
        font-family: PingFang SC;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: 20px; /* 142.857% */
        text-decoration-line: middle;
        margin-top: 4px;
        // 文本删除线
        text-decoration: line-through;
      }

      .price-desc {
        color: #3f3f3f;
        text-align: center;
        font-family: PingFang SC;
        font-size: 12px;
        font-style: normal;
        font-weight: 400;
        // line-height: 40rpx;
        margin: 6px 0;
      }
    }

  }
}
</style>
