import { createRouter, createWebHashHistory } from "vue-router";
import HomeView from "../views/HomeView.vue";

const routes = [
  {
    path: "/",
    name: "HomeView",
    component: HomeView,
    meta: {
      keepAlive: false,
    },
    children: [
      {
        path: "/",
        redirect: "/sticker",
      },
      {
        path: "artist",
        name: "ArtistView",
        component: () => import(/* webpackChunkName: "artist" */ "../views/artist/index"),
        // redirect: "/artist/artist1",
        meta: {
          keepAlive: true,
        },
        children: [
          {
            path: "album",
            name: "AlbumView",
            component: () => import("../views/artist/AlbumViewChild.vue"),
            meta: {
              keepAlive: true,
              title: "专辑",
            },
          },
          {
            path: "artist1",
            name: "Artist1View",
            component: () => import("../views/artist/ArtistViewChild.vue"),
            meta: {
              keepAlive: true,
              title: "艺术家",
            },
          },
        ],
      },
      {
        path: "sticker",
        name: "StickerView",
        component: () => import(/* webpackChunkName: "artist" */ "../views/stickerView/index.vue"),
        // redirect: "/sticker/sticker1",
        meta: {
          keepAlive: true,
        },
        children: [
          {
            path: "sticker1",
            name: "Sticker1View",
            component: () => import("../views/stickerView/StickerViewChild.vue"),
            meta: {
              keepAlive: true,
              title: "贴纸",
            },
          },
          {
            path: "font",
            name: "FontView",
            component: () => import("../views/stickerView/FontViewChild.vue"),
            meta: {
              keepAlive: true,
              title: "字体",
            },
          },
          {
            path: "shape",
            name: "ShapeView",
            component: () => import("../views/stickerView/ShapeViewChild.vue"),
            meta: {
              keepAlive: true,
              title: "形状",
            },
          },
          {
            path: "ai_avatar",
            name: "AiArtistView",
            component: () => import("../views/AiAvatarViewChild.vue"),
            meta: {
              keepAlive: true,
              title: "头像",
            },
          },
          {
            path: "matter",
            name: "matterView",
            component: () => import("../views/matter/index.vue"),
            meta: {
              keepAlive: true,
              title: "我的素材",
            },
          },
          {
            path: "template",
            name: "templateView",
            component: () => import("../views/templateView/index.vue"),
            meta: {
              keepAlive: true,
              title: "模板",
            },
          },
        ],
      },
      {
        path: "/savePreview",
        name: "savePreview",
        component: () => import(/* webpackChunkName: "savePreview" */ "../views/savePreview/index.vue"),
        meta: {
          keepAlive: true,
        },
      },
    ],
  },

  {
    path: "/avatar",
    name: "AvatarView",
    meta: {
      keepAlive: false,
      title: "Toopeeps",
    },
    component: () => import(/* webpackChunkName: "avatar" */ "../views/avatarView/index.vue"),
  },
  {
    path: "/artist_list",
    name: "ArtistList",
    meta: {
      keepAlive: true,
      title: "艺术家列表",
    },
    component: () => import(/* webpackChunkName: "avatar" */ "../views/artistList/index.vue"),
  },
  {
    path: "/ip_info",
    name: "IpInfo",
    meta: {
      keepAlive: false,
      title: "ip主页",
    },
    component: () => import(/* webpackChunkName: "avatar" */ "../views/ipInfo/index.vue"),
  },
  {
    path: "/promotion",
    name: "promotion",
    component: () => import("../views/promotion/index.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
});
router.beforeEach(async (to, from, next) => {
  if (to.path) {
    if (window._hmt) {
      window._hmt.push(["_trackPageview", "/#" + to.fullPath]);
    }
  }
  next();
});

export default router;
