import { Toast } from "vant";

export function debounce(func, timeout = 300) {
  let timer;
  return (...args) => {
    if (!timer) {
      func.apply(this, args);
    }
    clearTimeout(timer);
    timer = setTimeout(() => {
      timer = undefined;
    }, timeout);
  };
}

export function throttle(func, wait) {
  let _this, args;
  // 之前的时间戳
  let old = 0;
  return function () {
    // 保存this
    _this = this;
    //保存arguments
    args = arguments;

    // 获取当前时间戳
    let now = new Date().valueOf();
    if (now - old > wait) {
      // 立即执行
      func.apply(_this, args);
      old = now;
    }
  };
}

export function dataURLtoBlob(dataurl) {
  var arr = dataurl.split(",");
  var mime = arr[0].match(/:(.*?);/)[1];
  var bstr = atob(arr[1]);
  var n = bstr.length;
  var u8arr = new Uint8Array(n);  

  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new Blob([u8arr], { type: mime });
}

export function getItem(key) {
  return localStorage.getItem(key);
}

export function setItem(key, value) {
  localStorage.setItem(key, value);
}

export function removeItem(key) {
  localStorage.removeItem(key);
}

export function QueryString() {
  let url = window.location.hash;
  let obj = {};
  if (url.indexOf("?") !== -1) {
    let startIndex = url.indexOf("?") + 1;
    let str = url.substr(startIndex);
    let strs = str.split("&");
    for (let i = 0; i < strs.length; i++) {
      obj[strs[i].split("=")[0]] = strs[i].split("=")[1];
    }
    return obj;
  }
}

export function uploadImgs(file) {
  // 大于2.5MB的jpeg和png图片都缩小像素上传
  return new Promise((resolve, reject) => {
    let files = file;
    if (file.file.size > 2500000) {
      // 创建Canvas对象(画布)
      let canvas = document.createElement("canvas");
      // 获取对应的CanvasRenderingContext2D对象(画笔)
      let context = canvas.getContext("2d");
      // 创建新的图片对象
      let img = new Image();
      // 指定图片的DataURL(图片的base64编码数据)
      img.src = file.content;
      // 监听浏览器加载图片完成，然后进行进行绘制
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        context.drawImage(img, 0, 0, img.width, img.height);
        file.content = canvas.toDataURL(file.file.type, 0.7); // 0.92为默认压缩质量
        // files = dataURLtoFile(file.content, file.file.name);
        // console.log(`${file.file.name}图片被压缩处理,size:${file.file.size}`);
        resolve(file.content);
      };
    } else {
      resolve(files.content);
    }
  });
}

/**
 * 根据图片url转为png文件对象
 * @param url
 * @param imageName
 * @returns {Promise<unknown>}
 */
export function getImageFileFromUrl(url, imageName) {
  return new Promise((resolve, reject) => {
    var blob = null;
    var xhr = new XMLHttpRequest();
    xhr.open("GET", url.replace(/http:/, "https:")); //协议转成https
    xhr.responseType = "blob";
    // 加载时处理
    xhr.onload = () => {
      // 获取返回结果
      blob = xhr.response;
      let imgFile = new File([blob], imageName, { type: "image/png" });
      // 返回结果
      resolve(imgFile);
    };
    xhr.onerror = e => {
      reject(e);
    };
    // 发送
    xhr.send();
  });
}

export function log(key = "打印内容", value = "", type = 1) {
  type == 1 && console.log(`\n%c ${ key } `, "color: #fadfa3; background: #030307; padding:5px", "\n\n", value, "\n\n");
  type == 2 && console.log(`\n%c ${ key } `, "color: #ffffff; background: #3c9cff; padding:5px", "\n\n", value, "\n\n");
  type == 3 && console.log(`\n%c ${ key } `, "color: #ffffff; background: #6666FF; padding:5px", "\n\n", value, "\n\n");
}

export function logs() {

  let logs = [...arguments];
  if (logs.length == 0) return console.log(`\n%c 无打印内容！！ `, "color: #fadfa3; background: #030307; padding:5px");
  if (logs.length == 1) return console.log(`\n%c ${ logs[0] } `, "color: #fadfa3; background: #030307; padding:5px");

  // console.log("===logs 处理前===", ...arguments);

  let resultArr = groupList(logs, 2);
  resultArr.map(item =>  {
    console.log(`\n%c ${ item[0] } `, "color: #fadfa3; background: #030307; padding:5px", "\n\n", item[1], "\n\n");
  })

  function groupList(array, group) {
    let index = 0;
    let newArray = [];
    while (index < array.length) {
        newArray.push(array.slice(index, (index += group)));
    }
    return newArray;
  };
}
