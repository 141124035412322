import * as type from "./mutation-types"

export default {
  [type.SET_PATTERN_LIST](state, value) {
    state.patternList = value
  },
  [type.SET_FONT_LIST](state, value) {
    state.fontList = value
  },
  // 改变菜单栏的状态
  [type.CHANGE_MENU_STATUS](state, value) {
    if (value !== undefined) {
      state.menuStatus = value
    } else {
      state.menuStatus = !state.menuStatus
    }
  },
  // 切换载体状态
  //      【ID1000260】
  // 4.11.1 创作中心-用户-亲子装下一件
  [type.CARRIER_SWITCH_STATUS](state, value) {
    if (value === false || value === true) {
      state.carrierSwitchStatus = value
    } else {
      state.carrierSwitchStatus = !state.carrierSwitchStatus
    }
  },

  [type.CARRIER_SWITCH_MAIN](state, value) {
    console.log(state.carrierSwitchMain)
    state.carrierSwitchMain = !state.carrierSwitchMain
  },

  //改变头像类型
  [type.CHANGE_AVATAR_TYPE](state, value) {
    console.log(state.avatarType)
    state.avatarType = value
  },

  // 搜索区域
  [type.CHANGE_SEARCH_STATUS](state, value) {
    if (value) {
      state.searchType = value
    }
    if (value === "artist") {
      state.historyCodeType = "ip"
    } else if (value === "sticker") {
      state.historyCodeType = "element"
    }
    state.searchStatus = !state.searchStatus
  },
  [type.SET_QUERY_STRING](state, value) {
    state.productId = value.productId
    state.carrier_id = value.carrier_id
    state.store_name = value.store_name
    state.price = value.price
    state.image = value.image
    state.value1 = value.value1
    state.value2 = value.value2
    state.token = value.token
    state.stickerId = value.stickerId
    state.color_id = value.color_id
    state.x1 = value.x1
    state.y1 = value.y1
    state.x2 = value.x2
    state.y2 = value.y2
    state.carrierWidth = value.carrierWidth
    state.is_knitting = value.is_knitting
    state.avatarType = value.avatarType
    state.unique = value.unique
    state.isTemplate = value.isTemplate
    state.templateId = value.templateId
    state.mcId = value.mcId
    state.mcMenu = value.mcMenu
    state.extAvatarId = value.extAvatarId
    state.channel = value.channel
    state.isChild = value.isChild
    state.seriesId = value.seriesId
    state.isIndex = value.isIndex
    state.actName = value.actName
    state.designProductId = value.designProductId
    state.currentCateId = value.currentCateId
    state.merId = value.merId
    state.aigcImg = value.aigcImg
    // state.aigdImg = value.aigdImg;

    // console.log(" state.isChild", state.isChild);
    // console.log("state.seriesId", state.seriesId);
  },

  [type.SET_CARRIER_ID](state, value) {
    state.carrier_id = value
  },
  [type.SET_UNIQUE](state, value) {
    state.unique = value
  },
  [type.SET_DESIGN_JSON](state, value) {
    state.designJSON = value
  },
  [type.SET_COLOR_LIBRARY](state, value) {
    state.colorLibrary = value
  },
  [type.SET_COLOR_CONFIG](state, value) {
    state.colorConfig = value
  },
  [type.SET_ACTIVE_COLOR_KEY](state, value) {
    state.activeColorKey = value
  },
  [type.CHANGE_ADD_SVG](state, value) {
    state.addSvgStatus = false
  },
  [type.SET_PRODUCT_ID](state, value) {
    state.productId = value
  },
  [type.SET_SLIDER_VAL](state, value) {
    state.sliderVal = value
  },
  [type.SET_AVATAR_PART_MAP](state, value) {
    state.avatarPartMap = value
  },
  [type.SET_AVATAR_SVG](state, value) {
    state.avatarSvg = value
  },
  [type.SET_AVATAR_COLOR](state, value) {
    state.avatarColor.value1 = value.value1
    state.avatarColor.value2 = value.value2
    state.avatarColor.value3 = value.value3
  },
  // 保存设计的id数组
  [type.SET_AVATAR_ELEMENT_IDS](state, value) {
    state.avatarElementIds = value
  },
  [type.CHANGE_AVATAR_ELEMENT_ID](state, value) {
    state.avatarElementIds[value.index] = value.id
  },

  [type.SET_CANVAS_OBJECTS](state, value) {
    state.canvasObjects = value
  },
  [type.CHANGE_EXCLUDE_STICKER](state, value) {
    state.excludeSticker = value
  },
  [type.SET_OUTPUT_CANVAS_IMAGE](state, value) {
    state.outputCanvasImage = value
  },
  [type.SET_OUTPUT_PREVIEW_IMAGE](state, value) {
    state.outputPreviewImage = value
  },
  [type.SET_OUTPUT_AVATAR_IMAGE](state, value) {
    state.outputAvatarImage = value
  },
  [type.SET_OUTPUT_AVATAR_SVG](state, value) {
    state.outputAvatarSVG = value
  },
  [type.SET_BACKGROUND_ID](state, value) {
    state.background_id = value
  },
  [type.SET_AVATAR_ID](state, value) {
    state.avatarId = value
  },
  [type.SET_MC_CONFIG](state, value) {
    state.mcConfig = value
  },
  [type.SET_CURRENT_PERSPECTIVE](state, value) {
    state.currentPerspective = value
  },
  [type.SET_CURRENT_IP_ID](state, value) {
    state.currentIpId = value
  },
  [type.SET_LEFT_SLEEVE_IMAGE](state, value) {
    state.leftSleeveImage = value
  },
  [type.SET_RIGHT_SLEEVE_IMAGE](state, value) {
    state.rightSleeveImage = value
  },
  [type.SET_CARRIER_CONFIG](state, value) {
    state.carrierConfig = value
  },
  [type.SET_OUTPUT_CANVAS_IMAGES](state, value) {
    state.outputCanvasImages = value
  },
  [type.SET_OUTPUT_PREVIEW_IMAGES](state, value) {
    state.outputPreviewImages = value
  },
  [type.SET_DESIGN_JSONS](state, value) {
    state.designJSONs = value
  },
  [type.SET_ELEMENT_IDS](state, value) {
    state.elementIds = value
  },
  [type.SET_IP_IDS](state, value) {
    state.ipIds = value
  },
  [type.SET_ZOOM_TOGGLE](state, value) {
    state.zoomToggle = value
  },
  [type.INIT_AVATAR_DATA](state, value) {
    state.extAvatarId = ""
    state.avatarId = ""
  },
  [type.SET_STICKER_INFO](state, value) {
    state.stickerInfo = value
  },
  [type.SET_OBJECT_IS_EMPTY](state, value) {
    state.objectIsEmpty = value
  },
  [type.SET_CHILD_STATUS](state, value) {
    console.log("修改载体类型", value)

    state.isChild = value
  },
  [type.SET_IS_DRAWING_MODE](state, value) {
    state.isDrawingMode = value
  },
  [type.SET_ACTIVE_COLOR](state, value) {
    state.activeColor = value
  },
  [type.CLEAR_STICKER_ID](state, value) {
    state.stickerId = ""
  },
  [type.SET_CARRIER_SIZE](state, value) {
    state.carrierSize = value
  },

  // 设置 排版方式 菜单的状态
  [type.SET_ARRANGE_COUNT](state, value) {
    state.arrangeCount = value == undefined ? 0 : value
    state.menuStatus = state.arrangeCount ? true : false
  },

  // 设置，用户上传图片操作状态
  [type.CHANGE_INTERCEPT_STATUS](state, value) {
    state.interceptStatus = value
    state.menuStatus = value == "hide" ? false : true
  },

  // 设置，当前操作的用户上传图片url
  [type.CHANGE_INTERCEPT_IMG](state, value) {
    state.interceptImg = value || {
      imgUrl: "", // 用于在画布上展示的图片url
      originImgUrl: "", // CND OSS 原图url
      segmentedImgUrl: "", // 上一次一键抠图的结果图
      croppedImgUrl: "", // 上一次的图片url
    }
  },

  [type.SET_UPLOADED_IMAGE](state, value) {
    state.uploadedImage = value
  },
  [type.SET_CATE_ID](state, value) {
    state.currentCateId = value
  },

  //添加生成设计海报的数据
  [type.SET_SHARE_CANVAS_DATA](state, value) {
    state.shareDesignPosterData = value
    console.log("state.shareDesignPosterData-->", state.shareDesignPosterData)
  },
  //生成设计海报图片
  [type.SET_SHARE_POSTER_IMG](state, value) {
    state.sharePosterImg = value
  },

  // 是否添加了不共用贴纸
  [type.SET_ADDED_NOT_USE_STICKER](state, value) {
    state.hasAddedNotUseSticker = value
  },
  // 是否需要清除不共用贴纸
  [type.SET_REMOVE_NOT_USE_STICKER](state, value) {
    state.removeNotUseSticker = value
  },
  // 设置历史操步数
  [type.SET_HISTORY_STEPS](state, value) {
    // console.log("设置历史操作步骤", value);
    let { historyUndo = [], historyRedo = [] } = value
    state.historyUndo = historyUndo.length
    state.historyRedo = historyRedo.length
  },

  [type.SET_AIGD_INFO](state, value) {
    state.aigdInfo = value
  },

  [type.SET_AIGD_MODE](state, value) {
    state.isAigdMode = value
  },

  [type.SET_AIGC_INFO](state, value) {
    state.aigcInfo = value
  },

  [type.SET_AIGC_INFOS](state, value) {
    state.aigcInfos = value;
  },

  // 设置中台消息
  [type.SET_ADMIN_MESSAGE](state, value) {
    state.adminMessage = value
  },

  // 设置菜单功能
  [type.SET_MENUS](state, value) {
    state.menus = value
  },

  // 设置提示类型
  [type.SET_TIPS_TYPE](state, value) {
    state.tipsType = value || "random"
  },

  // 设置最后一次设计时间
  [type.SET_LAST_DESIGN_TIMESTAMP](state, value) {
    state.lastDesignTimestamp = value
  } 
}
