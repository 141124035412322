<template>
  <div class="container">
    <my-popup heightStyle="calc(60vh)" @closeMask="CARRIER_SWITCH_STATUS" :checked="carrierSwitchStatus">
      <!-- 载体选择区域 -->
      <van-tabs shrink v-model:active="active" @change="handleCarrier" color="#3F3F3F" title-active-color="#3F3F3F" swipeable>
        <van-tab v-for="(item, index) in carrierInfoList" :title="item.cate_name" :key="index">
          <div class="product_container">
            <better-scroll ref="betterTarget" :isPullUpLoad="false" style="height: calc(60vh - 60px)">
              <van-loading v-show="showLoading" class="z-40 absolute top-1/4 left-1/2 -translate-x-1/2 -translate-y-1/2" vertical color="#3F3F3F">加载中...</van-loading>
              <block v-show="!showLoading">
                <product-card @itemDetail="getCarrierType" class="card_item" :key="i.id" :cardItem="i" v-for="i in CategoryProductList"></product-card>
              </block>
              <div style="height: 50px"></div>
            </better-scroll>
          </div>
        </van-tab>
      </van-tabs>
    </my-popup>
    <my-popup @closeMask="closeMainMask" heightStyle="calc(93vh - 20px)" :checked="carrierSwitchMain">
      <carrier-detail-info
        @changeSize="getCurrentSize"
        @sizeStatus="changeSizeStatus"
        @changeSizeIndex="getSizeIndex"
        @confirmStatus="carrierType"
        @ChangeAttr="ChangeAttr"
        @changeColor="onChangeColor"
        @attrVal="attrVal"
        :selectSizeIndex="selectSizeIndex"
        :productSize="productSize"
        :isSizeStatus="isSizeStatus"
        :carrierDetail="carrierInfo"
        :attr="attr"
        :colorIndex="currentColorIndex"
      >
      </carrier-detail-info>
    </my-popup>
  </div>
</template>

<script>
import { setItem } from "@/utils/utils"
import myPopup from "@/components/common/myPopup/myPopup.vue"
import betterScroll from "@/components/common/betterScroll"
import productCard from "./components/productCard"
import carrierDetailInfo from "./components/carrierDetailInfo.vue"
import { getCategory, getCategoryProductList, getCategoryDetail, carrierDetailList } from "@/api/app"
import { mapState, mapMutations } from "vuex"
export default {
  data() {
    return {
      showLoading: false,
      active: 0,
      categoryId: 0,
      categoryList: [],
      carrierInfoList: [],
      CategoryProductList: [],
      carrierIndex: 0,
      tabCurrentIndex: 0,
      cateId: 0,
      isShow: false,
      productId: 0,
      isSizeStatus: true,
      productSize: "",
      selectSizeIndex: 0,
      is_Child: "",
      currentColorIndex: 0,
      carrierValue: {}, // 载体配置sku
      carrierInfo: {}, //选中载体数据
      attr: {
        carrierSelect: {},
        carrierAttr: [],
        attrStatus: false,
      },
      uniqueValue: "",
      attrValue: [],
    }
  },
  components: {
    myPopup,

    productCard,
    betterScroll,
    carrierDetailInfo,
  },
  computed: {
    ...mapState(["carrierSwitchStatus", "carrierSwitchMain", "seriesId", "mcId", "isChild", "isIndex", "actName", "currentCateId"]),
  },
  created() {
    // this.getinfoAll()
  },
  watch: {
    carrierSwitchStatus: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && !this.carrierInfoList.length) {
          this.getinfoAll()
        }
      }
    }
  },
  methods: {
    ...mapMutations(["CARRIER_SWITCH_STATUS", "CARRIER_SWITCH_MAIN", "SET_CHILD_STATUS", "CHANGE_MENU_STATUS", "SET_CARRIER_SIZE"]),

    getCarrierType(value) {
      let { item } = value
      // this.is_Child = is_child;
      this.handleClickProduct(item)
    },
    /**
     * 属性变动赋值
     *
     */
    ChangeAttr(res) {
      let carrierSelect = this.carrierValue[res]
      // console.log('this.attr.carrierSelect' , this.attr.carrierSelect);
      // console.log( 'this.carrierInfo', this.carrierInfo);
      // console.log('获取的配置sku',res);
      if (carrierSelect) this.uniqueValue = carrierSelect.unique
      console.log("🚀 ~ file: carrierInfo.vue:95 ~ ChangeAttr ~ carrierSelect:", carrierSelect)

      if (carrierSelect && carrierSelect.stock > 0) {
        this.attr.carrierSelect.cover_image = carrierSelect.cover_image ? carrierSelect.cover_image : this.carrierInfo.image
        console.log("🚀 ~ file: carrierInfo.vue:97 ~ ChangeAttr ~ carrierSelect:", carrierSelect)

        this.attr.carrierSelect.spec_image = carrierSelect.spec_image
        this.attr.carrierSelect.price = carrierSelect.price2
        this.attr.carrierSelect.multi_pricing = carrierSelect.multi_pricing || [] // 处理多规则的价格
        this.attr.carrierSelect.stock = carrierSelect.stock
        this.attr.carrierSelect.unique = carrierSelect.unique
        this.uniqueValue = carrierSelect.unique
        this.attrValue = res
      } else {
        this.attr.carrierSelect.cover_image = carrierSelect.cover_image || carrierSelect.cover_img
        this.attr.carrierSelect.price = carrierSelect.price
        this.attr.carrierSelect.multi_pricing = carrierSelect.multi_pricing || [] // 处理多规则的价格
        this.attr.carrierSelect.stock = 0
        this.attr.carrierSelect.unique = ""
      }
      // console.log('this.attr.carrierSelect' , this.attr.carrierSelect);
      // console.log( 'this.carrierInfo', this.carrierInfo);
      // console.log('获取的配置sku',res);
    },
    attrVal(val) {
      this.attr.carrierAttr[val.indexw].index = this.attr.carrierAttr[val.indexw].attr_values[val.indexn]
    },
    carrierType() {
      //储存当前的载体尺码
      this.SET_CARRIER_SIZE(this.productSize)
      if (this.actName != "mini") {
        console.log("当前不是亲子活动")
        this.SET_CHILD_STATUS(this.is_Child)
        setItem("isChild", this.is_Child)
        this.$bus.$emit("refresh")
      } else {
        console.log("当前是亲子活动")
      }
    },
    getCurrentSize(size) {
      this.productSize = size
    },
    getSizeIndex(index) {
      this.selectSizeIndex = index
    },
    changeSizeStatus(status) {
      // this.productSize = "";
      //尺码表状态
      this.isSizeStatus = status
    },
    closeMainMask() {
      setTimeout(() => {
        this.isSizeStatus = true
        this.productSize = ""
      }, 300)

      this.CARRIER_SWITCH_MAIN()
    },
    handleClickProduct(item) {
      console.log("item", item)

      this.productId = item.id
      this.CARRIER_SWITCH_MAIN()
      this.carrierDetailList()
    },
    async getinfoAll() {
      console.log(`\n 🚀🚀 %c  切换载体弹窗，初始化载体分类及信息  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`);
      await this.getCategoryList()
      await this.getCategoryDetail()
      await this.getCategoryProductList()
      // await this.carrierDetailList();
    },
    getCategoryList() {
      const params = {
        id: 0,
        params: {},
      }

      this.seriesId && (params.params.series_id = this.seriesId)
      this.isChild && (params.params.is_child = this.isChild)
      this.isIndex && (params.params.is_index = this.isIndex)
      this.mcId && (params.params.custom_id = this.mcId)
      return getCategory(params).then(res => {
        this.categoryList = res.data.data
        this.categoryId = this.categoryList[this.tabCurrentIndex]?.id
      })
    },
    getCategoryDetail() {
      const params = {
        id: this.categoryId,
        params: {},
      }
      this.seriesId && (params.params.series_id = this.seriesId)
      this.isIndex && (params.params.is_index = this.isIndex)

      this.mcId && (params.params.custom_id = this.mcId)
      this.isChild && (params.params.is_child = this.isChild)
      this.currentCateId && (params.params.cid = this.currentCateId)
      //       【ID1000345】
      // 更改载体，可以选择所有载体

      return getCategoryDetail(params).then(res => {
        this.carrierInfoList = res.data.data
        let filterIndex = this.carrierInfoList.findIndex(item => {
          return item.is_check === 1
        })
        if (filterIndex > -1) {
          this.carrierIndex = filterIndex
        }
        this.cateId = this.carrierInfoList[this.carrierIndex].id
      })
    },
    getCategoryProductList() {
      this.showLoading = true
      const params = {
        id: this.cateId,
        params: {},
      }
      this.isIndex && (params.params.is_index = this.isIndex)
      this.seriesId && (params.params.series_id = this.seriesId)
      this.mcId && (params.reparams.custom_id = this.mcId)
      this.isChild && (params.params.is_child = this.isChild)
      return getCategoryProductList(params).then(res => {
        this.CategoryProductList = res.data.data
        // this.productId = res.data.data.list[0]?.id;

        this.productId = res.data.data[0]?.id
        this.showLoading = false
        this.$refs.betterTarget[0].refresh()
        // console.log('this.', this.CategoryProductList);
      })
    },

    // 点击选择载体
    handleCarrier() {
      this.showLoading = true
      let index = this.active
      let item = this.carrierInfoList[index]
      this.cateId = item.id
      this.carrierIndex = index
      this.getCategoryProductList()
      //切换载体类型必须重置尺码
      this.currentColorIndex = 0
    },
    // 点击tab接受index
    async handleTab(id, index) {
      this.categoryId = id
      this.tabCurrentIndex = index
      this.carrierIndex = 0
      await this.getCategoryDetail()
      await this.getCategoryProductList()
    },
    // 获取载体详情列表
    carrierDetailList() {
      let config = {}
      if (this.seriesId) {
        config.series_id = this.seriesId
      }
      config.carrier_id = this.productId
      console.log("🚀 ~ file: carrierInfo.vue:252 ~ carrierDetailList ~ config:", config)

      return carrierDetailList(config).then(res => {
        this.$log("🚀 ~ file: carrierInfo.vue:248 ~ returncarrierDetailList ~ res:", res.data.data)

        this.carrierInfo = []
        this.carrierInfo = res.data.data
        // console.log('this.carrierInfo111',this.carrierInfo);

        this.attr.carrierAttr = res.data.data.attr
        this.carrierValue = res.data.data.sku
        // this.attr.attrStatus = true
        this.DefaultSelect()
      })
    },
    //默认选中属性
    DefaultSelect: function () {
      let carrierAttr = this.attr.carrierAttr
      console.log("🚀 ~ file: carrierInfo.vue:261 ~ carrierAttr:", carrierAttr)

      let value = []
      let arr = []

      for (var key in this.carrierValue) {
        if (this.carrierValue[key].stock > 0) {
          value = this.attr.carrierAttr.length ? key.split(",") : []
          break
        }
      }

      for (let i = 0; i < carrierAttr.length; i++) {
        carrierAttr[i].index = value[i]
        // console.log('value', value[i]);
        this.$logs(carrierAttr[i].index)
      }

      //sort();排序函数:数字-英文-汉字；
      let carrierSelect = this.carrierValue[value.join(",")]
      if (carrierSelect && carrierAttr.length) {
        // console.log("🚀 ~ file: carrierInfo.vue:284 ~ carrierSelect:", carrierSelect)

        this.attr.carrierSelect.store_name = this.carrierInfo.title
        this.attr.carrierSelect.cover_image = carrierSelect.cover_image ? carrierSelect.cover_image : this.carrierInfo.image
        this.attr.carrierSelect.spec_image = carrierSelect.spec_image

        this.attr.carrierSelect.price = carrierSelect.price
        this.attr.carrierSelect.multi_pricing = carrierSelect.multi_pricing || [] // 处理多规则的价格
        this.attr.carrierSelect.ot_price = carrierSelect.ot_price
        this.attr.carrierSelect.stock = carrierSelect.stock
        this.attr.carrierSelect.unique = carrierSelect.unique

        this.uniqueValue = carrierSelect.unique
        this.attrValue = value.join(",")
      }
      // this.$logs('this.attr.carrierSelect', this.attr.carrierSelect);
    },
    onChangeColor(currentIndex) {
      this.currentColorIndex = currentIndex
    },

    // 设定Attr的属性值
    setAttrValue(key, value) {
      this.$set(this.attr.carrierSelect, key, value)
    },
  },
}
</script>

<style lang="scss" scoped>
.container {
  background-color: #fff;
  z-index: 9999;

  .tab_control {
    color: #ababab;
    background-color: white;
    height: 44px;
    box-sizing: border-box;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    align-items: center;

    border-bottom: 1px solid #f5f5f5;

    .tab_item {
      box-sizing: border-box;
      padding: 0 20px;
      height: 44px;
      line-height: 44px;
      font-size: 14px;
      font-weight: 500;
      position: relative;

      .line_bottom {
        box-sizing: border-box;

        height: 3x;
        width: 15px;
        transition-duration: 500ms;
        position: absolute;
        bottom: 2px;
        left: 50%;
        transform: translate(-50%);
        border-radius: 1.5px;
      }
    }
  }

  .carrier_container ::-webkit-scrollbar {
    /*隐藏滚轮*/
    display: none;
  }

  ::-webkit-scrollbar {
    display: none;
  }

  .carrier_container {
    box-sizing: content-box;
    position: relative;
    background-color: white;
    width: 100%;
    height: 48px;
    display: flex;
    // justify-content: space-evenly;
    text-align: center;
    white-space: nowrap;
    overflow-x: scroll;
    overflow-y: hidden;
    align-items: center;
    /*解决ios上滑动不流畅*/
    -webkit-overflow-scrolling: touch;

    .carrier_item {
      padding: 0 20px;
      display: inline-block;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      align-items: center;

      .item_text {
        overflow: hidden;
        margin-bottom: 16rpx;
        font-size: 14px;
        line-height: 20px;
        font-weight: 500;
        color: #ababab;
      }

      .line_style {
        position: absolute;
        bottom: 1px;
        height: 3px;
        width: 15px;
        border-radius: 1.5px;
      }
    }

    .carrier_active .line_style {
      background-color: #3f3f3f !important;
    }

    .carrier_active .item_text {
      color: #3f3f3f;
    }
  }

  .product_container {
    box-sizing: border-box;
    padding: 12px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 12px;
    background: #f7f7f7;

    .card_item {
      margin-bottom: 16px;
    }
  }
}
</style>
