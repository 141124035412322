<template>
  <div style="width: 100%; height: 100%">
    <template v-if="isShape">
      <div class="container">
        <van-image width="100%" height="100%" fit="contain" :src="$setOss(imgValue)" />
      </div>
    </template>
    <div v-if="isPadding" class="container_ll">
      <template v-if="!isShape">
        <van-image width="100%" height="100%" fit="contain" :src="$setOss(imgValue)" />
      </template>
    </div>
    <div v-if="!isPadding" class="container_le">
      <template v-if="!isShape">
        <van-image width="100%" height="100%" fit="contain" :src="$setOss(imgValue)" />
      </template>
    </div>
  </div>
</template>

<script>
  export default {
    methods: {
    },
    props: {
      //当前的图片
      imgValue: String,

      isShape: {
        type: Boolean,
        default: false,
      },
      //是否有padding
      isPadding: {
        type: Boolean,
        default() {
          return false;
        },
      },
    }
  };
</script>

<style lang="scss" scoped>
  .container {
    width: 100%;
    height: 100%;
    padding: 5px;
  }

  .container_ll {
    width: 100%;
    height: 100%;
    padding: 8px;
  }

  .container_le {
    border-radius: 6px;
    width: 100%;
    height: 100%;
  }
</style>