<!--
 * @Description: 创作中心提示组件
 * @version: 
-->
<template>
  <div v-if="!isAigdMode" class="tt-tips" :style="!isTipsShow && 'left: 4px'">
    <img class="icon" src="@/static/icons/tips-icon.png" alt="" @click="onChangeTipsStatus(!isTipsShow)" />
    <div class="tips-box" v-show="isTipsShow">
      <div class="box-content">
        <div class="notice-swipe">

          <!-- 提示信息内容 -->
          <div class="notice-content">
            <div class="content" v-for="(tip, index) in showTips" :key="index"
              :style="{ opacity: currentIndex === index ? 1 : 0, height: currentIndex === index ? '30px' : 0 }">
              <span class="tips">{{ tip.content }}</span>
            </div>
          </div>
        </div>

        <!-- 关闭按钮 -->
        <img src="@/static/close.jpg" alt="" class="close-icon" @click="onChangeTipsStatus(!isTipsShow)" />

      </div>
    </div>
  </div>
</template>

<script>

import { mapState, mapMutations, mapActions } from "vuex";
export default {
  name: "tt-tips",
  data() {
    return {
      // "show-multiple-select-tips", "show-image-tips", "show-color-tips", "random", "hide"
      tips: [
        {
          id: 0,
          content: "最多框选100个元素进行一键排列",
          type: "show-multiple-limit-tips",
        },
        {
          id: 1,
          content: "框选多张图片后，可一键排列",
          type: "show-multiple-select-tips",
        },
        {
          id: 2,
          content: "没有灵感的话，可以去AI工具玩一下哦",
          type: "random",
        },
        {
          id: 3,
          content: "点击图片后，即可对图片进行抠图",
          type: "show-image-tips",
        },
        {
          id: 4,
          content: "选中对应照片，可以进行图像裁切噢",
          type: "random",
        },
        {
          id: 5,
          content: "点击右侧第一个工具，可保存当前图像",
          type: "random",
        },
        {
          id: 6,
          content: "点击调色盘，可对形状/字体改颜色",
          type: "show-color-tips",
        },
        {
          id: 7,
          content: "点击更改载体，可选择其他载体规格",
          type: "random",
        },
        {
          id: 8,
          content: "将设计加入购物车后，会弹出分享海报",
          type: "random",
        },
        {
          id: 9,
          content: "可以切换载体各个面进行设计",
          type: "random",
        },
        {
          id: 10,
          content: "可以上传自己的照片进行私人订制哦",
          type: "random",
        }
      ],

      isTipsShow: false,

      currentIndex: 0,
      scrollTimer: null,// 滚动通知的定时器
      autoTipsTimer: null,// 自动弹出提示信息的定时器

      autoShowTipsTimer: null,// 未创作时，自动弹出提示信息的定时器
      lastHistoryCount: 0,// 上一次的历史记录数量
    }
  },
  computed: {
    ...mapState(["tipsType", "historyRedo", "historyUndo", "isAigdMode", "lastDesignTimestamp"]),
    showTips() {
      const showTips = this.tips.filter(tip => tip.type === this.tipsType);
      return showTips;
    },
    historyCount() {
      return this.historyRedo + this.historyUndo;
    },
  },
  methods: {
    ...mapMutations(["SET_TIPS_TYPE"]),
    onChangeTipsStatus(status) {
      this.isTipsShow = status;
      if (!status) this.clearScrollTimer();// 切换为隐藏的时候, 清除定时器
      this.SET_TIPS_TYPE(status ? "random" : "hide");
    },
    // 清除滚动通知的定时器
    clearScrollTimer() {
      this.scrollTimer && clearInterval(this.scrollTimer);
      this.scrollTimer = null;
      this.currentIndex > 0 && (this.currentIndex = 0);
    },
    // 清除自动弹出提示信息的定时器
    clearAutoTipsTimer() {
      this.autoTipsTimer && clearTimeout(this.autoTipsTimer);
      this.autoTipsTimer = null;
    },
    // 设定一个5秒执行一次的定制器，当5秒内历史记录未变化，则自动弹出提示信息
    setAutoShowTipsTimer() {

      this.clearAutoShowTipsTimer();

      // 如果现在的时间 减去 上次设计时间 超过5秒钟，则自动弹出提示信息， 且5秒执行一次
      this.autoShowTipsTimer = setInterval(() => {

        if (this.isTipsShow) {
          this.clearAutoShowTipsTimer();
          console.log("已展示，不需要弹出提示信息");
          return;
        }

        if (new Date().getTime() - this.lastDesignTimestamp > 5000) {
          console.log("超过5s未设计，自动弹出提示信息");
          this.onChangeTipsStatus(true);
        } else {
          console.log("5s内有设计，不需要弹出提示信息");
        }
      }, 5000);

    },
    clearAutoShowTipsTimer() {
      this.autoShowTipsTimer && clearInterval(this.autoShowTipsTimer);
      this.autoShowTipsTimer = null;
    } 
  },
  watch: {
    // 监听通知内容的变化
    showTips: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.clearAutoTipsTimer();
        this.clearScrollTimer();
        const length = newValue.length;
        this.isTipsShow = length > 0;
        if (length > 1) {
          if (this.scrollTimer) clearInterval(this.scrollTimer);
          this.scrollTimer = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.showTips.length;
          }, 3500); // 切换通知的时间间隔为5秒
        };
      }
    },
    // 监听通知显示状态的变化
    isTipsShow: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        this.clearAutoTipsTimer();
        this.clearScrollTimer();
        if (newValue && this.showTips.length > 1) {
          this.scrollTimer = setInterval(() => {
            this.currentIndex = (this.currentIndex + 1) % this.showTips.length;
          }, 3500); // 切换通知的时间间隔为5秒
        } else if (!newValue) {
          console.log("关闭了，检测5秒内是否有设计");
          this.setAutoShowTipsTimer();
        };
      }
    },
  },

  // 组件周期函数--监听组件挂载完毕
  mounted() {

    // 组件挂载2秒后，自动弹出一次提示信息
    if (this.historyCount == 0 && !this.isTipsShow && !this.autoTipsTimer && this.tipsType === "hide") {
      this.clearAutoTipsTimer();
      this.autoTipsTimer = setTimeout(() => {
        this.onChangeTipsStatus(true);
        console.log("进入后，隔2s自动弹出提示信息");
      }, 2000);
    };

    // 设定一个5秒执行一次的定制器，当5秒内历史记录未变化，则自动弹出提示信息
    // this.setAutoShowTipsTimer();

  },
  // 组件周期函数--监听组件数据更新之前
  beforeUpdate() { },
  // 组件周期函数--监听组件数据更新之后
  updated() { },
  // 组件周期函数--监听组件激活(显示)
  activated() { },
  // 组件周期函数--监听组件停用(隐藏)
  deactivated() { },
  // 组件周期函数--监听组件销毁之前
  beforeDestroy() { },
}
</script>

<style lang="scss" scoped>
.tt-tips {

  transition: all .2s ease-in-out;

  position: fixed;
  left: 12px;
  top: 50px;
  min-width: 26px;
  height: 26px;

  display: flex;
  justify-content: flex-start;
  align-items: center;

  .icon {
    width: 26px;
    height: 26px;
  }

  .tips-box {
    padding-left: 10px;
    min-height: 30px;
    overflow-y: hidden;
    min-width: 50px;

    .box-content {
      // 渐变边框，从左至右，#A3BEF4 -> #35EAB0
      background: linear-gradient(90deg, #A3BEF480, #35EAB080);
      min-height: 30px;
      max-height: 34px;
      min-width: 30px;
      width: auto;
      position: relative;
      border-radius: 7px;
      max-width: 70vw;

      display: flex;

      align-items: center;

      // 左边三角形伪元素
      &::before {
        content: '';
        position: absolute;
        left: -5px;
        top: 50%;
        transform: translateY(-50%);
        width: 0;
        height: 0;
        border-top: 6px solid transparent;
        border-bottom: 6px solid transparent;
        border-right: 6px solid #A3BEF490;

      }

      .notice-swipe {
        min-height: 30px !important;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        flex: 1;
        position: relative;

        .notice-content {
          color: #272727;
          font-size: 13px;
          line-height: 14px;
          font-weight: 500;

          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;

          margin: 0 0 0 7px;
          // padding: 0;
          flex: 1;
          min-height: 30px !important;
          height: 30px !important;
          max-height: 30px !important;

          .content {

            display: flex;
            align-items: center;
            justify-content: flex-start;

            .tips {
              word-break: break-all;
              // 超过两行的内容显示省略号
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }

            width: 100%;
            // height: 100%;
            // opacity: 0;
            height: 0;
            max-height: 30px !important;
            vertical-align: middle;
            margin: 0 !important;
            transition: opacity .3s ease-in-out;
            transition: height .3s ease-in-out;

          }

        }
      }

      .close-icon {
        border-radius: 50%;
        margin: 0 8px;
        width: 14px;
        height: 14px;
      }
    }

  }

}
</style>
