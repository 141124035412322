<template>
  <div ref="toolsBtn">
    <div
      v-if="mcConfig.is_mass_customization != 0 || mcMenu == 1"
      class="absolute bottom-16 right-3 flex flex-col space-y-2 duration-150 origin-bottom"
      :class="{ 'scale-0 overflow-hidden': !toolsShow }"
    >
      <template v-if="!isArtist">

        <div @click="saveSnapshot()" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-save-to-folder text-[16px]"></i>
        </div>

        <div @click="handleClick('undo')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-undo text-sm" :style="{ opacity: historyUndo > 0 ? 1 : 0.2 }"></i>
        </div>
        <div @click="handleClick('redo')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-redo text-sm" :style="{ opacity: historyRedo > 0 ? 1 : 0.2 }"></i>
        </div>

        <div @click="handleClick('bringForward')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-forward text-[16px]"></i>
        </div>
        <div @click="handleClick('sendBackwards')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
          <i class="iconfont icon-backward text-[16px]"></i>
        </div>
       
      </template>

      <!-- <div @click="handleClick('bringForward')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-forward text-[1.375rem]"></i>
      </div>
      <div @click="handleClick('sendBackwards')" class="w-10 h-10 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-backward text-[1.375rem] ml-[1px] mt-[1px]"></i>
      </div> -->

      <!-- <div v-if="!isArtist" @click="handleClick('addUploadedImage')"
        class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont icon-camera text-sm"></i>
      </div> -->

      <!-- <div v-if="!isArtist" @click="saveSnapshot()" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <img class="object-contain w-4 h-4" src="@/static/folder.png" alt="" />
      </div> -->

      <div @click="handleClick('setZoomToggle')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont text-[16px]" :class="zoomToggle ? ' icon-zoomOut' : ' icon-zoomIn'"></i>
      </div>
    </div>
    <div v-else class="absolute bottom-1/3 -translate-y-1/2 right-3 flex flex-col space-y-2">
      <div @click="handleClick('setZoomToggle')" class="w-9 h-9 bg-white rounded-full text-sm flex justify-center items-center">
        <i class="iconfont text-[16px]" :class="zoomToggle ? ' icon-zoomOut' : ' icon-zoomIn'"></i>
      </div>
    </div>

    <!-- 【ID1000273】4.15.4 创作中心-用户-前后左右面定制 工具栏-->

    <div v-if="perspectives.length > 1" class="absolute bottom-3 right-16 flex space-x-2 duration-150 origin-right" :class="{ 'scale-0 overflow-hidden': !toolsShow }">
      <div
        v-for="(item, index) in perspectives"
        :key="index"
        @click="handlePerspective(item.perspective, index)"
        class="relative w-9 h-9 text-xs bg-white rounded-full flex justify-center items-center border-gray-300 border"
      >
        <div
          v-if="selectedPerspective === item.perspective"
          class="absolute w-full h-full text-sm flex justify-center items-center border rounded-full scale-125 z-10"
          style="border-color: #23d59c"
        ></div>
        {{ item.name }}
      </div>
    </div>

    <div class="absolute bottom-3 right-3 rounded-full w-10 h-10 flex justify-center items-center bg-white" @click="toggleTools">
      <!-- <img v-if="toolsShow" src="../static/tool.png" alt="" /> -->
      <!-- <img v-else src="../static/tool_closed.png" alt="" /> -->
      <!-- <img v-if="!toolsShow" :src="../static/icons/shezhi.png" alt="" class="w-[80%]"/> -->
      <img v-if="!toolsShow" :src="settingIcon" alt="" class="w-[80%]"/>
      <!-- <img v-else src="@/static/icons/shezhi.png" alt="" class="w-[80%]"/> -->
      <img v-else src="../static/close.jpg" alt="" class="rounded-full w-[80%]"/>
    </div>
    
  </div>
</template>

<script>
import { mapMutations, mapState, mapGetters } from "vuex";
import { Toast } from "vant";
import { useClickAway } from '@vant/use';
import { ref, getCurrentInstance } from "vue";

export default {
  name: "ToolBar",
  setup() {
    const toolsBtn = ref();
    let componentData = getCurrentInstance();
    useClickAway(
      toolsBtn,
      () => {
        componentData.data.toolsShow = false;
      },
      { eventName: 'touchstart' }
    );
    return { toolsBtn };
  },
  data() {
    return {
      isArtist: false, //是否为艺术家
      selectedPerspective: "front", //选中的面
      toolsShow: false, //工具栏是否弹出
      // toolsShow: true, //工具栏是否弹出

    };
  },
  computed: {
    ...mapGetters(["getMenus"]),
    ...mapState(["carrierConfig", "zoomToggle", "mcConfig", "mcMenu", "historyRedo", "historyUndo"]),
    settingIcon() {
      let icon = "https://mer-oss.tootools.cn/static/production/images/editor-icons/setting.png";// 默认值
      if (this.getMenus.length) {
        let settingObj = this.getMenus.find(i => i.id == 9);
        if (settingObj) {
          icon = settingObj.icon
        }
      }
      return icon;
    },
    perspectives() {
      const perspectives = [
        { perspective: "front", name: "正" },
        { perspective: "back", name: "反" },
        { perspective: "left", name: "左" },
        { perspective: "right", name: "右" },
      ];

      const res = [];

      perspectives.forEach(item => {
        //载体有几个面
        if (!this.carrierConfig[item.perspective]) {
          return;
        }
        res.push(item);
      });
      return res;
    },
  },
  mounted() {
    // this.$bus.$on("callCamera", () => {
    //   this.handleClick("addUploadedImage");
    // });
    this.isArtist = this.$route.matched[1].path.indexOf("artist") !== -1;
  },
  unmounted() {
    // this.$bus.$off("callCamera");
  },
  methods: {
    ...mapMutations(["SET_CURRENT_PERSPECTIVE", "SET_ZOOM_TOGGLE", "CHANGE_MENU_STATUS", "SET_UPLOADED_IMAGE"]),
    //【ID1000258】创作中心微调
    toggleTools() {
      this.toolsShow = !this.toolsShow;
    },
    saveSnapshot() {
      this.$bus.$emit(this.$store.state.currentPerspective + ":saveSnapshot");
    },
    handleClick(event) {
      if (event === "setZoomToggle") this.SET_ZOOM_TOGGLE(!this.zoomToggle);

      // if (event === "addUploadedImage") {
      //   this.$refs.uploadImage.click();
      //   return;
      // }
    
      if (event == "undo" && !this.historyUndo) return;// Toast("已是最后一步");
      if (event == "redo" && !this.historyRedo) return;// Toast("已是最新一步");

      this.$bus.$emit(this.$store.state.currentPerspective + ":" + event);
    },
    
    //【ID1000273】4.15.4 创作中心-用户-前后左右面定制 工具栏
    handlePerspective(event, index) {
      this.$bus.$emit("hideMenuPopup"); // 隐藏所有弹窗
      this.selectedPerspective = event;
      this.SET_CURRENT_PERSPECTIVE(event); // 设置当前的面
      this.$bus.$emit(event);
      this.$emit("switchPerspective", index); //切换到当前面的索引
      if (this.carrierConfig?.left && this.carrierConfig?.right) {
        //在正面背面渲染左袖右袖
        this.$bus.$emit("left:exportPerspectiveCanvas");
        this.$bus.$emit("right:exportPerspectiveCanvas");
      }

      this.$bus.$emit("front:renderPerspectiveCanvas");
      this.$bus.$emit("back:renderPerspectiveCanvas");
      // 放大每个面
      this.$bus.$emit("front:zoom150x");
      this.$bus.$emit("back:zoom150x");
      this.$bus.$emit("left:zoom150x");
      this.$bus.$emit("right:zoom150x");
    },
  },
};
</script>

<style lang="scss" scoped>
/* 图片动画切换效果 */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
