/*
 * @Description:
 * @version:
 * @Date: 2023-06-13 15:29:33
 * @LastEditTime: 2024-01-11 10:24:53
 */
import { createApp } from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"
import bus from "./utils/eventbus"
import { Toast, Swipe, SwipeItem, Dialog, Image as VanImage, Popup, Tabs, Tab, Slider, Loading, Uploader, Button, Field, Icon, Notify } from "vant"
import "vant/lib/index.css"
import "default-passive-events"
import "./assets/css/iconfont"
import "./index.css"
import { userAction } from "@/utils/userAction"
// import { ImgUtil } from "@/utils/imgUtils";
const app = createApp(App)
// ImgUtil.storageImageList();

// 统一修改notify的默认配置
Notify.setDefaultOptions({
  duration: 5000,
  background: "#ffffff",
  color: "#f56c6c",
})

import { log, logs } from "@/utils/utils"
app.config.globalProperties.$log = log
app.config.globalProperties.$logs = logs
app.config.globalProperties.$setOss = (img, width = 300, height = 300) => {
  if(!img) return img;
	let w_h_str = `w_${ width || 300 },h_${ height || 300 }`;// w_600,h_600
	if (width != null && height == null) w_h_str = `w_${ width }`;
	if (width == null && height != null) w_h_str = `h_${ height }`;
	return /\.(jpg|png)/.test(img) ? img + "?x-oss-process=image/resize," + w_h_str + "/quality,q_50" : img;
}

app.config.globalProperties.$bus = bus
userAction()
app.use(VanImage).use(Dialog).use(Icon).use(SwipeItem).use(Swipe).use(Popup).use(Tabs).use(Tab).use(Toast).use(Slider).use(store).use(router).use(Loading).use(Uploader).use(Button).use(Field).use(Notify).mount("#app")

Toast.setDefaultOptions({ duration: 600 })
