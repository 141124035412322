import * as type from "./mutation-types"
import {
  // getShopElementsList,
  saveToopeeks,
  getTemplateInfo,
  getMCCustomInfo,
  getCarrierConfig,
  getCartProductDesignDetail,
  postProductSaveDesignProduct,
  // uploadOSS,
  postUploadImage,
  getColorList,
  getFontList,
  getCreationMenu,// 底部菜单功能
} from "@/api/app"
import { dataURLtoBlob } from "@/utils/utils"
import { Toast } from "vant"

export default {

  async fetchMenus({ commit, state }, mer_id) {
    const { data } = await getCreationMenu({ mer_id });

    let menus = data.status != 200 ? [] : data.data;

    // 使用sort排序
    menus = menus.sort((a, b) => a.sort - b.sort);

    menus = menus.map(i => {
      return {
        icon: i.image,
        title: i.title,
        id: i.unique
      }
    })

    commit(type.SET_MENUS, menus);    
  },

  async fetchColorLibrary({ commit, state }) {
    const { data } = await getColorList({
      page: 1,
      limit: 999,
      unique: state.unique,
    })
    if (data.status === 200) {
      const colorList = data.data.list
      const colorLibrary = []
      colorList.forEach(
        (
          {
            value: color,
            id: id,
            // type,
            title: title,
            cover_img: coverImg,
            series_id: seriesId,
          },
          index,
        ) => {
          colorLibrary[index] = {
            color,
            id,
            type: 3,
            title,
            coverImg,
            seriesId
          }
        },
      )
      const colorConfig = {
        ...state.colorConfig,
      }
      
      Object.keys(colorConfig).forEach((key, index) => {
        if (key === "defaultColor") return
        colorConfig[key] = colorLibrary[index - 1]
      })

      

      commit(type.SET_COLOR_CONFIG, {
        ...state.colorConfig,
        ...colorConfig,
      })

      commit(type.SET_COLOR_LIBRARY, colorLibrary)
      // commit(type.SET_COLOR_LIBRARY, [...colorLibrary, ...colorLibrary, ...colorLibrary])
    }

    // const { data } = await getShopElementsList({ type: 4 });
    // if (data.status === 200) {
    //   const colorList = data.data.list;
    //   const colorLibrary = [];
    //   colorList.forEach(({ value: color, id, type, title, cover_img: coverImg }, index) => {
    //     colorLibrary[index] = {
    //       color,
    //       id,
    //       type,
    //       title,
    //       coverImg,
    //     };
    //   });
    //   const colorConfig = { ...state.colorConfig };
    //   Object.keys(colorConfig).forEach((key, index) => {
    //     if (key === "defaultColor") return;
    //     colorConfig[key] = colorLibrary[index - 1];
    //   });
    //   commit(type.SET_COLOR_CONFIG, { ...state.colorConfig, ...colorConfig });
    //   commit(type.SET_COLOR_LIBRARY, colorLibrary);
    // }
  },

  async fetchFontList({ commit, state }) {
    // const { data } = await getShopElementsList({ type: 3 })
    // console.log("fontLIst", data)
    // if (data.status === 200) {
    //   const fontList = data.data
    //   commit(type.SET_FONT_LIST, fontList)
    // }

    const { data } = await getFontList({
      page: 1,
      limit: 999,
    })
    console.log("fontLIst", data)
    if (data.status === 200) {
      const fontList = data.data.list
      commit(type.SET_FONT_LIST, fontList)
      return Promise.resolve(true)
    } else {
      return Promise.reject(false)
    }
  },
  async saveDesign({ commit, state }, products) {
    let options = state.designOptions
    return new Promise((reject, resolve) => {
      saveToopeeks(options).then(res => {
        resolve(res)
      })
    })
  },
  async fetchDesignJSON({ commit, state }) {
    // 加载模版中
    // Toast.loading({
    //   message: "加载模版中...",
    //   forbidClick: true,
    //   duration: 0,
    // });
    if (state.templateId) {
      const { data } = await getTemplateInfo(state.templateId)
      if (data.status === 200) {
        commit(type.SET_DESIGN_JSON, data.data.design_json)
      }
    }
    if (state.productId) {
      const { data } = await getCartProductDesignDetail({
        product_id: state.productId,
      })
      console.log(`\n 🚀🚀 %c  获取到 ${state.productId} 的design_detail  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, data.data);
      // 23-12-04：增加判断条件，需要判断data.data.design_json
      if (data.status === 200 && data.data.design_json) {
        commit(type.SET_CURRENT_IP_ID, data.data.ip_id)
        commit(type.SET_DESIGN_JSON, data.data.design_json)
      }
    };
    // 隐藏加载中
    // Toast.clear();
  },
  async fetchMCConfig({ commit, state }) {
    if (state.mcId) {
      const { data } = await getMCCustomInfo(state.mcId || 1)
      if (data.status === 200) {
        const mcConfig = data.data
        commit(type.SET_MC_CONFIG, mcConfig)
      }
    }
  },
  async fetchCarrierConfig({ commit, state }) {
    const params = {
      carrier_id: state.carrier_id,
      unique: state.unique,
    }
    const { data } = await getCarrierConfig(params)
    if (data.status === 200) {
      const carrierConfig = data.data
      if (carrierConfig.left == null) {
        delete carrierConfig.left
      }
      if (carrierConfig.right == null) {
        delete carrierConfig.right
      }
      if (carrierConfig.back == null) {
        delete carrierConfig.back
      }
      commit(type.SET_CARRIER_CONFIG, carrierConfig)
    }
  },
  async postProductSavePosterActions({ commit, dispatch, state, getters }, saveType) {
    //saveType 2 直接购买  1 加入购物车
    // this.isLoading = true;

    const {
      carrierId,
      // storeName,
      // containerImage,
      // colorImage,
      // size,
      // price,
      // productId
    } = getters.getItemInfo
    let perspective = {
      front: {},
      back: {},
      left: {},
      right: {},
    }

    let elementIds = []
    Object.keys(state.elementIds).forEach(key => {
      elementIds = [...elementIds, ...state.elementIds[key].split(",")]
    })
    for (let key in state.designJSONs) {
      perspective[key].json = state.designJSONs[key]
    }

    // 上传所有的导出预览图
    let allOutputPreviewImages = [];
    for (let key in state.outputPreviewImages) {
      if (state.outputPreviewImages[key] == null || state.outputPreviewImages[key].indexOf("http") > -1) {
        allOutputPreviewImages.push(state.outputPreviewImages[key]);
        continue
      } 
        
      const outputPreviewImage = await dispatch("uploadImage", state.outputPreviewImages[key])
        // console.log("outputPreviewImage-->", outputPreviewImage)
      console.log(`\n 🚀🚀 %c  导出的预览图内容 ===>  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, outputPreviewImage);
        
      // if (!outputPreviewImage) {
      //   return false
      // }
      perspective[key].image = outputPreviewImage     

      let newOutputPreviewImages = {
        ...state.outputPreviewImages,
      }
      newOutputPreviewImages[key] = outputPreviewImage
      commit(type.SET_OUTPUT_PREVIEW_IMAGES, newOutputPreviewImages);

      allOutputPreviewImages.push(outputPreviewImage);
    };

    // 所有上传结束的结果 allOutputPreviewImages
    if (allOutputPreviewImages.some(i => i === undefined)) return false;

    // 上传所有的导出设计图
    let allOutputCanvasImages = [];
    for (let key in state.outputCanvasImages) {
      try {
        if (state.outputPreviewImages[key] === null) {
          allOutputCanvasImages.push(null)
          continue
        }
        if (state.outputCanvasImages[key] === null) {
          allOutputCanvasImages.push(null)
          continue
        }
        const outputCanvasImage = await dispatch("uploadImage", state.outputCanvasImages[key])
        // console.log("outputCanvasImage-->", outputCanvasImage)
        console.log(`\n 🚀🚀 %c  导出的画布图内容 ===>  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, outputCanvasImage);
        
        // if (!outputCanvasImage) {
        //   console.error("上传图片超时")
        //   return false
        // }
        let newOutputCanvasImages = {
          ...state.outputCanvasImages,
        }
        newOutputCanvasImages[key] = outputCanvasImage
        commit(type.SET_OUTPUT_CANVAS_IMAGES, newOutputCanvasImages)
        perspective[key].thumb_image = outputCanvasImage
        allOutputCanvasImages.push(outputCanvasImage)
      } catch (error) {
        console.log(`\n 🚀🚀 %c 保存设计 error  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, error);
        console.log(state.outputCanvasImages[key])
        console.error(error)
        return
      }
    }

    // 所有上传结束的结果 allOutputCanvasImages
    if (allOutputCanvasImages.some(i => i === undefined)) return false;

    const params = {
      mer_id: state.merId,
      carrier_id: carrierId,
      carrier_attr_unique: state.unique,
      // ip_id: state.ipIds.front,
      element_ids: elementIds.filter(i => i),
      store_name: state.carrierConfig.store_name,
      // image: state.carrierConfig.image,
      design_id: localStorage.getItem("isGetDesignId") || localStorage.getItem("designProductId") || state.shareDesignPosterData.design_id || "0",
      // price: state.carrierConfig.price,
      // saveType: saveType,
      // channel: state.channel,
      design: perspective,
    };

    // aigd design_id 单独特别处理，判断条件为，如果design_id为0，且是aigd，则传aigd的design_id
    if (params.design_id === "0" && state.aigdInfo !== null &&  state.aigdInfo.design_id !== undefined) {
      params.design_id = state.aigdInfo.design_id
    };

    // 特别处理design_id，如果是做同款等场景，需要设置为0
    // if (localStorage.getItem("isGetDesign")) params.design_id = "0";
    console.log(`\n 🚀🚀 %c  修改完的保存设计参数为：  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, params);

    if (state.avatarId) {
      params.toopeeks_id = state.avatarId
    }

    const { data } = await postProductSaveDesignProduct(params);

    if (data.status !== 200) {
      Toast(data.message || data.msg || "保存设计失败");
      return false;
    }

    if (data.status === 200) {
      commit(type.SET_PRODUCT_ID, data.data.product_id)
      console.log("data.data", data.data)

      // 如果是做同款等场景，需要赋值design_id
      localStorage.setItem("isGetDesignId", data.data.design_id);

      return data.data
      // return true;
    } else {
      console.error(data.msg)
      
    }
  },
  
  async uploadImage(context, dataUrl) {
    const fileBlob = dataURLtoBlob(dataUrl)
    const formData = new FormData()
    formData.append("file", fileBlob, "blob.png")
    const { data } = await postUploadImage(formData)
    // console.time("uploadImage");
    console.log("formData-->", data)
    return data && data.data && data.data.path || undefined
    // const res = await uploadOSS(fileBlob);
    // console.log(res, res.options.endpoint + "/" + res.result.name);
    // return res.options.endpoint + "/" + res.result.name;
  },

  async uploadFile(context, dataUrl) {
    const formData = new FormData()
    formData.append("file", dataUrl, "blob.png")
    try {
      const { data } = await postUploadImage(formData)

      return data.data.path
    } catch (err) {
      throw new Error(err)
    }
  },

  // 上传多个file文件
  async uploadFiles(context, files) {
    let promises = [];
    files.forEach(fileBlob => {
      const formData = new FormData();
      formData.append("file", fileBlob, "blob.png");
      promises.push(postUploadImage(formData));
    });
    let results = await Promise.all(promises);
    // console.log(`\n 🚀🚀 %c  results  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, results);
    // console.log(results);
    // let resultsData = results.map(item => item.data.data.path);
    // return Promise.resolve(resultsData);  
    return results.map(item => item.data.data.path);
  }
}
