<template>
  <div>
    <van-popup class="shadow-sm rounded-t-xl" :class="'menuStatus' ? 'h-[280px]' : 'h-173px'" :show="show"
      position="bottom" :overlay="false" @close="handleClose" round>
      <div
        class="absolute right-4 top-2 w-7 h-7 rounded-full border border-gray-400 flex items-center justify-center leading-none z-50"
        @click="handleShowPopup">
        <i class="iconfont icon-confirm text-xs"></i>
      </div>
      <div class="h-full">
        <div class="py-2 h-full relative">
          <van-tabs class="menu-tabs flex flex-col w-full h-full justify-start" shrink v-model:active="computedTab">
            <!-- <van-tab v-if="isText" name="input">
              <template #title>
                <svg class="icon w-5 h-3 inline-block" aria-hidden="true">
                  <use xlink:href="#icon-keyboard"></use>
                </svg>输入</template>

              <div class="flex w-full h-full pt-4 px-4 items-center">
                <textarea class="rounded-md w-full bg-gray-100 px-3 py-2 h-16 resize-none text-sm" placeholder="请输入文字"
                  name="text" id="text" @input="handleInput" :value="text"> </textarea>
              </div>
            </van-tab> -->
            <van-tab name="color" class="color-menu h-full">
              <template v-if="isDrawingMode" #title>
                <!-- <img class="icon h-4 inline-block" src="@/static/brush.svg" alt="" />
                画笔 -->
                <div class="h-[43px] flex justify-between items-center rounded-t-xl overflow-hidden">
                  <img class="w-[1.1rem] mr-1" src="@/static/icons/huabi.png" alt="" />
                  <span class="leading-[40px] text-sm font-medium text-[#3F3F3F]">画笔</span>
                </div>
              </template>

              <template v-else #title>
                <!-- <svg class="icon w-5 h-3 inline-block" aria-hidden="true">
                  <use xlink:href="#icon-colorpad-color"></use>
                </svg>颜色 -->

                <!-- 形状的颜色 -->
                <div class="flex items-center ml-[10px]">
                  <img class="w-[1rem] mr-[5px]" src="@/static/icons/genggaiyanse.png/" alt="" />
                  <span class="font-medium">颜色</span>
                </div>
              </template>
              <div class="flex flex-col w-full h-full items-center">
                <div v-if="isDrawingMode" class="grid grid-cols-4 w-[15.5625rem] place-items-center gap-6 my-4">
                  <div class="rounded-full w-10 h-10 bg-gray-100 flex items-center justify-center">
                    <div class="w-full h-full rounded-full flex items-center justify-center p-1"
                      :class="{ 'border-2 border-[#3f3f3f]': activeBrush == 2 }" @click="handleBrushSize('2')">
                      <img class="w-full" src="@/static/brush_size_1.svg" alt="" />
                    </div>
                  </div>
                  <div class="rounded-full w-10 h-10 bg-gray-100 flex items-center justify-center">
                    <div class="w-full h-full rounded-full flex items-center justify-center p-1"
                      :class="{ 'border-2 border-[#3f3f3f]': activeBrush == 3 }" @click="handleBrushSize('3')">
                      <img class="w-full" src="@/static/brush_size_2.svg" alt="" />
                    </div>
                  </div>
                  <div class="rounded-full w-10 h-10 bg-gray-100 flex items-center justify-center">
                    <div class="w-full h-full rounded-full flex items-center justify-center p-1"
                      :class="{ 'border-2 border-[#3f3f3f]': activeBrush == 4 }" @click="handleBrushSize('4')">
                      <img class="w-full" src="@/static/brush_size_3.svg" alt="" />
                    </div>
                  </div>
                  <div class="rounded-full w-10 h-10 bg-gray-100 flex items-center justify-center">
                    <div class="w-full h-full rounded-full flex items-center justify-center p-1"
                      :class="{ 'border-2 border-[#3f3f3f]': activeBrush == 5 }" @click="handleBrushSize('5')">
                      <img class="w-full" src="@/static/brush_size_4.svg" alt="" />
                    </div>
                  </div>
                </div>
                <div :class="[
                  'menuStatus' ? 'px-6' : 'overflow-x-auto',
                  'menuStatus' && isDrawingMode ? 'h-[calc(280px-80px-40px)]' : '',
                  'menuStatus' && !isDrawingMode ? 'h-[calc(280px-50px)]' : '',
                ]" class="flex w-full h-[200px] pt-4 py-6 overflow-auto ">
                  <div>
                    <div
                      :class="'menuStatus' ? 'grid grid-cols-6 w-full pb-6 place-items-center gap-6' : 'flex mx-4 pb-4 items-center flex-grow-0 space-x-4 flex-shrink-0'">
                      <div class="rounded-full bg-gray-200 flex items-center justify-center" :style="color == activeColor
                        ? `background: #3f3f3f;padding: 2px; width: 2.375rem; height:2.375rem;`
                        : `background: linear-gradient(270deg, #E3E5E5 0%, #F7F7F7 99.94%, rgba(215, 215, 215, 0.5) 99.96%, #F5F5F5 99.97%, rgba(245, 245, 245, 0.3) 100%); padding: 2px; width: 2.375rem; height:2.375rem;`
                        " v-for="({ color, id, coverImg }, index) in colorLibrary" :key="id"
                        @click="handleColor(index)">
                        <div class="w-full h-full rounded-full" :style="{
                          backgroundImage: `url(${coverImg})`,
                          backgroundSize: 'cover',
                        }">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>

            <van-tab v-if="isText" name="style">
              <template #title>
                <svg class="icon w-5 h-3 inline-block" aria-hidden="true">
                  <use xlink:href="#icon-textStyle"></use>
                </svg>样式</template>

              <div class="w-full h-full pt-4 px-16">
                <div class="flex w-full justify-between items-center">
                  <div>
                    <div
                      class="w-12 h-12 rounded-md border-2 border-gray-100 bg-gray-200 flex items-center justify-center"
                      :style="{ backgroundColor: '#F5F5F5' }" @click="handleAlign('left')">
                      <i class="iconfont icon-align-left"></i>
                    </div>
                    <div class="text-xs mt-2 text-center">左对齐</div>
                  </div>
                  <div>
                    <div
                      class="w-12 h-12 rounded-md border-2 border-gray-100 bg-gray-200 flex items-center justify-center"
                      :style="{ backgroundColor: '#F5F5F5' }" @click="handleAlign('center')">
                      <i class="iconfont icon-align-center"></i>
                    </div>
                    <div class="text-xs mt-2 text-center">居中对齐</div>
                  </div>
                  <div>
                    <div
                      class="w-12 h-12 rounded-md border-2 border-gray-100 bg-gray-200 flex items-center justify-center"
                      :style="{ backgroundColor: '#F5F5F5' }" @click="handleAlign('right')">
                      <i class="iconfont icon-align-right"></i>
                    </div>
                    <div class="text-xs mt-2 text-center">右对齐</div>
                  </div>
                </div>
                <div class="w-full flex pt-2 items-center">
                  <div>Aa</div>
                  <div class="w-full pl-4">
                    <van-slider v-model="value" :min="sliderMinVal" max="3" step="0.1" active-color="#000000"
                      @update:model-value="handleScale" />
                  </div>
                </div>
              </div>
            </van-tab>

          </van-tabs>
        </div>
      </div>
    </van-popup>

    <van-popup v-if="isText" class="shadow-sm rounded-t-xl overflow-hidden"
      :class="'menuStatus' ? 'h-[280px]' : 'h-173px'" :show="show" position="bottom" :overlay="false" @close="handleClose"
      round>
      <div class="h-full">
        <div class="h-full relative">
          <div class="w-full flex items-center pt-3 px-3">
            <!--<van-field class="bg-[#F5F5F5] align-middle rounded-[6px] w-[309px] h-[38px] leading-none" :clearable="true" clear-icon clear-trigger v-model="fontText" placeholder="点击输入你的内容" />-->
            <div class="relative h-[34px] rounded-md flex bg-[#F5F5F5] items-center mr-3 flex-1">
              <textarea
                class="textArea w-full h-6 font-medium text-[#3F3F3F] bg-[#F5F5F5] px-3 py-1 text-sm leading-4 resize-none"
                placeholder="点击输入你的内容" name="text" id="text" @input="handleInput" :value="text"></textarea>
              <img @click="clearText" v-if="text !== ''" class="absolute z-50 right-2 h-4 w-4" src="@/static/clear.png"
                alt="" />
            </div>

            <div
              class="top-2 mr-1 w-7 h-7 rounded-full border border-gray-400 flex items-center justify-center leading-none z-50"
              @click="handleShowPopup">
              <i class="iconfont icon-confirm text-xs"></i>
            </div>
          </div>

          <van-tabs class="w-full menu-tabs" shrink v-model:active="computedTab" swipeable lock-scroll
            title-inactive-color="#3F3F3F" title-active-color="#3F3F3F" @change="onTabChange">

            <van-tab name="input" class="w-23">
              <template #title>
                <div class="flex items-center">
                  <!-- <svg class="icon w-4 h-4 inline-block" aria-hidden="true">
                    <use xlink:href="#icon-zitiicon"></use>
                  </svg> -->
                  <!-- <img src="@/static/icons/ziti.png" class="icon w-4 h-4 inline-block"/> -->
                  <img class="w-[1rem] mr-1" src="@/static/icons/ziti.png/" alt="" />
                  <span class="font-medium">字体</span>
                </div>
              </template>
              <div class="container-box flex flex-col w-full h-[200px] overflow-y-auto items-center">
                <!-- <elements-info seriesType="font" :itemObj="albumList"></elements-info> -->
                <div class="wrapper-box mt-3 mb-6">
                  <div class="img_item relative"
                    :class="item.font_family_name == selectedFontFamily ? 'img_item--active' : 'img_item'"
                    @click="checkedItem(item)" v-for="item in fontList" :key="item.id" :style="{
                      backgroundColor: item.bg_color_value ? item.bg_color_value : '#f5f5f5',
                    }">
                    <!-- <van-image width="100%" height="100%" fit="contain" :src="getImgValue(item.cover_img)" /> -->

                    <van-image class="font-image" width="100%" height="100%" fit="contain"
                      :src="getImgValue(item.font_cover_image)" />

                    <div
                      class="text-[#3f3f3f] bottom-0 text-center text-[10px] overflow-hidden whitespace-nowrap text-sm text-ellipsis">
                      {{ item.font_title }}
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>

            <van-tab name="color" class="color-menu h-full">
              <template #title>
                <div class="flex items-center ml-[10px]">
                  <!-- <svg class="icon w-5 h-5 inline-block" aria-hidden="true">
                    <use xlink:href="#icon-colorpad-color"></use>
                  </svg> -->
                  <!-- <img src="@/static/icons/ziti.png" class="icon w-4 h-4 inline-block"/> -->
                  <img class="w-[1rem] mr-[5px]" src="@/static/icons/genggaiyanse.png/" alt="" />
                  <span class="font-medium">颜色</span>
                </div>
                <!-- <svg class="icon w-5 h-3 inline-block" aria-hidden="true">
                  <use xlink:href="#icon-colorpad-color"></use></svg
                >颜色 -->
              </template>
              <div class="flex flex-col w-full h-full items-center">
                <div class="flex w-full h-[200px] pt-4 py-6 overflow-auto"
                  :class="'menuStatus' ? 'px-6' : 'overflow-x-auto'">
                  <div>
                    <div
                      :class="'menuStatus' ? 'grid grid-cols-6 w-full pb-6 place-items-center gap-6' : 'flex mx-4 pb-4 items-center flex-grow-0 space-x-4 flex-shrink-0'">
                      <div class="rounded-full bg-gray-200 flex items-center justify-center" :style="color == activeColor
                        ? `background: #3f3f3f;padding: 2px; width: 2.375rem; height:2.375rem;`
                        : `background: linear-gradient(270deg, #E3E5E5 0%, #F7F7F7 99.94%, rgba(215, 215, 215, 0.5) 99.96%, #F5F5F5 99.97%, rgba(245, 245, 245, 0.3) 100%); padding: 2px; width: 2.375rem; height:2.375rem;`
                        " v-for="({ color, id, coverImg }, index) in colorLibrary" :key="id"
                        @click="handleColor(index)">
                        <div class="w-full h-full rounded-full" :style="{
                          backgroundImage: `url(${coverImg})`,
                          backgroundSize: 'cover',
                        }">
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </van-tab>

          </van-tabs>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed, ref, toRefs, watch, watchEffect } from "vue";
import { useStore } from "vuex";
import { search } from "@/api/app.js";
import bus from "@/utils/eventbus";
import fontMenu from "@/views/stickerView/components/fontMenu";
import elementsInfo from "@/components/content/elementsInfo.vue";
import betterScroll from "@/components/common/betterScroll";

const props = defineProps({
  show: {
    type: Boolean,
    default: false,
  },
  isText: {
    type: Boolean,
    default: false,
  },
  textContent: {
    type: String,
    default: "",
  },
  sliderMinVal: {
    type: Number,
    default: 0,
  },
  tab: {
    type: String,
    default: "color",
  },
  fontFamily: {// 选中的字体
    type: String,
    default: "",
  },
});

const { isText, sliderMinVal, tab, textContent } = toRefs(props);

const store = useStore();

const colorLibrary = computed(() => store.getters.getColorLibrary);

const colorConfig = computed(() => store.getters.getColorConfig);

const menuStatus = computed(() => store.getters.getMenuStatus);

const fontList = computed(() => store.getters.getFontList)

const getSliderVal = computed(() => store.getters.getSliderVal);

const selectedColorKey = computed(() => store.state.activeColorKey);

const activeColor = computed(() => store.state.activeColor);

const isDrawingMode = computed(() => store.state.isDrawingMode);

const isKnitting = computed(() => store.state.is_knitting);

const text = computed(() => {
  //	1000295	创作中心-字体功能-输入文字需要先回车把“请输入文字”删除
  if (textContent.value === "请输入文字") {
    return "";
  } else {
    return textContent.value;
  }
});

let value = ref(1);
let activeBrush = ref(2);

// let activeName = ref("color");
const computedTab = computed(() => {
  return props.tab;
});



// 组件内部的默认选中字体的value
let selectedFontFamily = ref("");

// 监听传入的选中的fontFamily
watch(
  () => props.fontFamily,
  (newVal, oldVal) => {
    selectedFontFamily.value = newVal;
  },
  { immediate: true }
);

const albumList = ref([]);
const getSearch = function () {
  search({
    type: 5,
    elementType: 2,
  }).then(res => {
    albumList.value = res.data.data.list[0];
    console.log("albumList", albumList.value);
  });
};
//getSearch();
// 监听字体滑块大小
watch(
  () => store.getters.getSliderVal,
  sliderVal => {
    value.value = sliderVal;
  },
);

const emits = defineEmits(["hidePopup", "setText", "setTab"]);

const handleScale = value => {
  //已作废
  store.commit("SET_SLIDER_VAL", value);
  bus.$emit(store.state.currentPerspective + ":scaleText", value);
};
const handleShowPopup = () => {
  emits("hidePopup");
  bus.$emit(store.state.currentPerspective + ":exitDrawingMode");
};

const onTabChange = (e) => {
  // console.log(`\n 🚀🚀 %c  onTabChange  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, e);
  // console.log(`\n 🚀🚀 %c  子组件传tab参  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, e);
  // emits("setTab", e == "1" ? "color" : "input");
  // activeName.value = e;
  // emits("setTab", e);

  bus.$emit("showMenuPopup", {
    // colorKey: this.activeColorKey,
    isText: true,
    textContent: textContent.value,
    tab: e,
  })
}

// const handleTab = (e) => {
//   console.log(`\n 🚀🚀 %c  handleTab  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, e);
//   // console.log(activeName);
//   emits("setTab", e);
// };

// const handleFontTab = (e) => {
//   console.log(`\n 🚀🚀 %c  handleFontTab  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, e);
//   // console.log(activeName);
//   emits("setTab", e);
//   // activeName.value = e.name;
// };

const checkedItem = item => {
  let url, options;
  url = item.font_value;
  options = {
    fontFamily: item.font_family_name,
    elementId: item.id,
    ipId: item.ip_id || 0,
    type: 2,
  };
  selectedFontFamily.value = item.font_family_name;
  bus.$emit(store.state.currentPerspective + ":addFont", { url, options });
};

const getImgValue = url => {
  return /.(jpg|png)/.test(url) ? url + "?x-oss-process=image/resize,w_600,h_600/quality,q_50" : url;
};

/*
 * 屏蔽emoji表情
 */
const handleInput = e => {
  e.target.value = e.target.value.replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, "");

  bus.$emit(store.state.currentPerspective + ":inputText", e.target.value);
};

const handleAlign = align => {
  //已作废
  bus.$emit(store.state.currentPerspective + ":alignText", align);
};

//清除内容
const clearText = () => {
  bus.$emit(store.state.currentPerspective + ":inputText", "");
  emits("setText", "");
};

/*
 * 选择颜色
 */

const handleColor = index => {
  store.commit("SET_ACTIVE_COLOR", colorLibrary.value[index].color);
  if (!selectedColorKey.value && isKnitting.value == "1") return;
  bus.$emit("changeColorConfig", {
    color: isKnitting.value == "1" ? selectedColorKey.value : "primaryColor",
    value: colorLibrary.value[index],
  });
};

const handleBrushSize = size => {
  activeBrush.value = size;
  bus.$emit("front:setBrushSize", size);
  bus.$emit("back:setBrushSize", size);
  bus.$emit("left:setBrushSize", size);
  bus.$emit("right:setBrushSize", size);
};

const handleClose = () => {
  store.commit("CHANGE_MENU_STATUS", false);

  if (isDrawingMode.value) {
    bus.$emit("front:exitDrawingMode");
    bus.$emit("back:exitDrawingMode");
    bus.$emit("left:exitDrawingMode");
    bus.$emit("right:exitDrawingMode");
  }
};
</script>

<style lang="scss" scoped>
.textArea::-webkit-scrollbar {
  width: 0;
  height: 0;
}

.icon {
  margin-right: 0.25rem;
  fill: currentColor;
  overflow: hidden;
}

.menu-tabs {
  .van-tabs__wrap {
    border-bottom: 1px solid #f5f5f5;
  }

  .van-tabs__content {
    height: 100%;
    overflow: auto;
  }

  .van-tabs__nav--line {}

  .van-tab--shrink {
    margin-right: 16px;
  }

  .container-box::-webkit-scrollbar {
    display: none;
  }

  .container-box {
    .wrapper-box {
      display: grid;
      grid-template-columns: repeat(4, 79px);
      grid-template-rows: repeat(auto-fill, 105px);
      gap: 12px;
      grid-auto-rows: 105px;

      .img_item {
        border: #f5f5f5 1.3px solid;
        // overflow: hidden;
        width: 79px;
        height: 79px;
        border-radius: 6px;
        background-color: #f5f5f5;
        // overflow: hidden;

        .font-image {
          border-radius: 6px;
          overflow: hidden;
        }

        .img-size {
          width: 79px;
          height: 79px;
          border-radius: 6px;
        }

        &--active {
          border: #3f3f3f 1.3px solid;
        }
      }
    }
  }
}
</style>