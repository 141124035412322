<template>
  <!-- style="height: calc(100vh - 90px)" -->
  <div ref="fabricContainer" class="fabric-container relative w-full h-full" @resize="handleResize">
    <div ref="fabricCanvas" class="fabric-canvas w-full absolute top-1/2 -translate-y-1/2">
      <canvas :id="canvasId"></canvas>
    </div>
    <vanDialog></vanDialog>
  </div>
</template>

<script>
import { fabric } from "fabric-with-gestures-v5"
import { initAligningGuidelines } from "@/utils/fabric/aligning_guidelines.js"
import "fabric-history"
import _ from "lodash"

import controls from "@/utils/fabric/controls.js"
import { Dialog, Toast } from "vant"
import spec2 from "@/assets/images/icons/spec2.svg"
import { postExportCanvas, saveUserElements, uploadOSS } from "@/api/app"
import { dataURLtoBlob } from "@/utils/utils"

import { mapGetters, mapMutations, mapState, mapActions } from "vuex"

import SVG_CONFIG from "@/utils/fabric/svg_features_config.js"
// console.log("============= EditorCanvas.vue feats===============", SVG_CONFIG);

// import { processSegmentHDImages } from "@/utils/segmentHDImages" // 批量处理，高清抠图
import { processSegmentImages } from "@/utils/segmentImages" // 批量处理，高清抠图

const arrangeCountLimit = 100;// 最大可排列元素控制标记

export default {
  name: "EditorCanvas",
  props: {
    canvasId: {
      type: String,
      default: "front",
    },
    perspective: {
      type: String,
      default: "front",
    },
  },
  data() {
    return {
      carrier: "",
      isZoomIn: false, //是否放大了
      boundOriginWidth: 35, //框原始大小
      boundRatio: 43 / 35, //框的比例
      boundCenterX: 0, //框初始xy坐标
      boundCenterY: 0,
      batchId: null, //画笔批次
      svgOffset: 0, //svg偏移量
      templateLoaded: false, //模板是否已经加载好

      historyData: {
        historyNextState: "",
        historyUndo: [],
        historyRedo: [],
      },

      allowArrangeFlag: true, // 是否禁用【显示排版菜单】标记量
    }
  },
  watch: {
    removeNotUseSticker: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue) {
          // 清除画布中，不共用的贴纸， 目前主要用于，上传照片功能前检查完需要重置画布。 23-08-03
          let needRemoveObjects = this.canvas.getObjects().filter(o => o.typeId == SVG_CONFIG.notUseId || o.typeId == 2)
          this.removeSvg(needRemoveObjects) // 移除元素
          this.$store.commit("SET_REMOVE_NOT_USE_STICKER", false) // 重置 ，需要移除不共用贴纸状态
          this.$store.commit("SET_ADDED_NOT_USE_STICKER", false) // 重置， 是否已添加不共用贴纸状态
        }
      },
    },
    stickerInfo: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (newValue && newValue !== oldValue && this.perspective === "front") {
          // console.log("===== stickerInfo changed ======", this.canvas);
          this.addSvg(newValue)
          this.SET_STICKER_INFO(null)
        }
      },
    },
    getColorSeriesId: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if (this.perspective == "front" && oldValue != undefined && oldValue != null && newValue !== oldValue) {
          // 检测画布有内容，才进行提示，
          if (!this.canvasIsEmpty) this.$notify("已更改色系，部分旧色彩可能无法使用")
        }
      },
    },
    // 载入半定制设计
    designJSON: {
      immediate: true,
      deep: true,
      handler(newValue, oldValue) {
        if ((this.templateId || this.productId) && newValue[this.perspective]) {
          Toast.loading({
            message: "加载模板中...",
            forbidClick: true,
            duration: 0,
          })
          setTimeout(() => {
            this.loadFromTemplate()
          }, 1500)
          // console.log(`\n 🚀🚀 %c  需要载入半定制设计  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`);
          // this.loadFromTemplate()
        }
      },
    },
  },
  methods: {
    handleResize() {
      console.log("resize")
      this.resizeCanvas()
    },
    async init() {
      this.mcId && this.perspective === "front" && (await this.fetchMCConfig())
      // TODO: 添加触发条件
      await this.initFabric()
      await this.initCanvas()
      await this.initEvents()

      this.SET_OBJECT_IS_EMPTY({ ...this.objectIsEmpty, [this.perspective]: true })

      if (this.stickerInfo && this.perspective === "front") {
        // console.log("===== stickerInfo changed ======", this.canvas);
        this.addSvg(this.stickerInfo)
        this.SET_STICKER_INFO(null)
      }

      if (this.$route.query.fromAvatar) {
        const options = { fromAvatar: true, ...this.getAvatarColor, type: 1 }
        if (this.perspective === "front") this.addSvg({ url: this.getAvatarSvg, options })
      }
    },
    ...mapMutations([
      "SET_COLOR_CONFIG",
      "SET_SLIDER_VAL",
      "SET_OUTPUT_CANVAS_IMAGE",
      "SET_OUTPUT_PREVIEW_IMAGE",
      "SET_ACTIVE_COLOR_KEY",
      "SET_ACTIVE_COLOR",
      "SET_LEFT_SLEEVE_IMAGE",
      "SET_RIGHT_SLEEVE_IMAGE",
      "SET_OUTPUT_CANVAS_IMAGES",
      "SET_OUTPUT_PREVIEW_IMAGES",
      "SET_DESIGN_JSONS",
      "SET_ELEMENT_IDS",
      "SET_IP_IDS",
      "SET_CURRENT_IP_ID",
      "SET_ZOOM_TOGGLE",
      "SET_STICKER_INFO",
      "SET_OBJECT_IS_EMPTY",
      "SET_IS_DRAWING_MODE",
      "CHANGE_INTERCEPT_STATUS",
      "CHANGE_INTERCEPT_IMG",
      "CHANGE_MENU_STATUS",
      "SET_UPLOADED_IMAGE",
      "SET_HISTORY_STEPS",
      "SET_AIGD_INFO",
      "SET_AIGC_INFO",
      "SET_AIGC_INFOS",
      "SET_ARRANGE_COUNT",
      "SET_AIGD_MODE",
      "SET_TIPS_TYPE",
      "SET_LAST_DESIGN_TIMESTAMP"
    ]),

    ...mapActions(["fetchColorLibrary", "fetchMCConfig"]),

    initFabric() {
      //初始化 fabric 设置属性
      fabric.perfLimitSizeTotal = fabric.perfLimitSizeTotal * 2 // 缓存大小
      fabric.maxCacheSideLimit = fabric.maxCacheSideLimit * 2 // 缓存大小限制
      fabric.forceGLPutImageData = true // 使用显卡渲染
      fabric.Object.prototype.originX = fabric.Object.prototype.originY = "center" // 设置所有元素对称点是center
      fabric.Object.prototype.transparentCorners = false // 所有的控制点都不要半透明
      fabric.Object.prototype.toObject = (function (toObject) {
        // 将自定义数据再次复原在Object上面
        return function (properties) {
          return fabric.util.object.extend(toObject.call(this, properties), {
            // minScaleLimit: this.minScaleLimit || 0,
            scaleX: this.scaleX,
            scaleY: this.scaleY,
            url: this.url || "",
            typeId: this.typeId || "",
            colorKey: this.colorKey || "",
            ipId: this.ipId || "",
            elementId: this.elementId || "",
            defaultId: this.defaultId || "",
            isArtist: this.isArtist || false,
            isCarrierClass: this.isCarrierClass || false,
            isAvatar: this.isAvatar || false,
            colorConfig: this.colorConfig || {},
            batchId: this.batchId || "",
            // 图片类型，需要放置的，目前主要为用户上传图片和我的素材
            cropFlag: this.cropFlag || false,
            imgUrl: this.imgUrl || "",
            originImgUrl: this.originImgUrl || "",
            croppedImgUrl: this.croppedImgUrl || "",
            segmentedImgUrl: this.segmentedImgUrl || "",
          })
        }
      })(fabric.Object.prototype.toObject)
      fabric.Textbox.prototype.editable = false
      fabric.Canvas.prototype.clearRedo = function () {
        this.historyRedo = []
      }
      fabric.Canvas.prototype.goOnHistory = function () {
        this.historyProcessing = false
      }
    },

    async initCanvas() {
      this.canvas = new fabric.Canvas(this.canvasId, {
        allowTouchScrolling: true,
        preserveObjectStacking: true,
      })

      // initCenteringGuidelines(this.canvas);
      this.channel !== "admin" && initAligningGuidelines(this.canvas) //如果在中台,不显示辅助线
      this.canvas.offHistory()

      this.resizeCanvas()
      this.canvas.controlsAboveOverlay = true

      this.initBus()
      await this.initCarrier() // 初始化载体
      await this.initBound() // 初始化框
      await this.initOverlay() // 初始化浮层
      ;(this.perspective === "front" || this.perspective === "back") && this.initSleevesClipPath()
      !this.mcId && this.perspective === "front" && (await this.initBadgeArea())

      controls.renderControls(this.canvas, this.perspective)
      this.canvas.controlsAboveOverlay = true

      this.setBrushSize(2)
      await this.setDefaultColor()

      // console.log(this.templateId, this.productId);
      // 载入半定制设计
      // (this.templateId || this.productId) && this.loadFromTemplate();
      // if ((this.templateId || this.productId) && this.designJSON[this.perspective]) {
      //   console.log(`\n 🚀🚀 %c  需要载入半定制设计  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`);
      //   Toast.loading({
      //     message: "加载模板中...",
      //     forbidClick: true,
      //     duration: 0,
      //   })
      //   setTimeout(() => {
      //     this.loadFromTemplate();
      //   }, 1500);
      // }

      this.resetTemplateObjects()

      if (this.mcId && this.perspective === "front") await this.initMCConfig()

      this.zoom150x()

      this.canvas.onHistory()
      this.canvas.historyUndo = []
      this.canvas.historyRedo = []
      this.canvas.clipTo = function (ctx) {
        ctx.rect(0, 0, this.canvas.width, this.canvas.height)
      }
      this.canvas.renderAll()
    },

    setDefaultColor() {
      this.getColorLibrary && this.getColorLibrary.length && this.SET_ACTIVE_COLOR(this.getColorLibrary[0].color)
      this.getColorLibrary && this.getColorLibrary.length && this.setBrushColor(this.getColorLibrary[0].color)
    },

    resetTemplateObjects() {
      this.canvas.getObjects().forEach(o => {
        if (o.defaultId) {
          return
        }
        o.clipPath = this.clipPath

        if (o.typeId == 1) {
          o.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          })
        }
      })
    },

    /*
     * 1. bound
     */
    async loadFromTemplate(force = false) {

      // this.afterAddHandle();

      console.log(`\n 🚀🚀 %c  开始载入载入模版  `, `color: #fadfa3; background: #030307; padding: 5px`, `\n\n`)

      // console.log(force);
      if (this.templateLoaded && !force) return Toast.clear();//通过templateId参数来进入二次定制, 进入就调用loadFromTemplate, force是方便模板模块反复调用的

      if (!this.designJSON[this.perspective]) return Toast.clear();// 通过templateId参数来进入二次定制,这面没有就不调用

      // this.canvas.goOnHistory();
      this.canvas.offHistory();

      this.allowArrangeFlag = false // 禁止显示排版方式菜单

      this.canvas.getObjects().forEach(o => {
        //清除之前的objects
        !o.isCarrierClass && this.canvas.remove(o)
      })

      const { objects: designJSONObjects, colorConfig } = JSON.parse(this.designJSON[this.perspective]) //拿到接口传回来的designJSON
      // console.log(designJSONObjects)
      console.log(`\n 🚀🚀 %c  模版设计数据  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, designJSONObjects);
                  
      // TODO: 待优化字体 目前是加载全部字体 以后要遍历设计的字体来按需加载
      const fontPromise = []
      this.fontList.forEach(({ font_family_name: fontFamily, font_value: url }) => {
        const font = new FontFace(fontFamily, `url(${url})`)
        fontPromise.push(
          new Promise(resolve => {
            font
              .load()
              .then(() => {
                document.fonts.add(font)
                resolve(true)
              })
              .catch(() => {
                resolve(`${ fontFamily }: load error`)
              })
          }),
        )
      })

      const loadAllFontsResult = await Promise.allSettled(fontPromise);
      console.log(`\n 🚀🚀 %c  加载所有字体结果  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, loadAllFontsResult);
      
      const { boundX, boundY, carrierWidth } = this.carrierConfig[this.perspective]

      //template center
      const templateLeft = designJSONObjects[0].left
      const templateTop = designJSONObjects[0].top
      const centerX = this.centerX + (boundX / carrierWidth) * this.canvas.width
      const centerY = this.centerY + (boundY / carrierWidth) * this.canvas.height
      const templateScale = centerX / templateLeft //通过画布中点left值来算出当前载体应该放大多少倍
      const templateOffsetX = centerX - templateLeft
      const templateOffsetY = centerY - templateTop
      delete colorConfig.defaultColor

      this.SET_COLOR_CONFIG({ ...this.getColorConfig, ...colorConfig })


      new Promise(resolve => {
        fabric.util.enlivenObjects(designJSONObjects, async objects => {
          //给所有的Objects调整controls
          objects.forEach(async o => {
            if (o.defaultId === "bound") {
              o.defaultId = "tempBound"
              o.opacity = 0
              o.isCarrierClass = false
              this.tempBound = o
              this.canvas.add(o)
              return
            }
            if (o.isCarrierClass && o.defaultId !== "bound") {
              return
            }

            // 如果是AIGD载体，不允许拖动，且不可更换载体
            if (o.defaultId == "aigdCarrierImage") {
            //   o.selectable = false;// 不允许拖动
              this.SET_AIGD_MODE(true);// 设定为AIGD创作模式
            }

            // o.scaleX = o.scaleX * templateScale;
            // o.scaleY = o.scaleY * templateScale;
            // o.left = o.left * templateScale;
            // o.top = o.top + templateOffsetY ;
            o.clipPath = this.clipPath
            this.canvas.add(o)
            if (o.typeId == 1 || o.type == "group") {
              o.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                tr: false,
                editControl: false,
              })
            }
          })
          resolve()
        })
      }).then(() => {

        // this.canvas.offHistory();

        const group = this.canvas.getObjects().filter(o => !o.isCarrierClass) //将非载体的初始元素编成一组

        const selectedObject = new fabric.ActiveSelection(group, {
          canvas: this.canvas,
        })

        // const selectedObjectLeft = (selectedObject.left - templateLeft) * templateScale + this.canvas.width / 2; //位置根据之前的left进行相减得出偏移量
        // const selectedObjectTop = (selectedObject.top - templateTop) * templateScale + this.canvas.height / 2; //位置根据之前的top进行相减得出偏移量

        // console.log({ selectedObjectLeft, selectedObjectTop });

        // this.canvas.setActiveObject(selectedObject);
        // this.canvas.centerObject(selectedObject);

        // console.log(templateScale);

        // selectedObject.set({
        //   left: selectedObjectLeft,
        //   top: selectedObjectTop,
        // });
        // selectedObject.set({
        //   scaleX: selectedObject.scaleX * templateScale,
        //   scaleY: selectedObject.scaleY * templateScale,
        // });

        this.canvas.setActiveObject(selectedObject)
        this.canvas.centerObject(selectedObject)

        selectedObject.set({
          scaleX: selectedObject.scaleX * (this.bound.width / this.tempBound.width),
          scaleY: selectedObject.scaleY * (this.bound.width / this.tempBound.width),
        })

        const originClipOffsetX = (boundX / carrierWidth) * this.canvas.width
        const originClipOffsetY = (boundY / carrierWidth) * this.canvas.width

        selectedObject.set({
          left: this.canvas.width / 2 + originClipOffsetX,
          top: this.canvas.height / 2 + originClipOffsetY,
        })

        this.canvas.discardActiveObject()

        this.canvas
          .getObjects()
          .filter(o => o.defaultId === "tempBound")
          .forEach(o => {
            this.canvas.remove(o)
          })
        
        this.canvas.goOnHistory()

        this.canvas.renderAll()
        // console.log(`\n 🚀🚀 %c  this.canvas  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, this.canvas);

        // this.canvas.historyNextState = ""
        // this.canvas.clearHistory()

        // this.canvas.goOnHistory()
        // this.afterAddHandle();

        // this.canvas.historyRedo = [];
        // const lastUndo = this.canvas.historyUndo[this.canvas.historyUndo.length - 1];
        // this.canvas.historyUndo = [lastUndo]

        // this.SET_HISTORY_STEPS(this.canvas) // 将历史操作步数，同步至VUEX

        console.log(`\n 🚀🚀 %c  载入模版后的canvas `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, this.canvas);
        
        Toast.clear()

        this.allowArrangeFlag = true // 允许排版方式菜单

        this.templateLoaded = true


        // this.afterAddHandle();

      })
      console.log(this.canvas)
    },

    async initCarrier() {
      this.carrier = this.carrierConfig[this.perspective].carrierImage

      // 把AIGD添加到画布
      if (this.perspective == "front" && this.aigdInfo) {
        await this.addCarrierImage(this.aigdInfo.url, { selectable: false, isAigdCarrier: true })
      }

      // 添加载体图片
      await this.addCarrierImage(this.carrier, { selectable: false })

      this.resetInitialObjects()
      this.canvas.renderAll()

      // 如果载体分类是 贴纸: 19；硅胶手机壳: 3、
      // todo: 贴纸类的载体，自动放大。 24-01-18。
    },

    async initOverlay() {
      const { overlayImage } = this.carrierConfig[this.perspective]

      if (!overlayImage) return

      let url = overlayImage

      // TODO: 中台配置接入

      await new Promise(resolve =>
        fabric.Image.fromURL(
          url,
          async (img, loadError) => {
            if (loadError) return Toast("图片加载失败")

            img.set({
              left: this.centerX,
              top: this.centerY,
              originX: "center",
              originY: "center",
              scaleX: (this.bound.width / img.width).toFixed(2),
              scaleY: (this.bound.width / img.width).toFixed(2),
              backgroundColor: "transparent",
              selectable: false,
              defaultId: "overlayImage",
              isCarrierClass: true,
              opacity: 0.8,
            })
            //浮层的图片
            this.canvas.setOverlayImage(img, this.canvas.renderAll.bind(this.canvas))

            resolve()
          },
          { crossOrigin: "Anonymous" },
        ),
      )
    },

    initSleevesClipPath() {
      const { leftX, leftY, leftWidth, leftHeight, leftAngle, rightX, rightY, rightWidth, rightHeight, rightAngle, carrierWidth } = this.carrierConfig[this.perspective]
      let leftOriginX, rightOriginX
      if (this.perspective === "front") {
        leftOriginX = "right"
        rightOriginX = "left"
      } else {
        leftOriginX = "left"
        rightOriginX = "right"
      }
      this.leftSleeveClipPath = new fabric.Rect({
        left: (leftX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.1,
        top: (leftY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.1,
        originX: leftOriginX,
        originY: "center",
        width: (((leftWidth * this.canvas.getZoom()) / carrierWidth) * this.canvas.width) / 2,
        height: ((leftHeight * this.canvas.getZoom()) / carrierWidth) * this.canvas.width,
        absolutePositioned: true,
        angle: leftAngle,
      })
      this.rightSleeveClipPath = new fabric.Rect({
        left: (rightX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.1,
        top: (rightY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.1,
        originX: rightOriginX,
        originY: "center",
        width: (((rightWidth * this.canvas.getZoom()) / carrierWidth) * this.canvas.width) / 2,
        height: ((rightHeight * this.canvas.getZoom()) / carrierWidth) * this.canvas.width,
        absolutePositioned: true,
        angle: rightAngle,
      })
    },

    // 初始化框 2023年2月1日17:10:08
    async initBound() {
      // console.log("object", this.canvas.getObjects());

      let { boundX, boundY, boundWidth, boundHeight, carrierWidth, clipRadius } = this.carrierConfig[this.perspective] // 这里的单位是cm
      let x, y, width, height
      if (this.mcId && this.perspective === "front") {
        const { region_x, region_y, region_width, region_height } = this.mcConfig.design_region[0]
        x = region_x
        y = region_y
        width = region_width
        height = region_height
        boundWidth = (this.canvas.width * width) / carrierWidth
        boundHeight = (((this.canvas.width * width) / carrierWidth) * height) / width
      } else {
        x = boundX //在createClipPath会再次计算
        y = boundY //在createClipPath会再次计算
        clipRadius = (this.canvas.width * clipRadius) / carrierWidth
        boundWidth = (this.canvas.width * boundWidth) / carrierWidth
        boundHeight = (this.canvas.width * boundHeight) / carrierWidth
      }

      // rx, ry 兼容圆角剪切

      this.clipPath = this.createClipPath({
        x,
        y,
        rx: clipRadius || 0,
        ry: clipRadius || 0,
        width: boundWidth,
        height: boundHeight,
        stroke: "#fff",
        defaultId: "clipPath",
        isCarrierClass: true,
      })
      this.clipPath.originX = "center"
      this.clipPath.originY = "center"
    },

    //初始化胸标 2023年2月1日17:10:06
    async initBadgeArea() {
      const { badgeX, badgeY, boundWidth, carrierWidth } = this.carrierConfig[this.perspective]
      if (badgeX == "0" && badgeY == "0") return

      await new Promise(resolve =>
        //loadSVGFromURL 通过svg的url链接 xxx.svg 来载入对象iconGroup
        fabric.loadSVGFromURL(spec2, (objects, d) => {
          const iconGroup = fabric.util.groupSVGElements(objects, d)
          iconGroup.set({
            left: this.centerX + (badgeX / carrierWidth) * this.canvas.width,
            top: this.centerY + (badgeY / carrierWidth) * this.canvas.width,

            absolutePositioned: true,
            selectable: false,
            opacity: 0.5,
            defaultId: "spec2",
            isCarrierClass: true,
          })

          iconGroup.scale(((this.bound.width / boundWidth) * 10) / iconGroup.width)

          this.spec2 = iconGroup
          this.canvas.add(this.spec2) // badge
          resolve()
        }),
      )
    },

    async initMCConfig() {
      await this.initLogo()
    },

    async initLogo() {
      const { logo_color: logoColor, logo_img: logoUrl } = this.mcConfig
      const { logo_width: logoWidth, logo_x: logoX, logo_y: logoY, logo_angle: logoAngle, region_width: boundOriginWidth } = this.mcConfig.design_region[0]
      const { carrierWidth } = this.carrierConfig[this.perspective]
      await new Promise(resolve => {
        fabric.Image.fromURL(
          logoUrl,
          (img, loadError) => {
            if (loadError) return Toast("图片加载失败")

            img.set({
              left: this.centerX + (logoX / carrierWidth) * this.canvas.width,
              top: this.centerY + (logoY / carrierWidth) * this.canvas.width,
              originX: "center",
              originY: "center",
              selectable: false,
              angle: logoAngle,
              // defaultId: "mcConfig",
              // isCarrierClass: true
            })
            img.scale(((this.bound.width / boundOriginWidth) * logoWidth) / img.width)
            this.canvas.add(img)
            const color = this.getColorLibrary.filter(({ color: colorValue }) => colorValue === logoColor)[0]
            const tertiaryColor = { ...color }
            tertiaryColor.isLocked = true
            tertiaryColor.isLogo = true

            this.SET_COLOR_CONFIG({ ...this.getColorConfig, tertiaryColor })
            resolve()
          },
          { crossOrigin: "anonymous" },
        )
      })
    },

    // 【ID1000215】4.4 创作中心-用户-挑选载体
    async setCarrier() {
      Toast.loading({
        message: "",
        forbidClick: true,
        overlay: true,
        duration: 0,
      })
      this.resetZoom() // 重置zoom level至 1
      this.canvas.discardActiveObject() //取消所有选中贴纸
      this.canvas.offHistory() // 关闭历史记录
      this.getObjectsByDefaultId("leftSleeveImage").forEach(o => {
        this.canvas.remove(o)
      }) //移除左右袖图片
      this.getObjectsByDefaultId("rightSleeveImage").forEach(o => {
        this.canvas.remove(o)
      })
      console.log("clone出来的bound", this.getObjectByDefaultId("bound"))
      this.getObjectByDefaultId("bound").clone(cloned => {
        cloned.defaultId = "tempBound"
        cloned.opacity = 0
        cloned.isCarrierClass = false
        this.tempBound = cloned
        this.canvas.add(cloned)
      })

      //设置默认颜色

      // 更换载体后，获取新的载体相关的颜色配置
      await this.fetchColorLibrary()

      await this.setDefaultColor()

      const carrier = this.canvas.getObjects().filter(o => o.defaultId === "carrierImage")[0] //this.getObjectDefaultId()
      const colorConfig = { ...this.getColorConfig }
      colorConfig.defaultColor = {
        ...colorConfig.defaultColor,
        ...this.getColorLibrary.filter(item => item.id.toString() === this.carrierConfig.color_id)[0],
      } || {
        color: "",
        id: "",
        type: "",
        title: "",
        isLocked: true,
      }
      this.SET_COLOR_CONFIG(colorConfig)

      this.carrier = this.carrierConfig[this.perspective].carrierImage //切换载体图片
      await new Promise(resolve => {
        carrier &&
          carrier.setSrc(
            this.carrierConfig[this.perspective].carrierImage,
            () => {
              this.canvas.renderAll()
              resolve()
            },
            { crossOrigin: "anonymous" },
          )
      })

      let { boundX, boundY, badgeX, badgeY, boundWidth, boundHeight, carrierWidth, clipRadius } = this.carrierConfig[this.perspective]
      let x, y, width, height

      if (this.mcId && this.perspective === "front") {
        // 企业定制下的设置载体
        const { region_x, region_y, region_width, region_height } = this.mcConfig.design_region[0]
        x = region_x
        y = region_y
        width = region_width
        height = region_height
        boundWidth = (this.canvas.width * width) / carrierWidth
        boundHeight = (((this.canvas.width * width) / carrierWidth) * height) / width

        this.getObjectByDefaultId("bound").set({
          // 获取上次的设计区域并重新应用
          width: boundWidth,
          height: boundHeight,
          left: this.centerX + (x / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
          top: this.centerY + (y / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
        })
        this.clipPath.set({
          width: boundWidth,
          height: boundHeight,
          left: this.centerX + (x / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
          top: this.centerY + (y / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
        })
      } else {
        this.getObjectByDefaultId("bound").set({
          rx: (clipRadius / carrierWidth) * this.canvas.width ?? 5,
          ry: (clipRadius / carrierWidth) * this.canvas.width ?? 5,
          width: (boundWidth / carrierWidth) * this.canvas.width,
          height: (boundHeight / carrierWidth) * this.canvas.width,
          left: this.centerX + (boundX / carrierWidth) * this.canvas.width,
          top: this.centerY + (boundY / carrierWidth) * this.canvas.width,
        })

        this.boundCenterX = this.getObjectByDefaultId("bound").left
        this.boundCenterY = this.getObjectByDefaultId("bound").top

        this.clipPath.set({
          rx: (clipRadius / carrierWidth) * this.canvas.width,
          ry: (clipRadius / carrierWidth) * this.canvas.width,
          width: (boundWidth / carrierWidth) * this.canvas.width,
          height: (boundHeight / carrierWidth) * this.canvas.width,
          left: this.centerX + (boundX / carrierWidth) * this.canvas.width,
          top: this.centerY + (boundY / carrierWidth) * this.canvas.width,
        })

        const badge = this.getObjectByDefaultId("spec2") // 获取上次的胸标并重新应用

        if (badge) {
          badge.set({
            left: this.centerX + (this.carrierConfig[this.perspective].badgeX / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
            top: this.centerY + (this.carrierConfig[this.perspective].badgeY / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.width,
          })
        }

        if (badgeX == "0" && badgeY == "0") {
          badge && (badge.opacity = 0)
        } else {
          badge && (badge.opacity = 0.5)
        }
      }
      if (!(this.carrierConfig.left == null && this.carrierConfig.right == null)) {
        if (this.perspective === "front" || this.perspective === "back") {
          this.getObjectsByDefaultId("leftSleeveImage").forEach(o => {
            this.canvas.remove(o)
          })
          this.getObjectsByDefaultId("rightSleeveImage").forEach(o => {
            this.canvas.remove(o)
          })

          this.initSleevesClipPath()
          this.$bus.$emit("left:exportPerspectiveCanvas")
          this.$bus.$emit("right:exportPerspectiveCanvas")
          this.$bus.$emit("front:renderPerspectiveCanvas")
          this.$bus.$emit("back:renderPerspectiveCanvas")
        }
      }

      const group = this.canvas.getObjects().filter(o => !o.isCarrierClass)

      const selectedObject = new fabric.ActiveSelection(group, {
        canvas: this.canvas,
      })
      this.canvas.setActiveObject(selectedObject) //定位已经做好的设计
      // const { left: al, top: at, width: aw, height: ah } = this.canvas.getActiveObject();
      // const ar = al + aw;
      // const ab = at + ah;
      // const { left: bl, top: bt, width: bw, height: bh } = this.getObjectByDefaultId("bound");
      // const br = bl + bw;
      // const bb = bt + bh;

      // if (ar < bl || ab < bt || al > br || at > bb) {

      this.canvas.centerObject(selectedObject)

      selectedObject.set({
        left: selectedObject.left + (this.carrierConfig[this.perspective].boundX / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.getZoom() * this.canvas.width,
        top: selectedObject.top + (this.carrierConfig[this.perspective].boundY / this.carrierConfig[this.perspective].carrierWidth) * this.canvas.getZoom() * this.canvas.width,
      })
      // }

      this.canvas
        .getObjects()
        .filter(o => o.defaultId === "tempBound")
        .forEach(o => {
          this.canvas.remove(o)
        })
      this.canvas.discardActiveObject()
      this.canvas.renderAll()

      this.getAvatarObjects().forEach(o => {
        if (o.styleType == 2) {
          o.getObjects().forEach(icon => {
            if (icon.id === "light") {
              icon.set({ fill: this.getColorConfig.defaultColor.color })
            }
          })
        }
      })

      if (this.carrierConfig[this.perspective].overlayImage) {
        //如果有浮层
        this.initOverlay()
      } else {
        this.canvas.overlayImage = null
      }

      this.canvas.renderAll()

      this.zoom150x()

      this.canvas.historyNextState = ""
      this.canvas.clearHistory()
      this.canvas.goOnHistory()
      Toast.clear()
    },

    getAvatarObjects() {
      return this.canvas.getObjects().filter(o => o.isAvatar)
    },

    initBus() {
      this.$bus.$on(this.perspective + ":addSvg", this.addSvg) // 添加单个元素
      this.$bus.$on(this.perspective + ":addGroup", this.addGroup) // 添加一组
      this.$bus.$on(this.perspective + ":arrangeGroup", this.arrangeGroup) // 排版选中组合
      this.$bus.$on(this.perspective + ":addFont", this.addFont)
      this.$bus.$on(this.perspective + ":setZoomToggle", this.setZoomToggle)
      this.$bus.$on(this.perspective + ":resetZoom", this.resetZoom)
      this.$bus.$on(this.perspective + ":undo", this.undo)
      this.$bus.$on(this.perspective + ":redo", this.redo)
      this.$bus.$on(this.perspective + ":bringForward", this.bringForward)
      this.$bus.$on(this.perspective + ":sendBackwards", this.sendBackwards)
      this.$bus.$on(this.perspective + ":setObjectColor", this.setObjectColor)
      this.$bus.$on(this.perspective + ":renderColorConfig", this.renderColorConfig)
      this.$bus.$on(this.perspective + ":exportCanvas", this.exportCanvas)
      this.$bus.$on(this.perspective + ":getStickerLength", this.getStickerLength)
      this.$bus.$on(this.perspective + ":unlockColorConfig", this.unlockColorConfig)
      this.$bus.$on(this.perspective + ":inputText", this.inputText)
      this.$bus.$on(this.perspective + ":alignText", this.alignText)
      this.$bus.$on(this.perspective + ":scaleText", this.scaleText)
      this.$bus.$on(this.perspective + ":addUploadedImage", this.addUploadedImage)
      this.$bus.$on(this.perspective + ":setCarrier", this.setCarrier)
      this.$bus.$on(this.perspective + ":enterDrawingMode", this.enterDrawingMode)
      this.$bus.$on(this.perspective + ":exitDrawingMode", this.exitDrawingMode)
      this.$bus.$on(this.perspective + ":setBrushSize", this.setBrushSize)
      this.$bus.$on(this.perspective + ":saveSnapshot", this.saveSnapshot)
      this.$bus.$on("loadFromTemplate", this.loadFromTemplate)
      this.$bus.$on(this.perspective + ":loadFromTemplate", this.loadFromTemplate)

      this.$bus.$on(this.perspective + ":zoomIn", this.zoomIn)
      this.$bus.$on(this.perspective + ":zoom150x", this.zoom150x)
      this.$bus.$on(this.perspective + ":replaceUploadedImage", this.replaceUploadedImage)

      if (this.perspective == "front" || this.perspective == "back") {
        this.$bus.$on(this.perspective + ":renderPerspectiveCanvas", this.renderPerspectiveCanvas)
      }
      if (this.perspective == "left" || this.perspective == "right") {
        this.$bus.$on(this.perspective + ":exportPerspectiveCanvas", this.exportPerspectiveCanvas)
      }

      this.$bus.$on(this.perspective + ":renderAllPerspectiveCanvas", this.renderAllPerspectiveCanvas)
    },
    destroyBus() {
      this.$bus.$off(this.perspective + ":addSvg")
      this.$bus.$off(this.perspective + ":addGroup")
      this.$bus.$off(this.perspective + ":arrangeGroup")
      this.$bus.$off(this.perspective + ":addFont")
      this.$bus.$off(this.perspective + ":setZoomToggle")
      this.$bus.$off(this.perspective + ":resetZoom")
      this.$bus.$off(this.perspective + ":undo")
      this.$bus.$off(this.perspective + ":redo")
      this.$bus.$off(this.perspective + ":bringForward")
      this.$bus.$off(this.perspective + ":sendBackwards")
      this.$bus.$off(this.perspective + ":setObjectColor")
      this.$bus.$off(this.perspective + ":renderColorConfig")
      this.$bus.$off(this.perspective + ":exportCanvas")
      this.$bus.$off(this.perspective + ":getStickerLength")
      this.$bus.$off(this.perspective + ":unlockColorConfig")
      this.$bus.$off(this.perspective + ":inputText")
      this.$bus.$off(this.perspective + ":alignText")
      this.$bus.$off(this.perspective + ":scaleText")
      this.$bus.$off(this.perspective + ":addUploadedImage")
      this.$bus.$off(this.perspective + ":setCarrier")
      this.$bus.$off(this.perspective + ":enterDrawingMode")
      this.$bus.$off(this.perspective + ":exitDrawingMode")
      this.$bus.$off(this.perspective + ":setBrushSize")
      this.$bus.$off(this.perspective + ":saveSnapshot")
      this.$bus.$off("loadFromTemplate")
      this.$bus.$off(this.perspective + "loadFromTemplate")

      this.$bus.$off(this.perspective + ":zoomIn")
      this.$bus.$off(this.perspective + ":zoom150x")
      this.$bus.$off(this.perspective + ":replaceUploadedImage")

      if (this.perspective == "front" || this.perspective == "back") {
        this.$bus.$off(this.perspective + ":renderPerspectiveCanvas")
      }
      if (this.perspective == "left" || this.perspective == "right") {
        this.$bus.$off(this.perspective + ":exportPerspectiveCanvas")
      }

      this.$bus.$off(this.perspective + ":renderAllPerspectiveCanvas")
    },

    // 生成每个面的图片
    async renderAllPerspectiveCanvas(resolve) {
      let that = this

      // note: 23-11-06， 加入了aigd的判断条件。
      // 检测当前面，是否有aigd内容，如果有且画布不为空，则导出aigd内容，如果没有，则导出设计图
      const hasAIGDInfo = this.getObjectsByDefaultId("aigdCarrierImage")
      if (hasAIGDInfo.length == 0 && this.canvasIsEmpty()) {
        // 如果没有aigd信息，且这一面设计是空的
        //如果没有这一面，就使用默认载体图
        this.fillPreviewImageByDefault()
        resolve && resolve()
        return
      }

      that.zoomIn()
      that.exportCanvas(resolve)
    },

    async renderPerspectiveCanvas() {
      const { leftX, leftY, leftWidth, leftHeight, leftAngle, rightX, rightY, rightWidth, rightHeight, rightAngle, carrierWidth } = this.carrierConfig[this.perspective]

      const leftSleeve = this.canvas.getObjects().filter(o => o.defaultId === "leftSleeveImage")[0]
      const rightSleeve = this.canvas.getObjects().filter(o => o.defaultId === "rightSleeveImage")[0]

      if (!leftSleeve) {
        await new Promise(resolve => {
          fabric.Image.fromURL(
            this.getSleeveImages["leftSleeveImage"],
            async (img, loadError) => {
              if (loadError) return Toast("图片加载失败")

              img.set({
                left: (leftX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.2,
                top: (leftY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.2,
                originX: "center",
                originY: "center",
                backgroundColor: "transparent",
                selectable: false,
                defaultId: "leftSleeveImage",
                isCarrierClass: true,
                angle: leftAngle,
              })

              img.clipPath = this.leftSleeveClipPath
              img.scale(((leftWidth / carrierWidth) * this.canvas.width) / img.width)

              this.leftSleeve = img
              this.canvas.add(img)

              this.canvas.renderAll()
              resolve()
            },
            { crossOrigin: "Anonymous" },
          )
        })
      } else {
        await new Promise(resolve => {
          leftSleeve.setSrc(this.getSleeveImages["leftSleeveImage"], () => {
            leftSleeve.scale(((10 / carrierWidth) * this.canvas.width) / leftSleeve.width)
            leftSleeve.setCoords()
            this.canvas.renderAll()
            resolve()
          })
        })
      }

      if (!rightSleeve) {
        await new Promise(resolve => {
          fabric.Image.fromURL(
            this.getSleeveImages["rightSleeveImage"],
            async (img, loadError) => {
              if (loadError) return Toast("图片加载失败")
              img.set({
                left: (rightX / carrierWidth) * this.canvas.width + this.canvas.width / 2 - 0.2,
                top: (rightY / carrierWidth) * this.canvas.width + this.canvas.height / 2 - 0.2,
                originX: "center",
                originY: "center",
                backgroundColor: "transparent",
                selectable: false,
                defaultId: "rightSleeveImage",
                isCarrierClass: true,
                angle: rightAngle,
              })
              img.clipPath = this.rightSleeveClipPath
              img.scale(((rightWidth / carrierWidth) * this.canvas.width) / img.width)
              this.rightSleeve = img
              this.canvas.add(img)
              this.canvas.renderAll()
              resolve()
            },
            { crossOrigin: "Anonymous" },
          )
        })
      } else {
        await new Promise(resolve => {
          rightSleeve.setSrc(this.getSleeveImages["rightSleeveImage"], () => {
            rightSleeve.scale(((10 / carrierWidth) * this.canvas.width) / rightSleeve.width)
            rightSleeve.setCoords()
            this.canvas.renderAll()
            resolve()
          })
        })
      }
    },

    async exportPerspectiveCanvas() {
      this.clipPath && (this.clipPath.opacity = 0)
      this.bound && (this.bound.opacity = 0)
      this.spec2 && (this.spec2.opacity = 0)
      this.carrierImage && (this.carrierImage.opacity = 0)
      const outputCanvasImage = this.canvas.toDataURL({
        width: this.clipPath.width * this.canvas.getZoom(),
        height: this.clipPath.height * this.canvas.getZoom(),
        left: this.clipPath.left - this.clipPath.width / 2,
        top: this.clipPath.top - this.clipPath.height / 2,
        multiplier: 300 / this.clipPath.width,
        // multiplier: 1,
        format: "png",
        crossOrigin: "Anonymous",
      })
      this.bound && (this.bound.opacity = 0.5)
      this.clipPath && (this.clipPath.opacity = 0.5)
      this.carrierImage && (this.carrierImage.opacity = 1)
      // console.log(`SET_${this.perspective.toUpperCase()}_SLEEVE_IMAGE` + 'outputCanvasImage-->', outputCanvasImage)
      this[`SET_${this.perspective.toUpperCase()}_SLEEVE_IMAGE`](outputCanvasImage)
    },

    unlockColorConfig() {
      const colorConfig = { ...this.getColorConfig }
      for (let key in this.getColorConfig) {
        if (key == "defaultColor" || this.getColorConfig[key].isLogo == true) {
          continue
        }
        colorConfig[key].isLocked = false
      }
      this.SET_COLOR_CONFIG(colorConfig)
    },

    setObjectColor(value) {
      console.log(value)
      this.setBrushColor(value)
      if (this.carrierConfig.is_knitting == "1") {
        const colorKey = value?.colorKey
        if (!colorKey) {
          if (this.canvas.getActiveObject()) {
            if (this.canvas.getActiveObject()["_objects"]) {
              this.canvas.getActiveObject()["_objects"].forEach(obj => {
                obj.colorKey && obj.set("currentColor", this.getColorConfig[obj.colorKey].color)
              })
            } else {
              this.canvas.getActiveObject()?.colorKey && this.canvas.getActiveObject().set("currentColor", this.getColorConfig[this.canvas.getActiveObject().colorKey].color)
            }
          }
          this.renderColorConfig()
          return
        }
        if (this.canvas.getActiveObject()) {
          if (this.canvas.getActiveObject()["_objects"]) {
            this.canvas.getActiveObject()["_objects"].forEach(obj => {
              obj.set("colorKey", colorKey)
              obj.set("currentColor", this.getColorConfig[colorKey].color)
            })
          } else {
            this.canvas.getActiveObject().set("colorKey", colorKey)
            this.canvas.getActiveObject().set("currentColor", this.getColorConfig[colorKey].color)
          }
          this.renderColorConfig()
        }
      } else {
        console.log(this.canvas.getActiveObject())
        if (this.canvas.getActiveObject()) {
          if (this.canvas.getActiveObject().type == "activeSelection") {
            this.canvas.getActiveObject()["_objects"].forEach(obj => {
              obj.set("currentColor", value)
              console.log(obj)
              if (obj.defaultId === "drawingPath") {
                obj["_objects"].forEach(o => {
                  o.set("stroke", value)
                })
              } else {
                obj.set("fill", value)
              }
            })
          } else {
            this.canvas.getActiveObject().set("currentColor", value)
            if (this.canvas.getActiveObject().defaultId === "drawingPath") {
              console.log(this.canvas.getActiveObject())
              this.canvas.getActiveObject()["_objects"].forEach(obj => {
                obj.set("currentColor", value)
                obj.set("stroke", value)
              })
            } else {
              this.canvas.getActiveObject().set("fill", value)
            }
          }
        }
      }
      this.carrierImage.colorConfig = this.getColorConfig
      this.canvas.renderAll()
    },

    renderColorConfig() {
      // if (this.mode === "ipmode") return;
      if (!this.carrierConfig.is_knitting == "1") return
      this.canvas.getObjects().forEach(obj => {
        if (![1].includes(obj.typeId) && this.$route.matched[1].path.indexOf("artist") == -1) {
          if (obj.typeId != 1) {
            if (obj.defaultId === "drawingPath") {
              obj.colorKey && obj.set("stroke", this.getColorConfig[obj.colorKey].color)
            } else {
              obj.colorKey && obj.set("fill", this.getColorConfig[obj.colorKey].color)
            }
          }
        }
      })
      this.canvas.renderAll()
    },

    // 【ID1000271】4.15.2 创作中心-用户-撤回
    undo: _.debounce(async function () {
      // console.log(this.canvas);
      try {

        this.canvas.renderAll();

        Toast.loading({
          message: "",
          forbidClick: true,
          duration: 3000,
        })

        // this.$logs("撤回上一步", this.canvas);
        // this.$logs("撤回上一步", this.canvas.getObjects().filter(o => o.typeId));
        // if (this.canvas && this.canvas.getObjects().filter(o => o.typeId) == 0) return this.$logs("已经撤回到最后一步了");
        // if (this.canvas && this.canvas.getObjects().filter(o => o.typeId) == 0) return Toast("无法撤回了");

        await new Promise(resolve => {
          this.canvas.undo(() => {
            Toast.clear();
            resolve()
          })
        })

        this.SET_HISTORY_STEPS(this.canvas)

        // Toast.clear();

        this.canvas.offHistory()

        this.canvas.getObjects().forEach(o => {
          if (o.typeId == 1) {
            //设置贴纸控制器的配置
            o.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              tr: false,
              editControl: false,
            })
          }
          if (o.defaultId == "carrierImage") {
            //设置载体的参数
            this.carrierImage = o
            this.carrierConfig.is_knitting == "1" && this.SET_COLOR_CONFIG({ ...this.getColorConfig, ...o.colorConfig })
          }

          if (o.defaultId == "") {
            //设置画笔的参数
            if (this.canvas.isDrawingMode == true) {
              o.defaultId = "drawingPath"
              o.batchId = this.batchId
              o.clipPath = this.clipPath
            }
            o.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              tr: false,
              editControl: false,
            })
          }

          if (o.defaultId == "drawingPath") {
            //设置画笔控制器的参数
            o.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              tr: false,
              editControl: false,
            })
          }
        })

        Toast.clear()

        ;(this.perspective === "front" || this.perspective === "back") && (await this.renderPerspectiveCanvas())
        await this.resetHistoryCanvas()
        this.canvas.goOnHistory()
        this.canvas.renderAll();

      } catch (error) {
        console.error(`\n 🚀🚀 %c  重做失败 `, `color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, error)
        Toast.clear()
        this.canvas.renderAll();
      }
    }, 200),

    // 【ID1000271】4.15.2 创作中心-用户-重做
    redo: _.debounce(async function () {
      // this.$logs("重做", this.canvas);
      try {
        this.canvas.renderAll();

        Toast.loading({
          message: "",
          forbidClick: true,
          duration: 3000,
        })

        await new Promise(resolve => {
          this.canvas.redo(() => {
            Toast.clear();
            resolve()
          })
        })

        this.SET_HISTORY_STEPS(this.canvas)
        // Toast.clear();

        this.canvas.offHistory()

        this.canvas.getObjects().forEach(o => {
          if (o.typeId == 1 || o.defaultId == "drawingPath") {
            o.setControlsVisibility({
              mt: false,
              mb: false,
              ml: false,
              mr: false,
              tr: false,
              editControl: false,
            })
          }
          if (o.defaultId == "carrierImage") {
            this.carrierImage = o
            this.carrierConfig.is_knitting == "1" && this.SET_COLOR_CONFIG({ ...this.getColorConfig, ...o.colorConfig })
          }
        })
        Toast.clear()

        ;(this.perspective === "front" || this.perspective === "back") && (await this.renderPerspectiveCanvas())
        await this.resetHistoryCanvas()
        this.canvas.goOnHistory()
        this.canvas.renderAll();

      } catch (error) {
        console.error(`\n 🚀🚀 %c  撤回失败 `, `color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, error)
        Toast.clear()
        this.canvas.renderAll();
      }
    }, 200),

    resetColorConfig() {},

    async resetHistoryCanvas() {
      // this.canvas.getObjects().forEach(o => {
      //   if (o.isCarrierClass) {
      //     this.canvas.remove(o);
      //   }
      // });
      // await this.initCarrier();
      // await this.initBound();
      // (!this.mcId && this.perspective === 'front') && (await this.initBadgeArea());
      this.carrierImage = this.getObjectByDefaultId("carrierImage")
      if (this.getObjectByDefaultId("spec2")) this.spec2 = this.getObjectByDefaultId("spec2")
      this.bound = this.getObjectByDefaultId("bound")
      this.resetInitialObjects()
    },

    // 【ID1000272】4.15.3 创作中心-用户-图层顺序 上移图层
    bringForward() {
      this.canvas.bringForward(this.canvas.getActiveObject())
      this.resetInitialObjects()
      this.canvas.renderAll()
    },

    // 【ID1000272】4.15.3 创作中心-用户-图层顺序 下移图层
    sendBackwards() {
      this.canvas.sendBackwards(this.canvas.getActiveObject())
      this.resetInitialObjects()
      this.canvas.renderAll()
    },

    // 重置画布固定内容的层级方法，始终将固定内容放在最底层，从高到低的顺序按照 spec2 -> bound -> aigdCarrierImage -> carrierImage
    resetInitialObjects() {
      const spec2 = this.getObjectByDefaultId("spec2")
      const bound = this.getObjectByDefaultId("bound")
      const carrierImage = this.getObjectByDefaultId("carrierImage")
      const aigdCarrierImage = this.getObjectByDefaultId("aigdCarrierImage")
      if (spec2) this.canvas.sendToBack(spec2)
      if (bound) this.canvas.sendToBack(bound)
      if (aigdCarrierImage) this.canvas.sendToBack(aigdCarrierImage)
      if (carrierImage) this.canvas.sendToBack(carrierImage)
    },

    canvasIsEmpty() {
      return this.canvas.getObjects().filter(o => !o.isCarrierClass).length === 0
    },

    getObjectByDefaultId(id) {
      return this.canvas.getObjects().filter(o => o.defaultId === id)[0]
    },

    getObjectsByDefaultId(id) {
      return this.canvas.getObjects().filter(o => o.defaultId === id)
    },

    //【ID1000270】4.15.1 创作中心-用户-工具栏放大缩小

    setZoomToggle() {
      const widthZoom = this.canvas.width / (this.bound.width + 10)
      const canvasRatio = this.canvas.height / this.canvas.width
      const boundRatio = this.bound.height / this.bound.width

      let zoomValue

      if (boundRatio > canvasRatio) {
        if (canvasRatio < 1) {
          zoomValue = widthZoom
        } else {
          zoomValue = 2
        }
      } else {
        zoomValue = widthZoom
      }

      if (this.perspective === "left" || this.perspective === "right") {
        zoomValue = 2
      }

      if (this.zoomToggle) {
        this.resetZoom()
        this.SET_ZOOM_TOGGLE(true)
        this.canvas.zoomToPoint(
          // 以框中心点为中心放大
          new fabric.Point(this.bound.left, this.bound.top),
          zoomValue.toFixed(2),
        )

        // 计算框中心点和画布中心点的偏移量，并且平移
        this.canvas.relativePan(new fabric.Point(this.canvas.width / 2 - this.bound.left, this.canvas.height / 2 - this.bound.top))
      } else {
        this.resetZoom()
        this.zoom150x()
      }

      this.canvas.renderAll()
    },
    resetZoom() {
      this.SET_ZOOM_TOGGLE(false)
      this.canvas.setZoom(1)
      this.canvas.absolutePan(new fabric.Point(0, 0))
      this.canvas.renderAll()
    },
    zoomIn() {
      this.resetZoom()
      this.SET_ZOOM_TOGGLE(true)

      const widthZoom = this.canvas.width / (this.bound.width + 10)
      const canvasRatio = this.canvas.height / this.canvas.width
      const boundRatio = this.bound.height / this.bound.width

      let zoomValue

      if (boundRatio > canvasRatio) {
        if (canvasRatio < 1) {
          zoomValue = widthZoom
        } else {
          zoomValue = 2
        }
      } else {
        zoomValue = widthZoom
      }

      if (this.perspective === "left" || this.perspective === "right") {
        zoomValue = 2
      }

      this.canvas.zoomToPoint(new fabric.Point(this.bound.left, this.bound.top), zoomValue.toFixed(2))
      this.canvas.relativePan(new fabric.Point(this.canvas.width / 2 - this.bound.left, this.canvas.height / 2 - this.bound.top))

      this.canvas.renderAll()
    },
    //【ID1000307】创作中心正常状态载体放大
    zoom150x() {
      this.SET_ZOOM_TOGGLE(false)
      this.resetZoom()

      const widthZoom = this.canvas.width / (this.bound.width + 10)
      let zoomValue

      if (widthZoom < 1.5) {
        zoomValue = widthZoom
      } else {
        zoomValue = 1.5
      }

      this.canvas.zoomToPoint(new fabric.Point(this.bound.left, this.bound.top), zoomValue)
      this.canvas.relativePan(new fabric.Point(this.canvas.width / 2 - this.bound.left, this.canvas.height / 2 - this.bound.top))
      this.canvas.renderAll()
    },

    //【ID1000226】4.8 创作中心-用户-画笔
    setBrushSize(size) {
      //设置笔刷大小
      this.canvas.freeDrawingBrush.width = parseInt(size)
    },
    setBrushColor(color) {
      //设置笔刷颜色
      console.log({ color })
      this.canvas.freeDrawingBrush.color = color
    },

    async enterDrawingMode() {
      // 进入画笔模式前，画布检查。
      let checkResult = await this.beforeAddHandle({ options: { type: 5 } }, "画笔") // 将画笔当做是共用贴纸进行处理
      if (!checkResult) return // this.$logs("添加SVG前, 检查共用等的结果: ", checkResult);

      // 先存储本地历史，
      // this.canvas.offHistory();
      this.historyData = {
        historyNextState: this.canvas.historyNextState,
        historyUndo: this.canvas.historyUndo,
        historyRedo: this.canvas.historyRedo,
      }

      // this.canvas.historyNextState = "";
      // this.canvas.historyUndo = [];
      // this.canvas.historyRedo = [];

      // this.canvas.clearHistory();
      // this.canvas.goOnHistory();

      //进入画笔模式
      this.canvas.discardActiveObject() //取消选择 活动对象
      this.canvas.isDrawingMode = true // true进入画画模式, false退出画笔模式
      this.batchId = new Date().getTime() // 记录起始作画时间戳，并成为ID，目的让这一批画出来的产物进行编组

      this.$logs("this.batchId", this.batchId)

      this.SET_IS_DRAWING_MODE(true)
      this.$bus.$emit("showMenuPopup", {
        colorKey: this.activeColorKey,
        tab: "color",
      })

      this.setBrushColor(this.activeColor)
      this.canvas.on("path:created", e => {
        console.log("画笔功能数据", this.canvas.toDatalessJSON().objects)

        // 画完一笔的事件
        console.log("path:created")
        const { path } = e
        path.clipPath = this.clipPath // 要做剪切,在设计区域内
        path.colorKey = this.activeColorKey // primaryColor
        path.currentColor = this.activeColor // 改当前颜色
        path.defaultId = "drawingPath"
        path.batchId = this.batchId

        this.afterAddHandle()
      })
      this.canvas.renderAll()
    },

    // 23-08-07记录, 画笔模式下，撤回和重做的bugfix。 需要反复测试。
    exitDrawingMode() {
      // 退出画笔模式
      this.SET_IS_DRAWING_MODE(false)
      this.canvas.offHistory()

      this.canvas.historyNextState = ""
      this.canvas.historyUndo = []
      this.canvas.historyRedo = []

      this.canvas.clearHistory()
      // this.canvas.goOnHistory();

      // 将某批次画出来的产物编组
      const paths = this.canvas.getObjects().filter(o => o.batchId === this.batchId)

      paths.forEach(path => {
        path.clipPath = null
      })

      const selectedObject = new fabric.ActiveSelection(paths, {
        canvas: this.canvas,
      }) //指定画布上选中

      this.canvas.renderAll()

      if (paths.length > 0) {
        // this.canvas.offHistory();

        const group = new fabric.Group(paths, {
          defaultId: "drawingPath",
          clipPath: this.clipPath,
          left: selectedObject.left,
          top: selectedObject.top,
        })

        paths.forEach(path => {
          this.canvas.remove(path)
        })

        this.canvas.renderAll()
        this.canvas.goOnHistory() // 当path全部移除后，继续开启记录历史步骤

        // 将存储的临时历史数据，还原回去。
        let { historyNextState, historyUndo, historyRedo } = this.historyData
        this.canvas.historyNextState = historyNextState
        this.canvas.historyUndo = [...historyUndo].filter(i => i.length)
        this.canvas.historyRedo = [...historyRedo].filter(i => i.length)

        // 设置编组的控制按钮
        group.typeId = 5 // 将画笔
        let controlsVisibility = SVG_CONFIG.config.find(i => i.typeId == group.typeId).controls
        group.setControlsVisibility(controlsVisibility)
        this.$store.commit("SET_ADDED_NOT_USE_STICKER", false)

        this.canvas.add(group)
        this.canvas.renderAll()

        // 历史操作去重
        this.canvas.historyUndo = [...new Set(this.canvas.historyUndo)]
        this.canvas.historyRedo = [...new Set(this.canvas.historyRedo)]

        // this.$logs("操作完成, 撤回", this.canvas.historyUndo)
        // this.$logs("操作完成，重做", this.canvas.historyRedo)

        this.afterAddHandle()
      } else {
        this.canvas.goOnHistory()
      }

      // this.canvas.discardActiveObject();
      this.canvas.isDrawingMode = false
      this.canvas.renderAll()
    },

    /**
     * @name: 操作前，检查画布上的元素和要添加的元素
     * @param {*} options
     * @param {*} checkType 检查类型，画笔需要特别处理
     * @return {*}
     */
    async beforeAddHandle({ options }, checkType = "该系列") {
      // this.$logs("执行画布检查");

      /*
          添加元素前，判断之前的元素的ID，处理以下：
          1、共用之间叠加，直接下一步；
          2、不共用之间叠加，先清空画布，再下一步；
          3、共用叠加不共用，弹窗提示【该系列为星标贴纸，选择后画布将被清空，是否确认选择】，等待用户操作 ；
          4、不共用叠加共用，弹窗提示【该系列无法与星标贴纸共同使用，选择后画布将被清空，请确认】，等待用户操作。
        */

      // 1、判断画布是否是空画布
      let objectIsEmpty = this.$store.state.objectIsEmpty
      let currentPerspective = this.$store.state.currentPerspective
      if (objectIsEmpty[currentPerspective]) return Promise.resolve(true) // 如果画布, 载体当前面是空，直接返回操作。

      // 1、判断画布上有没有元素
      // let isNullElements = this.canvas.getObjects().length == this.canvas.getObjects().filter(o => o.typeId === undefined).length;
      // // this.$logs("执行画布检查，是否空画布", isNullElements);
      // if (isNullElements) return Promise.resolve(true);// 画布上没有元素，直接添加

      // 2、当画布有元素，进行判断共用。
      this.$logs("要叠加的元素", { options, typeId: options.type })

      let notUseTypeId = SVG_CONFIG.notUseId // 不共用的贴纸的typeId
      let notUseStickers = this.canvas.getObjects().filter(o => o.typeId == notUseTypeId).length // 画布上，不共用的个数
      let isNotUseSticker = options.type == notUseTypeId || options.typeId == notUseTypeId || false // 将要叠加的元素是否为不共用

      // 以下是叠加的4种情况，和每种情况的处理
      // 3、处理共用叠加共用，不共用叠加不共用，不需要弹窗交互
      let add_use_to_use = notUseStickers == 0 && !isNotUseSticker // 共用叠加共用。
      let add_not_use_to_not_use = notUseStickers > 0 && isNotUseSticker // 不共用叠加不共用。

      // 共用叠加共用，不共用叠加不共用，不需要弹窗交互
      if (add_use_to_use) return Promise.resolve(true) // this.$logs("共用叠加共用, 不处理"),
      if (add_not_use_to_not_use) {
        this.removeSvg(this.canvas.getObjects().filter(o => o.typeId == notUseTypeId))
        // this.$logs("不共用叠加不共用, 清空不共用再叠加")
        return Promise.resolve(true)
      }

      // 4、处理共用不共用之间叠加，需要弹窗交互
      let add_not_use_to_use = notUseStickers == 0 && isNotUseSticker // 共用叠加不共用。
      let add_use_to_not_use = notUseStickers > 0 && !isNotUseSticker // 不共用叠加共用。

      let needRemoveObjects = [] // 需要移除的元素
      let message = "该系列无法与星标贴纸共同使用，选择后画布将被清空，请确认" // 默认提示信息
      let confirmButtonColor = "#23d59c"

      if (add_not_use_to_use) {
        //不共用叠加到共用, 移除所有元素
        needRemoveObjects = this.canvas.getObjects().filter(o => SVG_CONFIG.useIds.includes(o.typeId))
        message = "该系列为星标贴纸，选择后画布将被清空，是否确认选择"
      } else if (add_use_to_not_use) {
        // 共用叠加到不共用，移除不共用的元素及字体元素
        // this.$log("共用叠加到不共用");
        needRemoveObjects = this.canvas.getObjects().filter(o => o.typeId == notUseTypeId || o.typeId == 2)
        // message = "该系列无法与星标贴纸共同使用，选择后画布将被清空，请确认";
        message = `${checkType}无法与星标贴纸共同使用，选择后画布将被清空，请确认`
      }

      const dialogRes = await Dialog.confirm({ message, confirmButtonColor }).catch(err => {
        return Promise.resolve(err)
      })
      if (dialogRes == "cancel") return Promise.resolve(false) // 用户点击取消，直接返回操作。

      this.removeSvg(needRemoveObjects) // 移除元素
      return Promise.resolve(true)
    },

    /**
     * @name: 添加动作完成后的系列操作
     * @return {*}
     */
    afterAddHandle() {
      // this.$logs("添加动作完成后");
      // this.$logs("重做", this.canvas.historyRedo, "撤回", this.canvas.historyUndo);
      // this.$logs("重做长度", this.canvas.historyRedo.length, "撤回长度", this.canvas.historyUndo.length);

      this.canvas.historyRedo = this.canvas.historyRedo.filter(i => i.length)
      this.canvas.historyUndo = this.canvas.historyUndo.filter(i => i.length)

      // this.$logs("操作完的重做长度", this.canvas.historyRedo.length, "操作完的撤回长度", this.canvas.historyUndo.length);

      this.SET_HISTORY_STEPS(this.canvas) // 将历史操作步数，同步至VUEX
    },

    /**
     * @name: 清除画布上的某些元素
     * @param {*} removeObjects 需要清除的元素
     * @return {*}
     */
    async removeSvg(removeObjects) {
      this.canvas.remove(
        removeObjects.forEach(o => {
          this.canvas.remove(o)
        }),
      )
      this.canvas.renderAll()
    },

    /**
     * @description: 排版当前选中组合
     * @param {*} gridColumns 列数
     * @param {*} gridRows 行数
     * @return {*}
     */
    arrangeGroup({ mode = "random", gridColumns = 0, gridRows = 0 }) {
      Toast.loading({
        message: "",
        forbidClick: true,
        duration: 0,
      })

      const gap = 0 // 间隔

      // 进入排版功能，需要将框选的回调，禁用
      this.allowArrangeFlag = false // 不需要隐藏排版菜单

      const activeObject = this.canvas.getActiveObject()
      if (!activeObject) return

      const batchId = new Date().getTime() // 设定当前group的batchId
      const { _objects } = activeObject

      // 修复从其他排版方式，切换到随机排版时，元素的缩放和角度没有还原的问题
      activeObject.set({ scaleX: 1, scaleY: 1, angle: 0 })

      // 每一个都scale一下，防止缩放的时候，元素的宽高不一致
      _objects.forEach(obj => {
        obj.scaleX = 1
        obj.scaleY = 1
      })

      let rows = gridRows // 行数
      let cols = gridColumns // 列数

      if (mode == "random") {
        // 随机排版

        // 生成随机角度
        function getRandomAngle() {
          const angleRange = 8 // 角度范围为左右8度
          const randomAngle = Math.random() * angleRange * 2 - angleRange // 生成介于 正负angleRange之间的随机角度
          return randomAngle
        }

        function generateCoordinates(objects, canvasWidth, canvasHeight) {
          // 列数根据canvas的宽度比来计算 gridColumns
          const canvasRatio = canvasWidth / canvasHeight
          let gridColumns = 2
          if (canvasRatio >= 1.3) {
            // 大于1.3的画布，列数为5
            gridColumns = 5
          } else if (1.3 > canvasRatio && canvasRatio >= 1) {
            // 1.3 > canvasRatio > 1，列数为4
            gridColumns = 4
          } else if (1 >= canvasRatio && canvasRatio >= 0.7) {
            // 1 >= canvasRatio > 0.7，列数为3
            gridColumns = 3
          } else if (0.7 > canvasRatio) {
            // 0.7 > canvasRatio，列数为2
            gridColumns = 2
          }

          // 计算网格的列数和行数
          let gridRows = Math.ceil(objects.length / gridColumns)

          // // 当元素的个数小于5个的时候，即个数为2、3、4个的时候，行数、列数为[1,2]中取随机值
          if (objects.length < 5) {
            // 2个的时候，[1，2]、[2、1]取随机值
            if (objects.length == 2)
              [gridColumns, gridRows] = [
                [1, 2],
                [2, 1],
                [2, 2],
              ][Math.floor(Math.random() * 3)]
            // 3个的时候，[1，3]、[3，1], [2, 2]取随机值
            if (objects.length == 3)
              [gridColumns, gridRows] = [
                [1, 3],
                [3, 1],
                [2, 2],
              ][Math.floor(Math.random() * 3)]
            // 4个的时候，[2，2]、[1，4]、[4，1]、[2,3]、[3、2]取随机值
            if (objects.length == 4)
              [gridColumns, gridRows] = [
                [2, 2],
                [1, 4],
                [4, 1],
                [2, 3],
                [3, 2],
              ][Math.floor(Math.random() * 5)]
          }

          // 根据画布的比例来计算网格的宽度和高度
          // let gridWidth = canvasWidth / Math.max(gridColumns, 3);
          let gridWidth = canvasWidth / gridColumns
          let gridHeight = canvasHeight / Math.max(gridRows, 3)

          let coordinates = []

          for (let i = 0; i < gridColumns; i++) {
            for (let j = 0; j < gridRows; j++) {
              let x = i * gridWidth + gridWidth / 2 // 在网格的中心生成坐标
              let y = j * gridHeight + gridHeight / 2
              coordinates.push({ x, y, gridWidth, gridHeight })
            }
          }
          return coordinates
        }

        try {
          const coordinates = generateCoordinates(_objects, this.bound.width, this.bound.height)

          // 坐标点再乱序一下
          coordinates.sort(() => Math.random() - 0.5)

          _objects.forEach((object, index) => {
            const { x, y, gridWidth, gridHeight } = coordinates[index]

            // 缩放也需要在scale的基础上，在进行 正负0.1至0.25的缩放
            const scale = Math.min(gridWidth / object.width, gridHeight / object.height) * (Math.random() * 0.15 + 0.85)

            // x,y 随机偏移量
            const randomOffsetX = Math.random() * 10 - 5 // 生成介于 正负5之间的随机数
            const randomOffsetY = Math.random() * 10 - 5 // 生成介于 正负5之间的随机数

            object.set({
              left: x + randomOffsetX,
              top: y + randomOffsetY,
              scaleX: scale,
              scaleY: scale,
              // 中心对齐
              originX: "center",
              originY: "center",
              angle: getRandomAngle(),
              batchId,
            })
          })
        } catch (error) {
          console.log(error)
        }
      } else if (mode == "grid") {
        // 宫格排版

        const numPoints = _objects.length // 元素的个数
        if (numPoints > 100) return Toast("需要排版的元素太多")

        // 根据传入的 gridRows、gridColumns 来计算行列
        if (rows == 0 && cols == 0) {
          // 宫格布局
          const num = new Array(10).fill(0).map((_, index) => index + 1) // 生成 1 - numPoints 的数组
          const perfectSquares = Array.from(num, i => i * i) // 完全平方数的列表

          // 查找小于等于元素个数的最大完全平方数
          const perfectSquare = perfectSquares.find(square => square >= numPoints)

          if (perfectSquare) {
            rows = Math.sqrt(perfectSquare)
            cols = Math.sqrt(perfectSquare)
          } else {
            rows = Math.ceil(Math.sqrt(numPoints))
            cols = Math.ceil(numPoints / rows)
          }
        } else if (rows == 0 && cols > 0) {
          // N列布局, 根绝列，计算行
          rows = Math.ceil(numPoints / cols)
        } else if (rows > 0 && cols == 0) {
          // N行布局, 根绝行，计算列
          cols = Math.ceil(numPoints / rows)
        } else if (rows > 0 && cols > 0) {
          // todo：行列布局
        }

        // 找出所有元素中最宽和最高的，在以此为单元格的宽高
        const maxWidth = Math.max(..._objects.map(o => o.width))
        const maxHeight = Math.max(..._objects.map(o => o.height))

        // 使用框选区域的宽高来计算cell的宽高
        let cellWidth = maxWidth
        let cellHeight = maxHeight

        _objects.forEach((object, index) => {
          let row = Math.floor(index / cols)
          let col = index % cols

          // 计算缩放因子
          let scale = Math.min(cellWidth / object.width, cellHeight / object.height)

          // 需要将每一个元素的高度或宽度设为一致，且为cell的宽或高
          object.scale(scale, scale)

          object.set({
            // 没有间隔
            left: col * cellWidth + gap,
            top: row * cellHeight + gap,
            // 中心对齐
            originX: "center",
            originY: "center",
            angle: 0,
            batchId,
          })
        })
      } else if (mode == "left-step") {
        // 左阶梯平铺

        // 将当前选中的组合，进行排版组合， 排版方式为左上至右下平铺
        let totalWidth = 0
        let totalHeight = 0
        _objects.forEach((object, index) => {
          if (index !== 0) {
            const { width, height, scaleX, scaleY } = _objects[index - 1]
            totalWidth += width * scaleX + gap
            totalHeight += height * scaleY + gap
          }
          object.set({
            left: totalWidth,
            top: totalHeight,
            angle: 0,
            originX: "left",
            originY: "top",
            batchId,
          })
        })
      } else if (mode == "right-step") {
        // 右阶梯平铺

        // 将当前选中的组合，进行排版组合， 排版方式为从右上至左下平铺
        let totalWidth = 0
        let totalHeight = 0
        _objects.forEach((object, index) => {
          if (index !== 0) {
            const { width, height, scaleX, scaleY } = _objects[index - 1]
            totalWidth += width * scaleX + gap
            totalHeight += height * scaleY + gap
          }
          object.set({
            left: this.bound.width - totalWidth,
            top: totalHeight,
            angle: 0,
            originX: "right",
            originY: "top",
            batchId,
          })
        })
      }

      // else if (arrangeKey.includes("horizontal")) {// 行平铺

      //   // 将当前选中的组合，进行排版组合， 排版方式为横向平铺
      //   let totalWidth = 0;
      //   const currentArrangeModeGap = 20; // 当前排版模式的间隔

      //   // 获得框选区域的宽高
      //   // const { width, height } = activeObject;
      //   // const averageWidth = width / _objects.length; // 计算平均宽度

      //   _objects.forEach((object, index) => {
      //     // 需要确保元素的宽度和高度，看起来是一致的
      //     // const { width, height } = object;

      //     // if (index !== 0) {
      //     //   const { width, scaleX } = _objects[index - 1]
      //     //   totalWidth += width * scaleX + gap
      //     // }

      //     object.set({
      //       left: totalWidth,
      //       top: _objects[0].top,
      //       originX: "left",
      //       originY: "center",
      //       angle: 0,
      //       batchId,
      //     })

      //     const { width: currentWidth, scaleX: currentScaleX } = object;
      //     totalWidth += currentWidth * currentScaleX + currentArrangeModeGap;

      //   })
      // } else if (arrangeKey.includes("vertical")) {// 列向平铺

      //   // 将当前选中的组合，进行排版组合， 排版方式为竖向平铺
      //   let totalHeight = 0;
      //   const currentArrangeModeGap = 20; // 当前排版模式的间隔

      //   _objects.forEach((object, index) => {
      //     // totalHeight
      //     object.set({
      //       left: _objects[0].left,
      //       top: totalHeight,
      //       originX: "center",
      //       originY: "top",
      //       angle: 0,
      //       batchId,
      //     });

      //     const { height: currentHeight, scaleY: currentScaleY } = object;
      //     totalHeight += currentHeight * currentScaleY + currentArrangeModeGap;

      //   });

      // }

      this.canvas.discardActiveObject() // 取消框选
      // this.canvas.renderAll();//

      // 在重新框选当前的组合
      const currentBatchIdGroup = this.canvas.getObjects().filter(o => o.batchId == batchId)
      const activeSelection = new fabric.ActiveSelection(currentBatchIdGroup, {
        canvas: this.canvas,
      })

      // 让当前的 group ,进行居中对齐,
      activeSelection.set({
        left: this.boundCenterX || this.centerX,
        top: this.boundCenterY || this.centerY,
        originX: "center",
        originY: "center",
      })

      // 设定缩放，将整体缩放至画布裁剪区域内. 随机排版不设定缩放
      if (mode !== "random") {
        const { width, height } = activeSelection
        const { width: clipPathWidth, height: clipPathHeight } = this.bound
        // 判断宽高比例，以宽高比例大的为基准，进行缩放
        if (width / height > clipPathWidth / clipPathHeight) {
          activeSelection.scale(clipPathWidth / width)
        } else {
          activeSelection.scale(clipPathHeight / height)
        }
      }

      this.canvas.setActiveObject(activeSelection) // 框选排版后的区域
      // this.canvas.renderAll();// 更新画布

      this.canvas.requestRenderAll()

      // 手动记录历史记录
      this.canvas.fire("object:modified")

      this.afterAddHandle() // 将当前这一次的【排版】的步骤加入历史记录

      this.allowArrangeFlag = true // 不需要隐藏排版菜单
      Toast.clear()
    },

    /**
     * @description: 添加一组元素
     * @param {*} groups
     * @return {*}
     */
    async addGroup(groups, mode = null) {

      // this.canvas.offHistory();// 关闭历史记录

      const batchId = new Date().getTime() // 设定当前group的batchId

      for (const group in groups) {
        // 添加每一个元素
        await this.addSvg(groups[group], batchId, false)

        // 该group添加完后，全选当前组
        if (group == groups.length - 1) {
          let objects = this.canvas.getObjects().filter(o => o.batchId == batchId) // 获取 canvas 上的所有对象
          let activeSelection = new fabric.ActiveSelection(objects, {
            canvas: this.canvas,
          })
          this.canvas.setActiveObject(activeSelection)
          this.canvas.renderAll() // 更新画布

          // 关闭【我的素材】菜单
          if (this.$route.path !== "/sticker") this.$router.replace("/sticker")

          // 将添加的所有元素
          this.afterAddHandle()

          // 展示框选多个元素的提示信息
          setTimeout(() => {
            // 这里设定一个延时执行，因为这里会走 this.canvas.on("selection:cleared"）事件，会将tipsType重置
            this.SET_TIPS_TYPE(groups.length > arrangeCountLimit ? "show-multiple-limit-tips" : "show-multiple-select-tips"); // 展示可更改颜色提示信息
          }, 100)

          // 当添加的元素超过100个，不进行排版操作
          if (groups.length > arrangeCountLimit) return;

          // 如果没有传入排版模式，根据当前的排版模式，进行排版
          if (!mode) mode = groups.length <= 20 ? "random" : "grid";

          // 加入的group仅包含单个元素，不进行排版。目前不存在这种情况，上传单图的改为走addUploadedImage的逻辑
          if (groups.length == 1) return;

          this.arrangeGroup({ mode: mode });

          // 设置 排版 菜单
          setTimeout(() => {
            this.SET_ARRANGE_COUNT(groups.length) // 设置当前的排版组合的个数
          }, 100)
        }
      }
    },

    /**
     * @name: 添加svg, 类型为 共用贴纸、不共用贴纸、素材、形状、字体、头像、照片上传
     * @param {*} url
     * @param {*} options
     * @param {*} batchIdParam 批次ID
     * @param {*} showTips 是否需要展示提示
     * @return {*}
     */
    async addSvg({ url, options }, batchIdParam = "", showTips = true) {
      this.$logs("addSVG", { url, options })

      /*
        typeId:
          1: 头像
          2: 字体
          4: 形状
          5: 贴纸
          6: 素材
          10: 照片上传
          15: 星标贴纸， 不共用贴纸
      */

      let checkResult = await this.beforeAddHandle({ options })
      if (!checkResult) return // this.$logs("添加SVG前, 检查共用等的结果: ", checkResult)

      // 以下3行代码必须，检查完后，添加元素到画布，把是否添加的不共用元素同步到vuex
      let isNotUseSticker = options?.type == SVG_CONFIG.notUseId || options.typeId == SVG_CONFIG.notUseId || false // 将要叠加的元素是否为不共用
      this.$store.commit("SET_ADDED_NOT_USE_STICKER", isNotUseSticker)
      // this.$logs("vuex中是否添加了不共用元素到画布", this.$store.state.hasAddedNotUseSticker)

      const typeId = options?.type || 5
      const ipId = options?.ipId || ""
      const elementId = options?.elementId || ""
      const fromAvatar = options?.fromAvatar || false
      const styleType = options?.styleType || 0
      const isImage = /.(jpg|png|jpeg)/.test(url)
      const batchId = batchIdParam || ""

      // console.log(isImage);
      // this.$logs(`添加的 ${ isImage ? '是' : '不是' } 图片`);
      // this.$logs("addSvg", { url, options, isImage, typeId });

      Toast.loading({
        message: "",
        forbidClick: true,
        duration: 0,
      })

      const hasSticker = this.canvas.getObjects().filter(o => o.typeId == 1).length == 1 && this.canvas.getObjects().filter(o => !o.isCarrierClass && (o.typeId != 2 || o.typeId != 4)).length > 0
      const hasNonSticker = this.canvas.getObjects().filter(o => o.typeId == 1).length == 0 && this.canvas.getObjects().filter(o => !o.isCarrierClass && (o.typeId != 2 || o.typeId != 4)).length > 0
      const isArtist = this.$route.matched[1].path.indexOf("artist") > -1

      if (isArtist) {
        let canvasIsEmpty = true
        for (let key in this.objectIsEmpty) {
          if (this.objectIsEmpty[key] == false) {
            canvasIsEmpty = false
            break
          }
        }
        // let canvasIsEmpty = true;
        // Object.keys(this.objectIsEmpty).forEach(key => {
        //   if (this.objectIsEmpty[key]) {
        //     return;
        //   }
        //   if (this.objectIsEmpty[key] == false) {
        //     canvasIsEmpty = false;
        //   }
        // });
        if (canvasIsEmpty) this.SET_CURRENT_IP_ID("")

        if (!this.currentIpId) {
          this.SET_CURRENT_IP_ID(ipId)
          // this.SET_OBJECT_IS_EMPTY({ ...this.objectIsEmpty, [this.perspective]: false });
        }
        if (this.currentIpId != ipId) {
          Toast.clear()
          await Dialog.alert({
            message: "请选择同一个艺术家的贴纸",
            confirmButtonColor: "#23d59c",
          })
          return
        }
        this.canvas.remove(
          this.canvas
            .getObjects()
            .filter(o => o.isArtist)
            .forEach(o => {
              this.canvas.remove(o)
            }),
        )
      }

      if (typeId == 1 && this.carrierConfig.is_knitting == "1") {
        if (hasSticker || hasNonSticker) {
          Toast.clear()
          const result = await Dialog.confirm({
            message: "所选贴纸将重置调色盘",
            confirmButtonColor: "#23d59c",
          })
          if (result !== "confirm") {
            return
          }
        }

        this.canvas.offHistory()
        this.canvas.remove(
          this.canvas
            .getObjects()
            .filter(o => o.typeId == 1)
            .forEach(o => {
              this.canvas.remove(o)
            }),
        )
        this.canvas.goOnHistory()
      }

      const colorConfig = {
        ...this.getColorConfig,
      }

      if (typeId == 1 && this.carrierConfig.is_knitting == "1") {
        let primaryValue = options.value1 ? options.value1.toUpperCase() : "",
          secondaryValue = options.value2 ? options.value2.toUpperCase() : "",
          tertiaryValue = options.value3 ? options.value3.toUpperCase() : ""

        this.setColorConfig({
          primaryValue,
          secondaryValue,
          tertiaryValue,
        })
      }

      //如果传进来是图片
      //begin

      if (isImage) {
        await new Promise(resolve => {
          fabric.Image.fromURL(
            url, // 设置图片加入画布的宽度大小
            (iconGroup, loadError) => {
              if (loadError) return Toast("图片加载失败")

              iconGroup.set({
                left: this.boundCenterX || this.centerX,
                top: this.boundCenterY || this.centerY,
                // left: 0,
                // top: 0,
                originX: "center",
                originY: "center",
                // selectable: true,
                lockScalingFlip: true,
                colorKey: typeId == 1 ? "" : this.activeColorKey,
                typeId: typeId,
                isArtist: isArtist,
                ipId: ipId,
                elementId: elementId,
                styleType,
                defaultId: "image",
                batchId,
              })

              // this.carrierConfig.is_knitting == "1" && this.SET_ACTIVE_COLOR_KEY(typeId == 1 ? "primaryColor" : this.activeColorKey);

              iconGroup.clipPath = this.clipPath

              const basic = iconGroup.width > iconGroup.height ? iconGroup.width : iconGroup.height

              if (typeId == 1 || isArtist) {
                iconGroup.minScaleLimit = ((this.clipPath.width / 35) * 1) / basic
              } else {
                iconGroup.minScaleLimit = this.clipPath.width / 35 / basic
              }

              iconGroup.scale(60 / basic)

              this.carrierConfig.is_knitting == "1" && this.renderColorConfig()
              this.carrierConfig.is_knitting == "1" && (this.carrierImage.colorConfig = { ...this.getColorConfig })

              // iconGroup.setControlsVisibility({
              //   mt: false,
              //   mb: false,
              //   ml: false,
              //   mr: false,
              //   tr: false,
              //   editControl: false,
              //   duplicateControl: [15, "15"].includes(typeId) ? false : true,
              //   bl: false, // 左下角的控制点
              // });

              let controlsVisibility = SVG_CONFIG.config.find(i => i.typeId == typeId).controls
              // this.$logs("addSvg前 获得的控制点", controlsVisibility);
              iconGroup.setControlsVisibility(controlsVisibility)

              if (isArtist) {
                iconGroup.setControlsVisibility({
                  mt: false,
                  mb: false,
                  ml: false,
                  mr: false,
                  bl: false,
                  br: false,
                  tl: false,
                  tr: false,
                  mtr: false,
                  duplicateControl: false,
                  rotateControl: false,
                  editControl: false,
                })
                let scale //【ID1000340】【Bug转需求】IP创作中心图片展示比之前小 IP图3/4画布 贴子1/4画布
                if (iconGroup.width > iconGroup.height) {
                  scale = (this.bound.width / iconGroup.width) * 0.75
                } else {
                  scale = (this.bound.height / iconGroup.height) * 0.75
                }
                iconGroup.scale(scale)
              }

              // if (typeId == 6) {
              //   let scale;
              //   if (iconGroup.width > iconGroup.height) {
              //     scale = (this.bound.width / iconGroup.width) * 0.75;
              //   } else {
              //     scale = (this.bound.height / iconGroup.height) * 0.75;
              //   }
              //   iconGroup.scale(scale);
              //   // iconGroup.set({ scaleX: scale });
              // }

              // 为我的素材里的图片添加裁剪功能
              if (typeId == 6) {
                iconGroup.set({
                  cropFlag: true, // 裁剪功能标识
                  imgUrl: url, // 用于在画布上展示的图片url
                  originImgUrl: url, // CND OSS 原图url
                  segmentedImgUrl: "", // 上一次抠过的图片url
                  croppedImgUrl: "", // 上一次的图片url
                })

                showTips && this.SET_TIPS_TYPE("show-image-tips") // 设置图片提示
                // this.$logs("要添加的元素", iconGroup);
              }

              let scale = this.getSvgScale(typeId, iconGroup, batchId)
              if (scale > 0) iconGroup.scale(scale)

              iconGroup.on("scaling", () => {
                this.canvas.oMoving = true //元素正在移动
              })

              this.canvas.add(iconGroup)
              this.canvas.setActiveObject(iconGroup)

              this.canvas.renderAll()
              resolve(loadError)
            },
            { crossOrigin: "Anonymous" },
          )
        })
        Toast.clear()

        this.afterAddHandle()
        return
      }

      //end

      const loadSvg = fromAvatar ? "loadSVGFromString" : "loadSVGFromURL"

      fabric[loadSvg](url, (objects, d) => {
        const iconGroup = fabric.util.groupSVGElements(objects, d)
        iconGroup.set({
          left: this.boundCenterX || this.centerX,
          top: this.boundCenterY || this.centerY,
          originX: "center",
          originY: "center",
          // selectable: true,
          lockScalingFlip: true,
          colorKey: typeId == 1 ? "" : this.activeColorKey,
          typeId: typeId,
          isArtist: isArtist,
          ipId: ipId,
          elementId: elementId,
          styleType,
          defaultId: "svg",
          batchId,
        })

        if (this.svgOffset == 0) {
          this.svgOffset = -5
        } else {
          this.svgOffset = 0
        }
        iconGroup.set({ left: iconGroup.left + this.svgOffset, top: iconGroup.top + this.svgOffset })

        this.carrierConfig.is_knitting == "1" && this.SET_ACTIVE_COLOR_KEY(typeId == 1 ? "primaryColor" : this.activeColorKey)

        // if (!isArtist) {
        //   iconGroup.set({
        //     fill: colorConfig[this.activeColorKey]?.color || this.activeColor,
        //   });
        // }

        if (typeId == 4) {
          // 如果是形状，需fill
          iconGroup.set({
            fill: colorConfig[this.activeColorKey]?.color || this.activeColor,
          })

          showTips && this.SET_TIPS_TYPE("show-color-tips"); // 展示可更改颜色提示信息
        }

        // 处理如果是贴纸，但是贴纸是空白的情况
        if (typeId == 5 || typeId == 15) {
          iconGroup.set({
            fill: "#fff",
          })
        }

        if (fromAvatar) {
          iconGroup.set({
            isAvatar: fromAvatar,
          })
          if (styleType == 2) {
            iconGroup.getObjects().forEach(icon => {
              if (icon.id === "light") {
                icon.set({ fill: colorConfig.defaultColor.color })
              }
            })
          }
        }

        iconGroup.clipPath = this.clipPath

        const basic = iconGroup.width > iconGroup.height ? iconGroup.width : iconGroup.height

        // if (typeId == 1 || isArtist) {
        //   iconGroup.minScaleLimit = ((this.clipPath.width / 35) * 1) / basic;
        // } else {
        //   iconGroup.minScaleLimit = this.clipPath.width / 35 / basic;
        // }

        iconGroup.scale(iconGroup.minScaleLimit > 42 / basic ? iconGroup.minScaleLimit : 42 / basic)

        this.carrierConfig.is_knitting == "1" && this.renderColorConfig()
        this.carrierConfig.is_knitting == "1" && (this.carrierImage.colorConfig = { ...this.getColorConfig })

        // iconGroup.setControlsVisibility();
        // if (typeId == 1) {
        //   iconGroup.setControlsVisibility({
        //     mt: false,
        //     mb: false,
        //     ml: false,
        //     mr: false,
        //     tr: false,
        //     editControl: false,
        //   });
        // }

        console.log(isArtist)

        if (isArtist) {
          iconGroup.setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            bl: false,
            br: false,
            tl: false,
            tr: false,
            mtr: false,
            duplicateControl: false,
            rotateControl: false,
            editControl: false,
          })
          let scale
          if (iconGroup.width > iconGroup.height) {
            scale = (this.bound.width / iconGroup.width) * 0.75
          } else {
            scale = (this.bound.height / iconGroup.height) * 0.75
          }
          iconGroup.scale(scale)
        }

        // 统一设置控制点
        let controlsVisibility = SVG_CONFIG.config.find(i => i.typeId == typeId).controls
        // this.$logs("addSvg前 获得的控制点", controlsVisibility);
        iconGroup.setControlsVisibility(controlsVisibility)

        // this.$log("typeId", typeId, "类型", typeof typeId);
        // if ([5, 15, 6, 5].includes(typeId)) {// 共用贴纸、不共用贴纸、形状、素材
        //   let scale;
        //   if (iconGroup.width > iconGroup.height) {
        //     scale = (this.bound.width / iconGroup.width) * 0.75;
        //   } else {
        //     scale = (this.bound.height / iconGroup.height) * 0.75;
        //   }
        //   iconGroup.scale(scale);
        // }

        let scale = this.getSvgScale(typeId, iconGroup, batchId)
        if (scale > 0) iconGroup.scale(scale)

        iconGroup.on("moving", () => {
          this.canvas.oMoving = true
        })

        iconGroup.on("scaling", () => {
          this.canvas.oMoving = true
        })

        this.$logs("处理完成的SVG", iconGroup)

        this.canvas.add(iconGroup)
        this.canvas.setActiveObject(iconGroup)
        // this.objectIsEmpty[this.perspective] = this.canvas.getObjects().filter(o => o.isArtist).length == 0;
        // this.SET_OBJECT_IS_EMPTY(this.objectIsEmpty);

        this.canvas.renderAll()
        this.afterAddHandle()
        Toast.clear()
      })
    },

    /**
     * @description: // 2023-12-06, 完成图形排版，统一缩放比例为默认的小尺寸的
     * @param {*} typeId
     * @param {*} group
     * @return {*}
     */
    getSvgScale(typeId, group, batchId) {

      // 1、头像；2、字体；4、形状；5、贴纸（共用贴纸、画笔）；6、素材；10、照片上传；15、贴纸（不共用贴纸）；20、AIGD
      // 1、2、20；不进行缩放

      let scale = 0
      if (batchId) return (scale = (this.bound.height / group.height) * 0.2)

      const { width, height } = group;
      const { width: boundWidth, height: boundHeight } = this.bound;

      // 共用贴纸、不共用贴纸、形状、素材、上传图片
      if ([5, 15, 6, 4, 10, "5", "15", "6", "4", "10"].includes(typeId)) {

        // 新的计算方法，需要根据宽高scale后和bound的宽高进行比较，取最小的scale
        let widthBaseScale = (boundWidth / width) * 0.75;
        let heightBaseScale = (boundHeight / height) * 0.75;

        // 这里的实现，是为了解决，当宽高比例不一致时，取最小的scale
        scale = Math.min(widthBaseScale, heightBaseScale);

        // 旧的缩放系数计算方法。
        // if (width > height) {
        //   scale = (boundWidth / width) * 0.75
        // } else {
        //   scale = (boundHeight / height) * 0.75
        // };
      };

      // console.log(`\n 🚀🚀 %c  group  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, { width, height });
      // console.log(`\n 🚀🚀 %c  scale  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, scale);
      
      return scale;
    },

    //【ID1000267】4.13 创作中心-用户-颜色选择

    setColorConfig({ primaryValue, secondaryValue, tertiaryValue }) {
      const primaryColor = {
        ...this.getColorLibrary.filter(c => c.color === primaryValue)[0],
      }
      const secondaryColor = {
        ...this.getColorLibrary.filter(c => c.color === secondaryValue)[0],
      }
      const tertiaryColor = {
        ...this.getColorLibrary.filter(c => {
          return c.color === tertiaryValue
        })[0],
      }

      // 设置三种颜色

      const colorConfig = {
        primaryColor,
        secondaryColor,
        tertiaryColor,
      }

      // 给每个颜色属性fill赋值

      Object.keys(colorConfig).forEach(key => {
        if (this.getColorConfig.defaultColor.color === colorConfig[key].color) {
          return
        }

        if (!Object.keys(colorConfig[key]).length) {
          colorConfig[key] = this.getColorConfig[key]
          colorConfig[key].isLocked = false
          if (this.mcId && key === "tertiaryColor") {
            colorConfig[key].isLocked = this.getColorConfig.tertiaryColor.isLogo
          }
          return
        }
        colorConfig[key].isLocked = true
        if (this.mcId && key === "tertiaryColor") {
          colorConfig[key].isLocked = this.getColorConfig.tertiaryColor.isLogo
        }
      })

      this.SET_COLOR_CONFIG({
        ...this.getColorConfig,
        ...colorConfig,
      })
    },
    getStickerLength(cb) {
      return cb(this.canvas.getObjects().filter(o => o.typeId == 1).length)
    },
    resizeCanvas() {
      this.canvas.setDimensions({
        width: this.$refs.fabricContainer.offsetWidth,
        // height: this.$refs.fabricContainer.offsetHeight,
        height: window.innerHeight - 90, // 解决部分情况进入，画布高度不对的问题，默认设定画布高度为屏幕高度 - 90
      })
      this.centerX = this.canvas.width / 2
      this.centerY = this.canvas.height / 2
      this.canvas.renderAll()
    },

    // 添加载体的方法
    async addCarrierImage(url, { selectable, isAigdCarrier = false }) {
      // this.$logs("添加载体的方法", { url, selectable });

      await new Promise(resolve =>
        fabric.Image.fromURL(
          url,
          async (img, loadError) => {
            if (loadError) return Toast("图片加载失败")

            img.set({
              left: this.centerX,
              top: this.centerY,
              originX: "center",
              originY: "center",
              scaleX: (this.canvas.width / img.width).toFixed(2),
              scaleY: (this.canvas.width / img.width).toFixed(2),
              backgroundColor: "transparent",
              selectable,
              defaultId: isAigdCarrier ? "aigdCarrierImage" : "carrierImage",
              isCarrierClass: isAigdCarrier ? false : true,
              // isCarrierClass: true
            })
            img.colorConfig = { ...this.getColorConfig }
            this.carrierImage = img
            this.imgRatio = img.width / img.height
            this.canvas.add(img)
            this.canvas.moveTo(img, 0)
            resolve()
          },
          { crossOrigin: "Anonymous" },
        ),
      )
    },

    async addFont({ url, options: { fontFamily, elementId } }) {
      try {
        // 【ID1000263】4.16 创作中心-用户-字体
        const font = new FontFace(fontFamily, `url(${url})`) // 字体文件 url https://mp-oss.tootools.cn/xxx/xxx.ttf格式
        const status = await font.load().then(() => document.fonts.add(font)) //预加载字体
        let activeText = this.canvas.getActiveObject()

        if (activeText?.defaultId === "text") {
          activeText.fontFamily = fontFamily
          this.canvas.renderAll()
          this.afterAddHandle();
        } else {
          this.addTextBox({
            textPlaceholder: "文本输入",
            fontFamily,
            elementId,
            url,
          })
          this.SET_TIPS_TYPE("show-color-tips"); // 展示可更改颜色提示信息
        }
      } catch (error) {
        console.log(`\n 🚀🚀 %c  字体加载 error  `, `color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, error)
        Toast("字体加载失败")
      }
    },
    /*
     * @description: 添加文字
     * @param {url, textPlaceholder, fontFamily, elementId} url: 字体url textPlaceholder: 初始化文字-enter fontFamily: 字体 elementId: 元素id
     */

    addTextBox({ url, textPlaceholder, fontFamily, elementId }) {
      const colorConfig = { ...this.getColorConfig } // 不看这个
      const textBox = new fabric.Textbox(textPlaceholder, {
        //初始化文字 textPlaceholder
        left: this.boundCenterX || this.centerX, // 设计框中点
        top: this.boundCenterY || this.centerY,
        minScaleLimit: 0.3, //zoom最小值
        originX: "center",
        originY: "center",
        fill: colorConfig[this.activeColorKey].color,
        fontSize: 18, // 初始字体大小
        fontFamily,
        lineHeight: 1, //行高 100% 字体多大行高就多高
        url,
        // fontWeight: "bold",
        fontStyle: "normal",
        centeredScaling: true, // 缩放时是否保持中心点不变
        textAlign: "justify-center", // 文字对齐方式 文本框居中
        // charSpacing: 150,
        cursorWidth: 0, //光标宽度
        editable: false, //是否可编辑，只能通过弹窗的文本框修改

        //以下为自定义属性
        ipId: "0",
        elementId, //字体的ID 中台提供的
        colorKey: this.activeColorKey,
        typeId: "2", //字体的typeId 恒为2
        defaultId: "text",
      })

      const textContent = textBox.text
      const sliderMinVal = textBox.minScaleLimit
      const sliderVal = textBox.scaleX
      const colorKey = textBox.colorKey
      this.$bus.$emit("showMenuPopup", {
        //颜色菜单 MenuPopup.vue
        isText: true, // 告诉弹窗是文字，直接定位到文本输入框
        textContent, // 文字内容
        sliderMinVal, // 最小缩放值 滑块 已作废
        sliderVal, // 当前缩放值
        colorKey, // 当前颜色key
        fontFamily, // 当前选中的字体
        tab: "input", //默认选中文本输入框
      })

      textBox.on("editing:entered", () => {
        this.textContent = textBox.get("text") //同步输入框和文字框的内容
      })
      textBox.on("editing:exited", () => {
        let width = textBox.dynamicMinWidth //同步文字框宽度
        textBox.set({ width })
      })
      textBox.on("changed", () => {
        let width = textBox.dynamicMinWidth
        textBox.set({ width })
        this.canvas.renderAll()
      })

      this.carrierConfig.is_knitting == "1" && (this.carrierImage.colorConfig = { ...this.getColorConfig })

      textBox.clipPath = this.clipPath //和贴纸一样，要加在设计区域内

      // 设置字体的控制点
      let controlsVisibility = SVG_CONFIG.config.find(i => i.typeId == "2").controls
      textBox.setControlsVisibility(controlsVisibility)
      this.$store.commit("SET_ADDED_NOT_USE_STICKER", false)

      this.canvas.add(textBox)
      this.canvas.setActiveObject(textBox)

      this.canvas.renderAll()

      this.afterAddHandle()
    },

    inputText(textContent) {
      const textBox = this.canvas.getActiveObject() //获取到当前选中的文字框

      let width = textBox.dynamicMinWidth //获取当前文字框的宽度
      textBox.set({
        text: textContent.replace(/[\u0020|\u3000]/g, "\u00A0").replace(/([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g, ""),
      }) //屏蔽emoji
      textBox.set({ width })
      this.$bus.$emit(this.currentPerspective + ":setTextContent", textContent) //同步输入框和文字框的内容

      this.canvas.renderAll()
    },

    setFontSize(e) {
      //设置字体大小
      const textBox = this.canvas.getActiveObject()
      let width = textBox.dynamicMinWidth

      textBox.set({
        fontSize: e,
      })
      textBox.set({ width })
      this.canvas.renderAll()
    },

    setFontFamily(fontFamily) {
      // TODO:
      //设置字体
      const textBox = this.canvas.getActiveObject()
      textBox.set({
        fontFamily,
      })
      this.canvas.renderAll()
    },

    alignText(pos) {
      //已作废
      //设置字体对齐方式
      const textBox = this.canvas.getActiveObject()
      textBox.set({
        textAlign: pos,
      })
      this.canvas.renderAll()
    },

    scaleText(scale) {
      //已作废
      const textBox = this.canvas.getActiveObject()
      textBox.scale(scale)
      this.canvas.renderAll()
    },
    // 创建剪切蒙版以及白色矩形
    createClipPath({ x, y, width, height, stroke, rx, ry }) {
      rx = rx || 0
      ry = ry || 0
      const { carrierWidth } = this.carrierConfig[this.perspective]
      const rect = new fabric.Rect({
        left: this.centerX + (x / carrierWidth) * this.canvas.width,
        top: this.centerY + (y / carrierWidth) * this.canvas.width,
        width,
        height,
        rx,
        ry,
        absolutePositioned: true,
      })
      console.log("创建剪切蒙版以及白色矩形 rx", rx, ry)

      this.bound = new fabric.Rect({
        left: this.centerX + (x / carrierWidth) * this.canvas.width,
        top: this.centerY + (y / carrierWidth) * this.canvas.width,
        rx,
        ry,
        width,
        height,
        absolutePositioned: true,
        stroke, //描边色
        selectable: false,
        fill: "transparent", // 填充色
        opacity: 0.5,
        defaultId: "bound",
        isCarrierClass: true,
      })

      this.boundCenterX = this.bound.left
      this.boundCenterY = this.bound.top

      this.canvas.add(this.bound)
      this.canvas.moveTo(this.bound, 10)
      return rect
    },

    initEvents() {
      // 选中对象
      this.canvas.on("selection:created", e => {
        const group = e.selected
        const isSingle = group.length === 1
        if (isSingle) {
          // this.$logs("选中了object", group[0].typeId);
          if (group[0]["colorKey"]) {
            this.SET_ACTIVE_COLOR_KEY(group[0]["colorKey"])
          }
          return
        } else {
          // 输入group的所有typeId
          // const groupTypeIds = group.map(o => o.typeId);
          // console.log(`\n 🚀🚀 %c  groupTypeIds  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, groupTypeIds);

          // 检测是否都是有效的元素
          let validGroup = group.every(o => o.typeId != "")
          if (!validGroup) return

          if (!this.allowArrangeFlag) return
          
          // 展示框选多个元素的提示信息
          this.SET_TIPS_TYPE(group.length > arrangeCountLimit ? "show-multiple-limit-tips" : "show-multiple-select-tips"); // 展示可更改颜色提示信息

          // 如果选中的元素大于100个
          if (group.length > arrangeCountLimit) return;

          // 选择一个组进行操作
          if (this.$route.path !== "/sticker") this.$router.replace("/sticker")

          setTimeout(() => {
            this.SET_ARRANGE_COUNT(group.length) // 设置当前的排版组合次数
          }, 100)
        }

        const hasSticker = !!group.filter(o => o.typeId == 1).length
        const hasText = !!group.filter(o => o.type == "textbox").length
        const hasDrawingPath = !!group.filter(o => o.defaultId == "drawingPath").length

        if (hasSticker || hasText || hasDrawingPath) {
          this.canvas.getActiveObject().setControlsVisibility({
            mt: false,
            mb: false,
            ml: false,
            mr: false,
            tr: false,
            editControl: false,
          })
        }
      })

      // 选中对象更新
      this.canvas.on("selection:updated", e => {
        const group = e.selected
        const isSingle = group.length === 1
        if (isSingle) {
          const object = group[0]
          // this.$logs("选中了object更新", group[0].typeId);
          if (object.type === "textbox") {
            this.$bus.$emit("showMenuPopup", {
              isText: true,
              textContent: object.text,
              sliderMinVal: object.minScaleLimit,
              sliderVal: object.scaleX,
              colorKey: object.colorKey,
              fontFamily: object.fontFamily, // 当前选中的字体
              tab: "input",
            })
          } else {
            this.$bus.$emit("hideMenuPopup")
          }
        }

        if (isSingle) {
          if (group[0]["colorKey"]) {
            this.SET_ACTIVE_COLOR_KEY(group[0]["colorKey"])
          }
          return
        }
      })

      // 取消选中对象
      this.canvas.on("selection:cleared", () => {
        // this.$logs("取消选中了object");
        if (this.$route.path !== "/sticker") this.$router.replace("/sticker")
        this.$bus.$emit("hideMenuPopup")
        this.interceptStatus != "hide" && this.onHideImageMenu() // 如果图片操作组件是展开的，那么现在就关闭它
        this.allowArrangeFlag && this.arrangeCount && this.SET_ARRANGE_COUNT(0) // 如果当前有排版组合，那么现在就关闭它

        if (["show-multiple-limit-tips", "show-multiple-select-tips"].includes(this.tipsType)) this.SET_TIPS_TYPE("hide");// 隐藏多选提示信息
        
      })

      // 鼠标按下事件
      this.canvas.on("mouse:down", e => {
        // this.$logs("鼠标按下");
        if (!e.transform) return
        this.canvas.oClicked = true
      })

      // 鼠标取消按下的事件， //  在此处操作， 选中文本、上传图片后，唤出对应的操作选项。
      this.canvas.on("mouse:up", e => {

        if (["show-image-tips", "show-color-tips"].includes(this.tipsType)) this.SET_TIPS_TYPE("hide");// 隐藏多选提示信息

        // console.log("已点击元素", e);

        // this.$logs("取消鼠标按下");
        if (!e.transform) return
        const object = e.target
        if (e.transform.corner !== "removeControl" && e.transform.corner !== "editControl" && object.type === "textbox") {
          if (!this.canvas.oMoving && this.canvas.oClicked) {
            this.$bus.$emit("showMenuPopup", {
              isText: true,
              textContent: object.text,
              sliderMinVal: object.minScaleLimit,
              sliderVal: object.scaleX,
              colorKey: object.colorKey,
              fontFamily: object.fontFamily, // 当前选中的字体
              tab: "input",
            })
          }
        }

        // 点击形状或字体
        if (!["removeControl"].includes(e.transform.corner) && (object.typeId === 4 || object.type === "textbox")) {
          // if (!this.canvas.oMoving && this.canvas.oClicked) {
            this.SET_TIPS_TYPE("show-color-tips"); // 展示可更改颜色提示信息
          // }
        }

        // 当【可以操作crop的元素】被【点击】和【移动】, 不是删除操作的时候，都展示【裁切图片操作】的菜单。 && !this.canvas.oMoving
        if (e.transform.corner !== "removeControl" && object.cropFlag && this.canvas.oClicked) {
          // 同样当前路由路径中有【贴纸弹窗】【素材弹窗】，那么先替换路由为【默认 /sticker】
          // ["/sticker/sticker1", "/sticker/matter", "/sticker/shape", "/sticker/template"].includes(this.$route.path)
          if (this.$route.path !== "/sticker") this.$router.replace("/sticker")

          this.SET_TIPS_TYPE("show-image-tips"); // 展示图片提示信息

          setTimeout(() => {
            let { imgUrl, originImgUrl, segmentedImgUrl = "", croppedImgUrl = "" } = object
            this.onShowImageMenu({ imgUrl: imgUrl || originImgUrl, originImgUrl, segmentedImgUrl, croppedImgUrl })
          }, 100)
        } else {
          // 点击了不是【可以操作crop的元素】，如果该类元素菜单打开，则关闭【裁切图片操作】的菜单
          this.interceptStatus != "hide" && this.onHideImageMenu()
        }

        this.canvas.oMoving = false
        this.canvas.oClicked = false
      })

      // 对象缩放事件
      this.canvas.on("object:scaling", e => {

        // this.$logs("对象缩放");
        if (e.target.type === "textbox") this.SET_SLIDER_VAL(e.target.scaleX)
        // this.canvas.oMoving = true
        // this.interceptStatus != "hide" && this.onHideImageMenu() // 放大的时候，把【裁切图片操作】菜单关掉
        if (this.canvas.historyProcessing) return
        this.canvas.clearRedo()

        // if (this.$route.matched[1].path.indexOf("artist") > -1) {
        //   const maxScaleWidth = this.clipPath.getScaledWidth();
        //   const maxScaleHeight = this.clipPath.getScaledHeight();

        //   const obj = e.target;
        //   const objWidth = obj.width;
        //   const objHeight = obj.height;

        //   const overRatio = objWidth / objHeight > maxScaleWidth / maxScaleHeight;
        //   if (overRatio) {
        //     if (obj.getScaledWidth() > maxScaleWidth) {
        //       obj.scaleX = maxScaleWidth / objWidth;
        //       obj.scaleY = maxScaleWidth / objWidth;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //     if (obj.getScaledHeight() > maxScaleHeight) {
        //       obj.scaleX = maxScaleHeight / objHeight;
        //       obj.scaleY = maxScaleHeight / objHeight;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //   } else {
        //     if (obj.getScaledHeight() > maxScaleHeight) {
        //       obj.scaleX = maxScaleHeight / objHeight;
        //       obj.scaleY = maxScaleHeight / objHeight;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //     if (obj.getScaledWidth() > maxScaleWidth) {
        //       obj.scaleX = maxScaleWidth / objWidth;
        //       obj.scaleY = maxScaleWidth / objWidth;
        //       this.detectBoundary(e);
        //       return;
        //     }
        //   }
        // }
      })

      // 对象移动事件
      this.canvas.on("object:moving", e => {
        // this.$logs("对象移动");
        if (e.target.type === "textbox") this.$bus.$emit("hideMenuPopup")
        // if (e.target.cropFlag) this.onHideImageMenu();
        this.canvas.oMoving = true
        // if (this.$route.matched[1].path.indexOf("artist") > -1) {
        //   this.detectBoundary(e);
        // }
        if (this.canvas.historyProcessing) return
        this.canvas.clearRedo()
      })

      // 对象移除事件
      this.canvas.on("object:removed", () => {
        // this.$logs("对象移除");
        const isEmpty = this.canvas.getObjects().filter(o => !o.isCarrierClass).length == 0
        const objectIsEmpty = {
          ...this.objectIsEmpty,
          [this.perspective]: isEmpty,
        }
        this.SET_OBJECT_IS_EMPTY(objectIsEmpty)
        if (this.canvas.historyProcessing) return
        this.canvas.clearRedo()
      })

      // 对象添加事件
      this.canvas.on("object:added", () => {
        // this.$logs("对象新增");
        const isEmpty = this.canvas.getObjects().filter(o => !o.isCarrierClass).length == 0
        const objectIsEmpty = {
          ...this.objectIsEmpty,
          [this.perspective]: isEmpty,
        }
        this.SET_OBJECT_IS_EMPTY(objectIsEmpty)
        if (this.canvas.historyProcessing) return
        this.canvas.clearRedo()
      })

      // 对象发生变化
      this.canvas.on("object:modified", () => {
        // this.$logs("对象发生变化");
        if (this.canvas.historyProcessing) return
        this.canvas.clearRedo()
      })

      // 对象旋转事件
      this.canvas.on("object:skewing", () => {
        // this.$logs("对象skewing");
        if (this.canvas.historyProcessing) return
        this.canvas.clearRedo()
      })

      // 历史记录变化事件
      this.canvas.on("history:append", () => {
        this.$logs("历史记录变化");
        this.SET_LAST_DESIGN_TIMESTAMP(new Date().getTime())
        // this.canvas.clearRedo();
      })
    },

    detectBoundary(e) {
      const obj = e.target
      const objBoundary = obj.getBoundingRect()
      const canvasBoundary = this.clipPath.getBoundingRect()
      if (obj.currentHeight > canvasBoundary.height || obj.currentWidth > canvasBoundary.width) {
        return
      }
      obj.setCoords()
      // top-left  corner
      if (objBoundary.top <= canvasBoundary.top || objBoundary.left <= canvasBoundary.left) {
        obj.top = Math.max(obj.top, canvasBoundary.top + objBoundary.height / 2 / this.canvas.getZoom())
        obj.left = Math.max(obj.left, canvasBoundary.left + objBoundary.width / 2 / this.canvas.getZoom())
      }
      // bot-right corner

      const objBoundaryBottom = objBoundary.top + objBoundary.height
      const objBoundaryRight = objBoundary.left + objBoundary.width
      const canvasBoundaryBottom = canvasBoundary.top + canvasBoundary.height
      const canvasBoundaryRight = canvasBoundary.left + canvasBoundary.width

      if (objBoundaryBottom >= canvasBoundaryBottom || objBoundaryRight >= canvasBoundaryRight) {
        obj.top = Math.min(obj.top, canvasBoundaryBottom - objBoundary.height / 2 / this.canvas.getZoom())
        obj.left = Math.min(obj.left, canvasBoundaryRight - objBoundary.width / 2 / this.canvas.getZoom())
      }
    },

    // 如果此面为空，则将默认的载体图赋值给预览图
    fillPreviewImageByDefault() {
      const outputPreviewImage = this.carrierConfig[this.perspective].carrierImage
      const outputPreviewImages = { ...this.outputPreviewImages, [this.perspective]: outputPreviewImage }
      // const designJSONs = { ...this.designJSONs, [this.perspective]: JSON.stringify(canvasJSON) };
      // const elementIdsObj = { ...this.elementIds, [this.perspective]: elementIds };
      // const ipIdsObj = { ...this.ipIds, [this.perspective]: ipId };
      // console.log('outputPreviewImages---->', outputPreviewImages)
      this.SET_OUTPUT_PREVIEW_IMAGES(outputPreviewImages)
    },
    //【ID1000281】生产输出高清图

    /*
     *  1. 设计图 2.带有衣服的预览图
     *  @param resolve 结束promise
     *
     */
    async exportCanvas(resolve) {
      if (!this.carrierConfig[this.perspective]) {
        //如果没有这一面，就return
        resolve()
        return
      }

      const { boundWidth, boundHeight, boundX, boundY, carrierWidth } = this.carrierConfig[this.perspective] // carrierConfig 是中台录入的数据, 拿取框宽度高度xy和载体宽度
      this.canvas.renderAll() //在导出之前渲染一遍

      this.canvas.offHistory() //关闭历史记录
      await this.resetHistoryCanvas() //重置历史记录
      // this.zoomIn(); // 必须放大来导出高清大图

      // 1. 导出设计图
      this.canvas.set({
        backgroundColor: "transparent", //背景图透明
      })

      this.clipPath.opacity = 0
      this.bound.opacity = 0 // 设计框透明度设置为 0
      this.spec2 && (this.spec2.opacity = 0) // 如果有胸标 将其透明度设置为0
      this.carrierImage.opacity = 0 //底衣透明度设置为0

      // const multi = ((this.perspective === "back") || (this.perspective === "front")) ? 0.5 : 0.2;
      // const multi = 1;

      const originClipWidth = (boundWidth / carrierWidth) * this.canvas.width * this.canvas.getZoom() // 中台提供的框宽cm/中台提供的载体宽度cm * canvas宽度px * canvas缩放值 来求得设备上的设计框宽px
      const originClipHeight = (boundHeight / carrierWidth) * this.canvas.width * this.canvas.getZoom()
      const originClipOffsetX = (boundX / carrierWidth) * this.canvas.width // 中台提供的中点横向 (偏移量) cm/中台提供的载体宽度cm * canvas宽度px * canvas缩放值 来求得设备上的设计框宽px
      const originClipOffsetY = (boundY / carrierWidth) * this.canvas.width

      const originClipLeft = this.canvas.width / 2 - originClipWidth / 2 // 根据上文算出
      const originClipTop = this.canvas.height / 2 - originClipHeight / 2

      let outputCanvasWidth, outputCanvasHeight, outputCanvasLeft, outputCanvasTop

      if (this.perspective === "back" || this.perspective === "front") {
        outputCanvasWidth = originClipWidth
        outputCanvasHeight = originClipHeight
        outputCanvasLeft = originClipLeft
        outputCanvasTop = originClipTop
      } else {
        outputCanvasWidth = originClipWidth
        outputCanvasHeight = originClipHeight
        outputCanvasLeft = originClipLeft
        outputCanvasTop = originClipTop
      }

      // const targetWidth = ((this.carrierConfig[this.perspective].boundWidth * 300) / 2.54) * multi; // 框宽度 * 300(ppi) / 2.54 (转成inch) * 0.5 (缩放系数来兼容手机导出)

      let outputCanvasImage = null

      let multi
      if (outputCanvasWidth * 8 > 3000) {
        multi = 3000 / outputCanvasWidth
      } else {
        multi = 8;
      }

      console.time("outputCanvasImage")

      if (!this.canvasIsEmpty()) {
        //【ID1000282】前后左右的Canvas按手机规格调整
        this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0) //放大8倍，因手机大小不同输出也有一些差异
        outputCanvasImage = this.canvas.toDataURL({
          width: outputCanvasWidth,
          height: outputCanvasHeight,
          left: outputCanvasLeft,
          top: outputCanvasTop,
          multiplier: multi,
          // multiplier: 1,
          // multiplier: targetWidth / outputCanvasWidth,
          format: "png",
          crossOrigin: "Anonymous",
        })

        // outputCanvasImage
        this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0.8)
      }
      console.timeEnd("outputCanvasImage")

      this.carrierImage.opacity = 1
      console.log(this.carrierImage.top, this.carrierImage.left)
      console.time("outputPreviewImage")
      const outputPreviewImage = this.canvas.toDataURL({
        width: this.carrierImage.getScaledWidth() * this.canvas.getZoom(),
        height: this.carrierImage.getScaledHeight() * this.canvas.getZoom(),
        left: this.carrierImage.left - (this.carrierImage.getScaledWidth() * this.canvas.getZoom()) / 2 - originClipOffsetX * this.canvas.getZoom(),
        top: this.carrierImage.top - (this.carrierImage.getScaledHeight() * this.canvas.getZoom()) / 2 - originClipOffsetY * this.canvas.getZoom(),
        // multiplier: 900 / this.carrierImage.width,
        // multiplier: 1,
        multiplier: 2,// 之前导出倍数默认为1倍，部分贴纸会模糊，现在改为2倍，2024-02-21
        format: "png",
        crossOrigin: "anonymous",
      })
      console.timeEnd("outputPreviewImage")
      this.clipPath.opacity = 1
      this.bound.opacity = 0.5
      this.spec2 && (this.spec2.opacity = 0.5)

      this.canvas.goOnHistory()

      const objs = this.canvas.getObjects()
      let ipId
      if (this.$route.matched[1].path.indexOf("artist") != -1) {
        ipId = objs.filter(o => o.typeId == 5)[0]?.ipId || ""
      } else {
        ipId = "0"
      }
      const elementIds = objs
        .map(item => item.elementId)
        .filter(i => i)
        .join()

      // this.SET_OUTPUT_CANVAS_IMAGE(outputCanvasImage);
      // this.SET_OUTPUT_PREVIEW_IMAGE(outputPreviewImage);

      const canvasJSON = this.canvas.toDatalessJSON()

      canvasJSON.objects.forEach((object, index) => {
        if (object.defaultId === "leftSleeveImage" || object.defaultId === "rightSleeveImage") {
          canvasJSON.objects.splice(index, 1)
        }
      })

      canvasJSON.colorConfig = JSON.parse(JSON.stringify(this.getColorConfig))

      if (outputCanvasImage !== null) {
        const outputCanvasImages = { ...this.outputCanvasImages, [this.perspective]: outputCanvasImage }
        this.SET_OUTPUT_CANVAS_IMAGES(outputCanvasImages)
      }

      const outputPreviewImages = { ...this.outputPreviewImages, [this.perspective]: outputPreviewImage }
      const designJSONs = { ...this.designJSONs, [this.perspective]: JSON.stringify(canvasJSON) }
      const elementIdsObj = { ...this.elementIds, [this.perspective]: elementIds }
      const ipIdsObj = { ...this.ipIds, [this.perspective]: ipId }

      this.SET_OUTPUT_PREVIEW_IMAGES(outputPreviewImages)
      this.SET_DESIGN_JSONS(designJSONs)
      this.SET_ELEMENT_IDS(elementIdsObj)
      this.SET_IP_IDS(ipIdsObj)

      this.canvas.renderAll()
      this.zoom150x()
      resolve && resolve()
    },

    //上传的照片放在画布上 2023年2月1日15:37:46
    /**
     * @name:
     * @param {*} url 展示到画布上的图片url
     * @param {*} originUrl 上传的原始图片的url
     * @return {*}
     */
    async addUploadedImage(url, originUrl = "") {
      //【ID1000225】4.7 创作中心-用户-照片上传
      Toast.loading({
        message: "读取中...",
        forbidClick: true,
        duration: 0,
      })
      await new Promise(resolve =>
        fabric.Image.fromURL(
          // 使用oss参数强转为png
          url + "?x-oss-process=image/resize,m_lfit,w_1536",
          // url + "?x-oss-process=image/format,png",
          async (img, loadError) => {
            if (loadError) return Toast("图片加载失败")

            const basic = img.width > img.height ? img.width : img.height
            img.set({
              left: this.bound.left, //【ID1000346】加入所有的设计元素，都按照载体的创作框居中
              top: this.bound.top, //【ID1000346】加入所有的设计元素，都按照载体的创作框居中
              originX: "center",
              originY: "center",
              scaleX: (100 / img.width).toFixed(2),
              scaleY: (100 / img.width).toFixed(2),
              backgroundColor: "transparent",
              defaultId: "customImage",
              typeId: 10,

              cropFlag: true, // 裁剪功能标识
              imgUrl: url, // 用于在画布上展示的图片url
              originImgUrl: url, // CND OSS 原图url
              segmentedImgUrl: "", // 上一次抠过的图片url
              croppedImgUrl: "", // 上一次的图片url
            })
            // img.minScaleLimit = this.clipPath.width / 35 / basic;
            img.clipPath = this.clipPath //剪贴蒙版

            // let scale //【ID1000340】【Bug转需求】IP创作中心图片展示比之前小 IP图3/4画布 贴子1/4画布
            // if (img.width > img.height) {
            //   scale = (this.bound.width / this.canvas.getZoom() / img.width) * 0.75
            // } else {
            //   scale = (this.bound.height / this.canvas.getZoom() / img.height) * 0.75
            // }
            // img.scale(scale)

            // 2024-02-20: 使用统一的方法：对上传的图片进行缩放
            let scale = this.getSvgScale(10, img)
            if (scale > 0) img.scale(scale)

            // img.setControlsVisibility({
            //   mt: false,
            //   mb: false,
            //   ml: false,
            //   mr: false,
            //   tr: false,
            //   editControl: false,
            // });
            let controlsVisibility = SVG_CONFIG.config.find(i => i.typeId == 10).controls
            img.setControlsVisibility(controlsVisibility)

            this.canvas.add(img)
            this.canvas.setActiveObject(img)

            this.afterAddHandle()

            // 上传完图片，添加到画布后，显示 用户上传图片 的操作菜单
            this.onShowImageMenu({
              imgUrl: url,
              originImgUrl: url,
              segmentedImgUrl: "",
              croppedImgUrl: "", // 上一次的图片url
            })

            this.SET_TIPS_TYPE("show-image-tips") // 设置图片提示

            // this.CHANGE_IS_INTERCEPT(true);
            // this.CHANGE_MENU_STATUS(true);
            resolve()
            Toast.clear()
          },
          { crossOrigin: "Anonymous" },
        ),
      )
      Toast.clear()
    },

    //保存素材2023年2月1日15:37:22

    async saveSnapshot() {
      if (this.canvasIsEmpty()) {
        //【ID1000282】前后左右的Canvas按手机规格调整
        Toast("请先创作")
        return
      }

      Toast.loading({
        message: "保存中...",
        forbidClick: true,
        duration: 0,
      })

      const { boundWidth, boundHeight, carrierWidth } = this.carrierConfig[this.perspective] //拿取框宽度高度xy和载体宽度

      this.canvas.renderAll()

      this.canvas.offHistory() //关闭历史记录
      await this.resetHistoryCanvas() //重置历史记录

      const isZoomIn = this.zoomToggle

      this.zoomIn() // 必须放大来导出高清大图

      this.bound.opacity = 0
      this.spec2 && (this.spec2.opacity = 0)
      this.carrierImage.opacity = 0

      const originClipWidth = (boundWidth / carrierWidth) * this.canvas.width * this.canvas.getZoom()
      const originClipHeight = (boundHeight / carrierWidth) * this.canvas.width * this.canvas.getZoom()

      const originClipLeft = this.canvas.width / 2 - originClipWidth / 2
      const originClipTop = this.canvas.height / 2 - originClipHeight / 2

      let outputCanvasWidth, outputCanvasHeight, outputCanvasLeft, outputCanvasTop

      if (this.perspective === "back" || this.perspective === "front") {
        outputCanvasWidth = originClipWidth
        outputCanvasHeight = originClipHeight
        outputCanvasLeft = originClipLeft
        outputCanvasTop = originClipTop
      } else {
        outputCanvasWidth = this.clipPath.width * this.canvas.getZoom()
        outputCanvasHeight = this.clipPath.height * this.canvas.getZoom()
        outputCanvasLeft = this.clipPath.left - outputCanvasWidth / 2
        outputCanvasTop = this.clipPath.top - outputCanvasHeight / 2
      }

      let multi
      if (outputCanvasWidth * 8 > 3000) {
        multi = 3000 / outputCanvasWidth
      } else {
        multi = 8
      }
      this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0) //放大8倍，因手机大小不同输出也有一些差异
      const outputCanvasImage = this.canvas.toDataURL({
        width: outputCanvasWidth,
        height: outputCanvasHeight,
        left: outputCanvasLeft,
        top: outputCanvasTop,
        multiplier: multi,
        // multiplier: 1,
        // multiplier: targetWidth / outputCanvasWidth,
        format: "png",
        crossOrigin: "Anonymous",
      })

      this.canvas.overlayImage && (this.canvas.overlayImage.opacity = 0.8)
      this.bound.opacity = 0.5
      this.spec2 && (this.spec2.opacity = 0.5)
      this.carrierImage.opacity = 1

      this.canvas.goOnHistory()
      if (isZoomIn) {
        this.zoomIn()
      } else {
        this.zoom150x()
      }

      const url = await this.uploadImage(outputCanvasImage)
      const result = await saveUserElements({ path: url, type: 0 })
      console.log(result)
      Toast.success("已保存至我的素材")
      this.onHideImageMenu()
      this.CHANGE_MENU_STATUS(true)
      this.$router.replace("/sticker/matter?type=matter")
      this.$bus.$emit("refreshMatterData")
    },

    /**
     * 素材上传2023年2月1日15:29:33\
     * @param String dataUrl
     */
    async uploadImage(dataUrl) {
      const fileBlob = dataURLtoBlob(dataUrl)
      const res = await uploadOSS(fileBlob) //oss的原始链接产生的流量是要收费的/价格昂贵, 这边用的cdn回源流量包产生的费用较小,因此需要拼接一下cdn的endpoint生成新的链接.
      console.log(res, res.options.endpoint + "/" + res.result.name)
      return res.options.endpoint + "/" + res.result.name
    },

    //将扣完的图片替换 2023年2月1日15:29:39
    replaceUploadedImage(url) {
      // 【ID1000264】4.13 创作中心-用户-自动抠图 替换为扣完的图片

      const image = this.canvas.getActiveObject() //拿到当前选中的图片
      const imageBackup = this.canvas.getActiveObject() // 备份图片对象

      Toast.loading({
        message: "读取中...",
        forbidClick: true,
        duration: 0,
      })

      let { croppedImgUrl, segmentedImgUrl } = this.interceptImg

      imageBackup.imgUrl = url // 操作完图片后，存储imgUrl，以供后续使用
      imageBackup.segmentedImgUrl = segmentedImgUrl // 操作完图片后，存储segmentedImgUrl，以供后续使用
      imageBackup.croppedImgUrl = croppedImgUrl // 操作完图片后，存储croppedImgUrl，以供后续使用

      this.onHideImageMenu() // 隐藏图片操作菜单

      imageBackup.setSrc(
        url,
        () => {
          // 关闭历史记录
          this.canvas.offHistory()
          // 将image从画布上删掉
          this.canvas.remove(image)
          // 再开启历史记录
          this.canvas.goOnHistory()
          // 将备份的图片添加到画布上
          this.canvas.add(imageBackup)
          //第二个参数为回调, 将canvas重新渲染
          this.canvas.renderAll()
          Toast.clear()
        },
        { crossOrigin: "Anonymous" }, // 设置跨域
      ) // 替换图片src
    },

    // 展示图片操作菜单
    onShowImageMenu(imgUrls) {
      this.CHANGE_INTERCEPT_IMG(imgUrls) // 设置用户上传的原图url
      this.CHANGE_INTERCEPT_STATUS("showMenu")
      // this.CHANGE_MENU_STATUS(true);
    },

    // 隐藏图片操作菜单
    onHideImageMenu() {
      this.CHANGE_INTERCEPT_IMG("")
      this.CHANGE_INTERCEPT_STATUS("hide")
      // this.CHANGE_MENU_STATUS(false);
    },
  },
  created() {},
  async mounted() {
    console.log("mounted")
    // Toast.loading({
    //   message: "Loading...",
    //   forbidClick: true,
    //   overlay: true,
    //   duration: 0,
    // });
    // this.$nextTick(async () => {
    //   setTimeout(async () => {
    await this.init()
    if (this.perspective == "front" && this.aigcInfo) {
      this.$nextTick(() => {
        this.addSvg(this.aigcInfo)
        this.SET_AIGC_INFO(null)
      })
    }
    // if (this.
    // if (this.perspective == "front" && this.stickerInfo) {
    //   this.addSvg(this.stickerInfo);
    //   this.SET_STICKER_INFO(null);
    // }
    // Toast.clear();
    //   }, 1000);
    // });

    // 处理多张aigc图片的情况
    if (this.perspective == "front" && this.aigcInfos) {

      Toast.loading({
        message: "一键抠图中...",
        forbidClick: true,
        overlay: true,
        duration: 0,
      })

      // const segmentResult = await processSegmentHDImages(this.aigcInfos)
      const segmentResult = await processSegmentImages(this.aigcInfos)
      // console.log(`\n 🚀🚀 %c  批量高清抠图结果  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, res);

      const original_count = segmentResult.filter(i => i.status == "original").length
      if (original_count) this.$notify(`${original_count}个图像抠图失败，可点击它们以重新抠图`)

      Toast.clear()

      const groups = segmentResult.map(i => {
        return {
          url: i.url,
          options: {
            type: 6,
          },
        }
      })

      this.addGroup(groups, "random") // 批量添加至画布，并进行排版
      this.SET_AIGC_INFOS(null) // 将aigcInfos置空
    }
  },
  unmounted() {
    this.destroyBus()
  },
  activated() {
    console.log("activated-----")
    // this.destroyBus();
    // this.initBus();
    if (this.$route.query.fromAvatar) {
      if (this.mcId) {
        this.canvas &&
          this.canvas.getObjects().forEach(o => {
            if (o.isAvatar) {
              this.canvas.remove(o)
            }
          })
      }
      const options = { fromAvatar: true, ...this.getAvatarColor, type: 1 }
      if (this.perspective === this.currentPerspective) this.addSvg({ url: this.getAvatarSvg, options })
    }
    // todo: 这里还是需要测试，代码的addSvg添加贴纸，应该会执行两次。
    if (this.perspective == this.currentPerspective && this.stickerInfo) {
      this.addSvg(this.stickerInfo)
      this.SET_STICKER_INFO(null)
    }
  },
  deactivated() {
    this.destroyBus()
  },
  computed: {
    ...mapGetters(["getColorSeriesId", "getColorConfig", "getColorLibrary", "getImage", "getAvatarSvg", "getAvatarColor", "getSleeveImages"]),
    ...mapState([
      "activeColorKey",
      "activeColor",
      "templateId",
      "designJSON",
      "fontList",
      "mcConfig",
      "mcId",
      "carrierConfig",
      "elementIds",
      "ipIds",
      "outputCanvasImages",
      "outputPreviewImages",
      "designJSONs",
      "currentIpId",
      "zoomToggle",
      "currentPerspective",
      "stickerInfo",
      "objectIsEmpty",
      "productId",
      "channel",
      "uploadedImage",
      "removeNotUseSticker",
      "historyRedo",
      "historyUndo",
      "interceptImg",
      "aigdInfo",
      "aigcInfo",
      "aigcInfos",
      "interceptStatus",
      "arrangeCount",
      "tipsType"
    ]),
    // isUndoDisable() {
    //   // this.$logs("撤回上一步", this.canvas.getObjects().filter(o => o.typeId));
    //   return this.canvas && this.canvas.getObjects().filter(o => o.typeId).length == 0 ? true : false;
    // },
  },
  components: {},
}
</script>

<style></style>
