import { fabric } from "fabric-with-gestures-v5";
import { iconCornerDuplicate, iconCornerRemove, iconCornerEdit, iconCornerRotate, iconCornerScale } from "./icons";
import bus from "@/utils/eventbus";
import store from "@/store";

const { state, commit } = store;

//2023年2月2日19:18:07
import { log, logs } from "../utils";

import SVG_CONFIG from "./svg_features_config";
// log("control.js feats", SVG_CONFIG);

export default {
  canvas: {},
  $bus: bus,
  renderControls(canvas, perspective) {
    this.canvas[perspective] = canvas;
    fabric.Object.prototype.set({
      transparentCorners: false, //是否是空心点
      cornerColor: "#000", // 黑色点
      cornerSize: 6, //大小
      cornerStyle: "rect", // 点的形状
      borderColor: "#000", // 边框颜色
      borderWidth: 2, // 边框的厚度
      lockScalingFlip: true, //锁定翻转角
      padding: 5, // 离元素真实边缘的间距
    });
    this.renderCorner("duplicateControl", iconCornerDuplicate, {
      //复制
      x: -0.5,
      y: 0.5,
      mouseUpHandler: this._duplicateObject(),
    });

    this.renderCorner("editControl", iconCornerEdit, {
      //编辑
      x: 0.5,
      y: -0.5,
      mouseUpHandler: this._editObject(),
    });

    this.renderCorner("removeControl", iconCornerRemove, {
      //删除
      x: -0.5,
      y: -0.5,
      mouseUpHandler: this._removeObject(),
    });

    this.renderCorner("scaleAndRotateControl", iconCornerScale, {
      //缩放和旋转
      x: fabric.Object.prototype.controls.br.x,
      y: fabric.Object.prototype.controls.br.y,

      // 同时触发旋转和缩放，但是交互卡顿，暂时注释掉。
      actionHandler: function (eventData, target, x, y) {
        // console.log("scaleAndRotateControl", target);
        fabric.Object.prototype.controls.br.actionHandler(eventData, target, x, y);
        fabric.Object.prototype.controls.mtr.actionHandler(eventData, target, x, y);
        return true; // 一定要返回true，否者旋转和缩放会卡顿
      },

      // actionHandler: fabric.Object.prototype.controls.br.actionHandler,// 缩放
      // actionHandler: fabric.controlsUtils.rotationWithSnapping,// 旋转
    });

    // this.renderCorner("scaleControl", iconCornerScale, {
    //   //缩放
    //   x: fabric.Object.prototype.controls.br.x,
    //   y: fabric.Object.prototype.controls.br.y,
    //   actionHandler: fabric.Object.prototype.controls.br.actionHandler,// 缩放
    // });

    // this.renderCorner("rotateControl", iconCornerRotate, {
    //     //旋转
    //     x: fabric.Object.prototype.controls.mtr.x,
    //     y: fabric.Object.prototype.controls.mtr.y,
    //     offsetY: fabric.Object.prototype.controls.mtr.offsetY,
    //     actionHandler: fabric.controlsUtils.rotationWithSnapping,
    //     cursorStyleHandler: fabric.controlsUtils.rotationStyleHandler,
    //     actionName: "rotate",
    // });

    // 处理默认按钮
    fabric.Object.prototype.setControlsVisibility({
      mtr: false,// 上方的旋转按钮
      // mt: false,// 顶部中间的控制点
      // mb: false,// 底部中间的控制点
      // ml: false,// 左侧中间的控制点
      // mr: false,// 右侧中间的控制点
      // bl: false,// 左下角的控制点
      // br: false,// 右下角的控制点
      // tl: false,// 左上角的控制点
      // tr: false,// 右上角的控制点 
    });


    fabric.Textbox.prototype.controls = fabric.Object.prototype.controls; //将Textbox的Controls替换成Object的Controls
    fabric.Textbox.prototype.setControlsVisibility({
      //主要配置控制点的显隐
      mt: false,
      mb: false,
      ml: false,
      mr: false,
      tr: false,
    });
  },
  renderCorner(corner, icon, options) {
    /*
     * 中点: (0, 0)
     * 左下角: (-0.5, 0.5)
     * mouseUpHandler 是主要方法
     */

    const cornerOptions = {
      x: 0.5,
      y: -0.5,
      offsetY: 0, //设定偏移量
      offsetX: 0,
      cursorStyle: "pointer", // 鼠标悬浮图标
      render: this.renderIcon(icon), // 此按钮的图片
      cornerSize: 24, //按钮热区
      ...options,
    };
    fabric.Object.prototype.controls[corner] = new fabric.Control(cornerOptions);
  },
  renderIcon(icon) {
    return function renderIcon(ctx, left, top, styleOverride, fabricObject) {
      const size = this.cornerSize;
      ctx.save();
      ctx.translate(left, top);
      ctx.rotate(fabric.util.degreesToRadians(fabricObject.angle));
      ctx.drawImage(icon, -size / 2, -size / 2, size, size);
      ctx.restore();
    };
  },
  _duplicateObject() {
    return () => {
      if (!this.canvas[state.currentPerspective].oMoving) {
        //如果元素已经移动好静止

        this.canvas[state.currentPerspective].getActiveObject().clone(
          cloned => {
            console.log({ cloned });
            if (cloned.type === "activeSelection") {
              //如果是多选
              this.canvas[state.currentPerspective].offHistory();
              cloned.canvas = this.canvas[state.currentPerspective];
              cloned.forEachObject(obj => {
                obj.set({
                  evented: true,
                  padding: this.canvas[state.currentPerspective].getActiveObject().padding,
                  lockScalingFlip: this.canvas[state.currentPerspective].getActiveObject().lockScalingFlip,
                  controls: fabric.Object.prototype.controls,
                });

                if (obj.type === "textbox") {
                  cloned.set({
                    editable: false, // 重新设置字体属性
                  });
                }
                this.canvas[state.currentPerspective].add(obj);
              });
              cloned.set({
                left: cloned.left + 10,
                top: cloned.top + 10,
                evented: true,
              });
              cloned.setControlsVisibility({
                mt: false,
                mb: false,
                ml: false,
                mr: false,
                tr: false,
                editControl: false,
              });
              cloned.setCoords();
            } else {
              // 选中单个元素
              cloned.set({
                left: cloned.left + 10,
                top: cloned.top + 10,
                evented: true,
                padding: this.canvas[state.currentPerspective].getActiveObject().padding,
                lockScalingFlip: this.canvas[state.currentPerspective].getActiveObject().lockScalingFlip,
                controls: fabric.Object.prototype.controls,
              });
              console.log(cloned, "cloned");

              if (cloned.typeId == 1 || cloned.type == "group" || cloned.type == "image") {
                cloned.setControlsVisibility({
                  mt: false,
                  mb: false,
                  ml: false,
                  mr: false,
                  tr: false,
                  editControl: false,
                });
              }

              if (cloned.type === "textbox") {
                cloned.set({
                  editable: false,
                });
              }

              this.canvas[state.currentPerspective].add(cloned);
            }
            this.canvas[state.currentPerspective].goOnHistory();
            this.canvas[state.currentPerspective].setActiveObject(cloned); //选中克隆出来的元素
            this.canvas[state.currentPerspective].renderAll();
          },
          ["currentColor"],
        );
      }
      this.canvas[state.currentPerspective].oMoving = false;
    };
  },
  _removeObject() {
    //移除选中Object
    return () => {
      bus.$emit("hideMenuPopup");
      if (!this.canvas[state.currentPerspective].oMoving) {
        if (this.canvas[state.currentPerspective].getActiveObjects().length > 1) {
          this.canvas[state.currentPerspective].offHistory();

          this.canvas[state.currentPerspective].getActiveObjects().forEach(o => {
            const isLastSticker = this.canvas[state.currentPerspective].getObjects().filter(o => o.typeId == "1").length == 1;
            if (o.typeId == "1" && isLastSticker) {
              console.log(o.typeId);
              bus.$emit(state.currentPerspective + ":unlockColorConfig"); // 针织已无用
            }
            this.canvas[state.currentPerspective].remove(o);
          });
          this.canvas[state.currentPerspective].goOnHistory();
        } else {
          const isLastSticker = this.canvas[state.currentPerspective].getObjects().filter(o => o.typeId == "1").length == 1;
          if (this.canvas[state.currentPerspective].getActiveObject().typeId == "1" && isLastSticker) {
            bus.$emit(state.currentPerspective + ":unlockColorConfig");
          }
          this.canvas[state.currentPerspective].remove(this.canvas[state.currentPerspective].getActiveObject());
        }
        this.canvas[state.currentPerspective].discardActiveObject().renderAll();
        this.removeObject && this.removeObject();
      }
      this.canvas[state.currentPerspective].oMoving = false;

      commit("SET_OBJECT_IS_EMPTY", state.objectIsEmpty);

      // 如果画布没有元素了，且之前添加的是 不共用元素，重置状态
      if (state.objectIsEmpty && state.hasAddedNotUseSticker) commit("SET_REMOVE_NOT_USE_STICKER", false);
      
    };
  },
  _editObject() {
    // 编辑
    return () => {
      const object = this.canvas[state.currentPerspective].getActiveObject();
      const isText = object.type === "textbox";
      if (isText) {
        const textContent = object.text;
        const sliderMinVal = object.minScaleLimit;
        const sliderVal = object.scaleX;
        const colorKey = object.colorKey;
        console.log(colorKey);
        this.$bus.$emit("showMenuPopup", {
          //唤出文字\颜色\样式弹窗
          isText,
          textContent,
          sliderMinVal,
          sliderVal,
          colorKey,
          tab: "color",
        });
      } else {
        const colorKey = object.colorKey;
        this.$bus.$emit("showMenuPopup", {
          isText,
          colorKey,
          tab: "color",
        });
      }
    };
  },
  on(event, cb) {
    this[event] = cb;
  },
};
