export default {
  merId:null,
  menuStatus: false, //菜单栏的状态

  arrangeCount: 0, // 选择用于排版的数量，也用于控制排版按钮的显示状态

  interceptStatus: "hide", //图片截取状态，hide：隐藏， showMenu：显示主菜单， crop：图片裁切， segment：一键抠图 hd：图片高清
  interceptImg: {// 正在操作的的图片url
    imgUrl: "", // 用于在画布上展示的图片url
    originImgUrl: "",// CND OSS 原图url
    segmentedImgUrl: "",// 上一次一键抠图的结果图
    croppedImgUrl: "",// 上一次的图片url
  }, 
  uploadedImage: "",// 用户刚上传的图片url， oss 原始图片

  isIntercept: false, //图片截取状态

  carrierSwitchStatus: false,
  carrierSwitchMain: false,
  patternList: [], //图案列表 布片颜色图案
  fontList: [], // 字体列表
  searchStatus: false, // 搜索的popup的状态
  searchType: "", // 搜索类型的切换
  historyCodeType: "", // deprecated
  productId: null, // 微信小程序跳转过来的参数
  excludeSticker: false, //服装定制页面
  channel: "wx", //渠道, wx微信小程序,tt抖音小程序, admin 中台
  carrier_id: "", // 载体id
  store_name: "", // 规格
  price: "", //价格
  image: "", // 商品图
  value1: "", //deprecated
  value2: "", //deprecated
  token: "",
  stickerId: "", //传过来的贴纸的id
  seriesId: "", //系列id 用于部分材质载体不能使用贴纸, 载体列表做筛选
  color_id: "", // deprecated
  x1: "", // deprecated
  y1: "", // deprecated
  x2: "", // deprecated
  y2: "", // deprecated
  carrierWidth: "", // deprecated
  is_knitting: 0, // 是否是针织
  avatarType: 1, // 头像类型 1 像素 2 扁平
  unique: "", // 载体唯一标识
  isTemplate: "", // deprecated
  templateId: "", // 有值就是二次定制，模板ID
  mcId: "", //企业半定制id
  mcMenu: "", //企业半定制菜单附加条件
  extAvatarId: "", //微信小程序传过来的贴纸id
  designProductId: null, // 购物车跳转过来传入的设计商品id
  isChild: 0, //区分是否是mini tootools
  isIndex: 0, // 区分载体贴纸排序显示
  actName: null, // 活动名称
  currentCateId: "",

  designJSON: "", //设计json (deprecated)
  //保存设计的参数
  avatarElementIds: [], // 头像设计每个部位的id
  // 背景框的id
  background_id: null, // deprecated
  avatarId: null, //头像设计id
  // 第一次的addSvg
  addSvgStatus: true,
  addChannel: "", //活动名称
  addName: "",
  carrierSize: "M",
  colorLibrary: [], //颜色库 中台里的所有颜色
  colorConfig: {
    // 有四色限制的时候
    defaultColor: {
      //载体颜色
      color: "",
      id: null,
      seriesId: null,// 色系id
      type: null,
      title: "",
      isLocked: true,
      coverImg: "",
    },
    primaryColor: {
      //主颜色,当前颜色
      color: "",
      id: null,
      seriesId: null,// 色系id
      type: null,
      title: "",
      isLocked: false,
      coverImg: "",
    },
    secondaryColor: {
      // deprecated
      color: "",
      id: null,
      seriesId: null,// 色系id
      type: null,
      title: "",
      isLocked: false,
      coverImg: "",
    },
    tertiaryColor: {
      // deprecated
      color: "",
      id: null,
      seriesId: null,// 色系id
      type: null,
      title: "",
      isLocked: false,
    },
  },
  activeColorKey: "primaryColor", //通过primaryColor代理的
  sliderVal: 0, // deprecated
  avatarPartMap: [],
  avatarSvg: "",
  avatarColor: {
    //头像颜色
    value1: "#3F3F3F",
    value2: "",
    value3: "",
  },
  canvasObjects: [], //给辅助线用的
  outputCanvasImage: null, //输出的设计图片(deprecated)
  outputPreviewImage: null, //输出的预览图片(deprecated)
  outputAvatarImage: null, // 输出的avatar图片
  outputAvatarSVG: null, //输出的avatar svg
  //Mass Customization
  mcConfig: {},
  currentPerspective: "front", // 当前衣服的面
  currentIpId: "", //当前的Ip id
  objectIsEmpty: {
    //衣服的各个面是否为空, 里面包含了各个面的状态
    front: true,
    back: true,
    left: true,
    right: true,
  }, 
  leftSleeveImage: null, //左袖在正/反面的输出图
  rightSleeveImage: null, //右袖在正/反面的输出图
  carrierConfig: {}, //载体配置信息
  outputCanvasImages: {
    //输出的设计图片(多面)
    front: null,
    back: null,
    left: null,
    right: null,
  },
  outputPreviewImages: {
    //输出的预览图片(多面)
    front: null,
    back: null,
    left: null,
    right: null,
  },
  designJSONs: {
    //设计JSON
    front: null,
    back: null,
    left: null,
    right: null,
  },
  elementIds: {
    // 所用的贴纸的elementID的集合
    front: "",
    back: "",
    left: "",
    right: "",
  },
  ipIds: {
    //所用的贴纸的ipid
    front: "",
    back: "",
    left: "",
    right: "",
  },
  zoomToggle: false, // true为放大 false为 1.5
  stickerInfo: null,
  isDrawingMode: false,
  activeColor: "",

  fontTextValue: "",
  shareDesignPosterData: {},
  sharePosterImg: "",

  hasAddedNotUseSticker: false, // 是否添加过不共用贴纸
  removeNotUseSticker: false,// 是否需要清除画布中的不共用贴纸

  historyRedo: 0, // redo历史记录, 步数
  historyUndo: 0, // undo历史记录，步数，这两个存步数，不存设计json

  aigcInfo: null,// aigc结果信息
  aigcInfos: null,// 多条aigc结果信息
  aigdInfo: null,// aigd结果信息
  isAigdMode: false,// 是否是aigd模式

  adminMessage: null,// 来自中台的指令
  
  menus: [],// 创作中心功能菜单

  tipsType: "hide",// 设置提示类型, 默认隐藏

  lastDesignTimestamp: new Date().getTime(),// 上次设计时间
};
