<template>
  <router-view v-slot="{ Component }">
    <keep-alive>
      <component :is="Component" :key="$route.matched[0]?.name" v-if="$route.meta.keepAlive"></component>
    </keep-alive>
    <component :is="Component" :key="$route.matched[0]?.name" v-if="!$route.meta.keepAlive" />
  </router-view>
</template>
<script>
import { wxConfig, getMerchantCarrier } from "./api/app"
import { mapActions, mapMutations } from "vuex"
import { getItem } from "@/utils/utils"
import VConsole from "vconsole"
import { Toast } from "vant"

export default {
  name: "App",
  data() {
    return {
      hasQuery: false,
    }
  },
  async created() {
    await wxConfig()

    document.addEventListener("UniAppJSBridgeReady", function () {
      // eslint-disable-next-line no-undef
      uni.getEnv(function (res) {
        console.log("当前环境：" + JSON.stringify(res))
      })
      // eslint-disable-next-line no-undef
      uni.postMessage({
        data: {
          action: "postMessage",
        },
      })
      window.postMessage({
        data: {
          action: "postMessage",
        },
      })
    })
    //异常捕获
    /**
     * @param {String}  message    错误信息
     * @param {String}  source     出错文件
     * @param {Number}  lineno     行号
     * @param {Number}  colno      列号
     * @param {Object}  error      Error对象（对象）
     */
    window.onerror = function (message, source, lineno, colno, error) {
      console.log("捕获到异常：", { message, source, lineno, colno, error })
    }

    // 关闭线上调试工具，仅允许在开发环境使用
    !(process.env.VUE_APP_ENV === "production") && new VConsole()

    // if(!this.hasQuery) {
    //   this.initQuery();
    // }

    // 全局监听message事件
    window.addEventListener("message", (event) => {
      // 全局注册事件
      const { action, from } = event.data;
      if (from == "admin") this.SET_ADMIN_MESSAGE(action);
    });

  },
  beforeUnmount() {
    window.removeEventListener("message", () => {
      // console.log(`\n 🚀🚀 %c 创作中心移除 message removeEventListener  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`);
    });
  },
  watch: {
    $route: {
      async handler() {
        if (!this.hasQuery) {
          await this.initQuery();
          this.hasQuery = true;
        }
        if (this.$route.path == "/sticker" && !this.$store.state.otherMenu) {
          if (this.$store.state.menuStatus) {
            this.$store.commit("CHANGE_MENU_STATUS", false);
          }
        }
      },
      deep: true,
    },
  },
  methods: {
    ...mapMutations({
      setQueryString: "SET_QUERY_STRING",
      CHANGE_MENU_STATUS: "CHANGE_MENU_STATUS",
      SET_AIGD_INFO: "SET_AIGD_INFO",
      SET_AIGD_MODE: "SET_AIGD_MODE",
      SET_AIGC_INFO: "SET_AIGC_INFO",
      SET_AIGC_INFOS: "SET_AIGC_INFOS",
      SET_ADMIN_MESSAGE: "SET_ADMIN_MESSAGE",
    }),
    ...mapActions(["fetchColorLibrary", "fetchCarrierConfig", "fetchFontList", "fetchMenus", "fetchDesignJSON"]),

    // 解析参数
    async initQuery() {
      console.log("route!", this.$route);
      const { query } = this.$route;

      try {
        await this.fetchMenus(query.mer_id);// 进入就查询菜单
      } catch (error) {
        console.log(`\n 🚀🚀 %c  App.vue获取菜单报错  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, error);
      }

      let needQueryDesignJSON = false;// 是否需要请求设计json， 加载模版

      if (query) {
        // let config = {
        //   carrier_id: query.carrier_id,
        //   store_name: query.store_name,
        //   price: query.price,
        //   image: query.image,
        //   value1: query.value1,
        //   value2: query.value2,
        //   token: query.token,
        //   stickerId: query.sticker_id,
        //   color_id: query.color_id,
        //   x1: query.x1,
        //   y1: query.y1,
        //   x2: query.x2,
        //   y2: query.y2,
        //   carrierWidth: query.carrierWidth,
        //   is_knitting: query.is_knitting,
        //   avatarType: query.avatarType,
        //   unique: query.unique,
        //   isTemplate: query.is_template ? query.is_template : null,
        //   templateId: query.template_id ? query.template_id : null,
        //   // mass customization id
        //   mcId: query.mc_id ? query.mc_id : null,
        // };

        let config = {
          isGetDesignId: query.get_design_id ? "0" : null,// 是否获取设计，做同款等场景，保存设计时，design_id要设为0
          productId: query.design_product_id || query.get_design_id || null,
          designProductId: query.design_product_id || query.get_design_id || null,
          carrier_id: query.carrier_id || null,
          unique: query.unique || null,
          token: query.token || getItem("token"),
          stickerId: query.sticker_id || undefined,
          avatarType: query.avatarType || undefined,
          templateId: query.template_id ? query.template_id : null,
          mcId: query.mc_id ? query.mc_id : null,
          mcMenu: query.mc_menu ? query.mc_menu : null,
          extAvatarId: query.ext_avatar_id,
          channel: query.channel || getItem("channel"),
          isChild: query.is_child || getItem("isChild"),
          seriesId: query.series_id,
          isIndex: query.is_index || getItem("isIndex"),
          actName: query.act_name || getItem("actName"),
          addChannel: query.ad_ch ? query.ad_ch : null,
          addName: query.ad_na ? query.ad_na : null,
          currentCateId: query.cate_id,
          merId: query.mer_id || getItem("merId"),
          merType: query.mer_type || 1,// 默认为1，工厂店模式。
          // aigcImg: query.aigc_img ? query.aigc_img : "https://mer-oss.tootools.cn/def/c7fa8202306021514376066.png",
          aigcImg: query.aigc_img ? query.aigc_img : null,
          aigcImgs: query.aigc_imgs || null,// 多个aigc结果，适用于选多张AI贴纸的场景
          aigdImg: query.aigd_img ? query.aigd_img : null,
          aigdDesignId: query.aigd_design_id ? query.aigd_design_id : null,
          is_admin: query.channel == "admin" ? 1 : null,
        };

        needQueryDesignJSON = config.templateId || config.productId || false;

        // 如果没有carrier_id和unique，需要查询该店铺的默认值
        if (config.carrier_id == null || config.unique == null) {
          let result = await this.fetchDefaultCarrier(config.merId);
          if (!result) return;// 请求失败，不执行后续
          config = Object.assign(config, result);
        }

        console.log(`\n 🚀🚀 %c  解析完参数结果：  `, `color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, config);

        // 当同时有aigd图片和aigd设计id时，才设置aigd信息
        if (config.aigdImg && config.aigdDesignId) {
          this.SET_AIGD_INFO({
            url: config.aigdImg,
            design_id: config.aigdDesignId,
          })
          this.SET_AIGD_MODE(true);
        }

        // 当同时有aigc图片时，设置aigc信息
        if (config.aigcImg) {
          this.SET_AIGC_INFO({
            url: config.aigcImg,
            options: {
              value: null,
              value1: null,
              value2: null,
              value3: null,
              type: 6,// aigc, 当做是我的素材6
            },
          });
          setTimeout(() => {
            this.CHANGE_MENU_STATUS(true);
            if (config.aigcImg) this.$router.replace("/sticker/matter?type=ai");
          }, 1000);
        };

        // 当有多个aigc图片时，多个AI贴纸 
        if (config.aigcImgs) {
          // 组装aigc完整图片地址，现在单店多店AIGC图片都存于mp-oss.tootools.cn
          const aigcImgsInfo = config.aigcImgs.split("/");
          const uid = aigcImgsInfo[0];
          if (uid == null || uid == undefined) return Toast("处理AI贴纸失败");
          const aigcImgs = aigcImgsInfo[1].split(",");
          const realAigcImgs = aigcImgs.map(item => `https://mp-oss.tootools.cn/ai/user/${uid}/result/${item}`);
          this.SET_AIGC_INFOS(realAigcImgs);
          // console.log(`\n 🚀🚀 %c  realAigcImgs  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, realAigcImgs);
        };

        // console.log("config", config);

        if (config.addChannel) {
          console.log("记录广告来源", config.addChannel, config.addName)
          window._hmt.push(["_trackEvent", `add_channel:${config.addChannel}`, `add_channel:${config.addChannel},add_name:${config.addName}`])
        }

        await this.setQueryString(config)
        Object.keys(config).forEach(key => {
          if (config[key] == undefined || config[key] == null) {
            if (localStorage.getItem(key)) {
              window.localStorage.removeItem(key)
            }
            delete config[key]
            return
          }
          localStorage.setItem(key, config[key])
        })
      };

      // 获取色库和载体配置
      await this.fetchColorLibrary()
      await this.fetchCarrierConfig()
      await this.fetchFontList()
      // 获取设计json
      if (needQueryDesignJSON) await this.fetchDesignJSON();

    },

    // 获取默认的carrier_id和unique
    async fetchDefaultCarrier(mer_id) {
      const { data } = await getMerchantCarrier(mer_id, { page: 1, limit: 1 });
      const { status, message, data: carrierData } = data;
      if (status !== 200 || !carrierData.length) {
        Toast(message || "获取载体信息失败");
        return Promise.reject(false);
      }
      const { carrier_id, unique } = carrierData[0];
      return Promise.resolve({ carrier_id, unique });        
    },
  },
}
</script>
<style lang="scss">
html,
body {
  @media screen and (min-width: 768px) and (max-width: 900px) {
    // font-size: 35px;
  }

  // font-size: 16px;
  padding: 0;
  margin: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

#app {
  width: 100%;
  height: 100%;
}
</style>
