/* eslint-disable */
import axios from "axios"
import { HTTP_REQUEST_URL, api, HTTP_REQUEST_URL_AI, HTTP_REQUEST_URL_NODE, apiAi } from "../config/api"
import store from "../store"
import OSS from "ali-oss"
import md5 from "md5"

// 请求拦截器
axios.interceptors.request.use(res => {

  // 处理token
  const httpToken = store.state.token || localStorage.getItem("token")
  res.headers["X-Token"] = httpToken

  // 是在admin中台访问，加上is_admin=1
  if (localStorage.getItem("is_admin")) res.headers["Is-Admin"] = 1
  
  return res
  
})

// todo： 响应拦截器， 一些异常情况集中处理。 23-11-25. 
// axios.interceptors.response.use(response => {
//   return response;
// }, error => {
//   // 处理错误
//   console.error(error);
// });


// const config = {
// 	headers: {
// 		"Content-Type": "multipart/form-data",
// 		"Access-Token": token,
// 		//因为我的请求内容只有file，如果formdata复杂的话，建议参考上面的链接
// 		transformRequest: [data => data]
// 		}
// 	}

export const wxConfig = async () => {
  const { data } = await axios.get(`${HTTP_REQUEST_URL}/wechat/config`, {  
    params: {
      url: window.location.href.split("#")[0],
    },
  })
  if (data.status === 200) {
    // console.log(`\n 🚀🚀 %c  wxConfig  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, data);
    // try {
    //   if (data.data.jsApiList.indexOf("getLocalImgData") === -1) {
    //     data.data.jsApiList.push("getLocalImgData");
    //   }
    // } catch (error) {
    //   console.log(`\n 🚀🚀 %c  组装jsApiList出错  `,`color: #fadfa3; background: #030307; padding: 5px`, `\n\n`, error);
    // }
    wx.config({ ...data.data });
  } else {
    console.log("获取微信配置失败")
  };

}

const HTTP = {
  get: async (api, params) =>
    await axios.get(`${HTTP_REQUEST_URL}${api}`, {
      params,
    }),
  post: async (api, params, options) => await axios.post(`${HTTP_REQUEST_URL}${api}`, params, options),
  delete: async (api, params, options) => await axios.delete(`${HTTP_REQUEST_URL}${api}`, params, options),
}

const HTTP_NODE = {
  get: async (api, params) =>
    await axios.get(`${HTTP_REQUEST_URL_NODE}${api}`, {
      params,
    }),
  post: async (api, params, options) => await axios.post(`${HTTP_REQUEST_URL_NODE}${api}`, params, options),
}

const HTTPAI = {
  get: async (api, params) =>
    await axios.get(`${HTTP_REQUEST_URL_AI}${api}`, {
      params,
    }),
  post: async (api, params, options) => await axios.post(`${HTTP_REQUEST_URL_AI}${api}`, params, options),
}

async function initOSS() {
  let res = {}
  let localOSSData // 定义存储在客户端localstorage中的数据

  if (localStorage.getItem("initOssData") && localStorage.getItem("initOssData").length > 2) {
    localOSSData = JSON.parse(localStorage.getItem("initOssData")).data
    // 判断oss是否在有效期,返回的时间戳是按照秒计算，非毫秒，需要*1000
    if (localOSSData.data.expireTime * 1000 < Date.now() - 1000) {
      res = await getOSSToke()
    } else {
      res = JSON.parse(localStorage.getItem("initOssData"))
    }
  } else {
    res = await getOSSToke()
  }

  const { data } = res.data
  const options = {
    endpoint: data.endpoint, // mp-oss.tootools.cn
    savePath: data.savePath,
  }
  delete data.endpoint
  delete data.savePath
  const client = new OSS(data)
  client.savePath = options.savePath
  console.log(client)
  return { client, options }
}

async function getOSSToke() {
  let res = await HTTP.get(api.get_OSS_TOKEN)
  localStorage.setItem("initOssData", JSON.stringify(res))
  return res
}

export async function uploadOSS(file) {
  const { client, options } = await initOSS()
  const fullPath = `${client.savePath}/${file.name}`
  const d = new Date()
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
  const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
  const hour = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`
  const name = `${md5(fullPath).slice(0, 5)}${d.getFullYear()}${month}${day}${hour}${d.getMinutes()}${d.getSeconds()}${parseInt(Math.random(0, 1) * 1000)}`
  console.log(`${options.savePath}`)

  const result = await client.put(`${options.savePath}/${name}.png`, file)
  return { result, options }
}

export async function uploadOSSImg(file) {
  const { client, options } = await initOSS()
  const fullPath = `store/comment/${file.name}`
  const type = file.type.split("/")[1]
  const d = new Date()
  const month = d.getMonth() + 1 < 10 ? `0${d.getMonth() + 1}` : `${d.getMonth() + 1}`
  const day = d.getDate() < 10 ? `0${d.getDate()}` : `${d.getDate()}`
  const hour = d.getHours() < 10 ? `0${d.getHours()}` : `${d.getHours()}`
  const name = `${md5(fullPath).slice(0, 5)}${d.getFullYear()}${month}${day}${hour}${d.getMinutes()}${d.getSeconds()}${parseInt(Math.random(0, 1) * 1000)}`
  console.log(`${options.savePath}`)
  const result = await client.put(`${options.savePath}/${name}.${type}`, file)
  return { result, options }
}

//获取字体列表 --商户
export function getFontList(params) {
  return HTTP.get(api.get_font_list, params)
}

//贴纸
export function getElement(params) {
  return HTTP.get(api.get_element, params)
}

//艺术家
export function getSeries(params) {
  return HTTP.get(api.get_series, params)
}

//搜索 收藏 (创作中心我的收藏也可以用)
export function search(params) {
  params.is_knitting = store.state.carrierConfig.is_knitting
  return HTTP.get(api.search + `/${store.state.merId}`, params)
}
// 收藏

export function setSeriesCollect(params) {
  return HTTP.post(api.post_setCollection, params)
}

//获取载体详情列表
export function getProductCarrierDetailList(params) {
  return HTTP.get(api.get_productCarrierDetailList, params)
}
// 搜索历史
export function getShopSearchHistory(params) {
  return HTTP.get(api.get_shopSearchList, params)
}
//删除搜索历史
export function getClearSearch(params) {
  return HTTP.get(api.getClearSearch, params)
}
// 删除字体
export function SetCollections(params) {
  return HTTP.post(api.postSetCollections, params)
}
//提交设计
export function postProductSaveDesignProduct(params, options) {
  return HTTP.post(api.post_productSaveDesignProduct, params, options)
}

//加入购物车
export function postCartCreate(params, options) {
  return HTTP.post(api.post_cart_create, params, options)
}

//获取商品详情
export function getProductDetail(params) {
  return HTTP.get(api.get_productDetail, params)
}

//获取元素列表
export function getShopElementsList(params) {
  return HTTP.get(api.get_shopElementsList + "/" + store.state.merId, params)
}

//获取ip列表
export function getIpTabList(params) {
  return HTTP.get(api.get_ip_list + `/${store.state.merId}`, params)
}
//获取ip列表
export function getIpTabDetail(params) {
  return HTTP.get(api.get_ip_detail + `/${store.state.merId}`, params)
}

// 获取元素价格信息

export function getElementPriceInfo(params) {
  return HTTP.get(api.get_elementPriceInfo, params)
}

export function getTemplateInfo(id) {
  return HTTP.get(api.get_getTemplateInfo + id)
}

//上传图片接口
export function postUploadImage(params, options) {
  return HTTP.post(api.post_uploadImage, params, options)
}

/**
 * buddha 接口
 */

export function getElements(params) {
  return HTTP.get("/toopeeks/getElements", params)
}

export function saveToopeeks(params) {
  return HTTP.post("/toopeeks/save", params)
}
/**
 * 获取小程序分享专属码
 */
export function getShareQRcode(params) {
  return HTTP.post("/toopeeks/share", params)
}
export function createdCoupon(params) {
  return HTTP.post("/toopeeks/code", params)
}

export function getMCCustomInfo(id) {
  return HTTP.get(api.get_MCCustomInfo + id)
}

export function getCarrierConfig(params) {
  return HTTP.get(api.get_carrierConfig, params)
}

export function getAvatarDetail(params) {
  return HTTP.get(api.get_AVATAR_DETAIL, params)
}

/**
 * 人脸识别
 * @param {*} id
 * @returns
 */
export function getAIFace(params) {
  return HTTPAI.post(apiAi.post_predict, params, {
    headers: {
      "x-api-key": "C7Inb8gEHVf/3IF8f483SijuWhNW3t7mRMa7PrPlKitcJsSwOAWZVBAxrPPEPEKwyXo245oHcnXVK3J4nndmOqosxh62G2R9yx/oT+KeZ5q5S9DlrRAiIYg3i93hV9+ARRKvKQZWOxwwmqRSDxa/bAGgbgS0nclRTAW+6TubJs=",
    },
  })
}
/**
 *
 * @param {*} params
 * @returns
 */

export function postUploadAvatar(params) {
  return HTTP.post(api.post_UPLOAD_AVATAR, params)
}

/**
 * 获取素材列表
 *
 */

export function getMatterList(params) {
  return HTTP.get(api.get_USER_ELEMENTS, params)
}

/**
 * 获取头像的列表数据
 *
 */
export function getUserAvatarInfo(params) {
  return HTTP.get(api.get_USER_INFO_AVATAR, params)
}

// 获取载体列表数据
export function getCategory({ id = 0, params }) {
  return HTTP.get(`${api.get_CATEGORY_LIST}/${store.state.merId}?pid=${id}`, params)
}
/**
 *
 * @param {*} param0 获取分类数据
 * @returns
 */
export function getCategoryDetail({ id, params }) {
  return HTTP.get(`${api.get_CATEGORY_LIST}/${store.state.merId}?pid=${id}`, params)
}

/**
 *
 * @param {*} param0 获取商品详情
 * @returns
 */
export function getCategoryProductList({ id, params }) {
  return HTTP.get(`${api.get_CATEGORY_PRODUCT_LIST}/${store.state.merId}?cate_id=${id}`, params, {})
}

export function carrierDetailList(data) {
  return HTTP.get(`${api.get_PRODUCT_DETAIL}/${data.carrier_id}?mer_id=${store.state.merId}${data.design_id ? '&design_id=' + data.design_id : ''}`)
}

// 贴纸系列 目录图片
export function getSeriesList(params) {
  return HTTP.get(`${api.get_SERIES_LIST}/${store.state.merId}`, params)
}

//对应系列详情图片列表
export function getSeriesDetail(params) {
  return HTTP.get(`${api.get_SERIES_DETAIL}/${params.id}`)
}

// 获取ip列表数据
export function getIpList(params) {
  return HTTP.get(`${api.get_IP_LIST}`, params)
}

// 关注
export function setUserFocus(params) {
  return HTTP.post(`${api.post_USER_DO_FOCUS}`, params)
}

// 获取ip详情
export function getIpInfo(params) {
  return HTTP.get(`${api.get_IP_INFO}`, params)
}

export function postExportCanvas(params) {
  return HTTP_NODE.post(`${api.post_EXPORT_CANVAS}`, params)
}

//删除头像
export function postDelAvatar(params) {
  return HTTP.post(`${api.post_DEL_AVATAR}`, params)
}
//删除自定义素材
export function postDelUserEle(params) {
  return HTTP.post(`${api.post_DEL_USER_ELE}`, params)
}
//保存素材
export function saveUserElements(params) {
  return HTTP.post(`${api.post_SAVE_USER_ELEMENTS}`, params)
}

/**
 * @name: 获取贴纸生成的历史列表
 * @param {page limit}
 * @return {*}
 */
export function getAiDesignHistory(params) {  
  return HTTP.get(`${api.get_AI_DESIGN_HISTORY}`, params)
}

// 获取用户收藏的AI作品
export function getUserAiElements(params) {
  return HTTP.get(`${api.get_USER_AI_ELEMENTS}`, params)
}
// 删除用户收藏的AI作品
export function deleteUserAiElements(params) {
  return HTTP.post(`${api.post_DEL_USER_AI_ELE}`, params)
} 

export function getCartProductDesignDetail(params) {
  return HTTP.get(`${api.get_CART_PRODUCT_DESIGN_DETAIL}`, params)
}
//product/getDesignTempList
export function getDesignTempList(params) {
  return HTTP.get(`${api.get_DESIGN_TEMP_LIST}`, params)
}
export function getDesignTemPJson(params) {
  return HTTP.get(`${api.get_DESIGN_JSON}`, params)
}

export function bindShareDesignPoster(params) {
  return HTTP.post(`${api.post_BIND_SHARE_DESIGN_POSTER}`, params)
}

export function getColorList(params) {
  return HTTP.get(`${api.get_color_list}`, params)
}

// /user/cart/getCartAttrPrice
// 购物车根据数量计算总价
export function getCartAttrPrice(data) {
  return HTTP.post(`${api.post_CART_ATTR_PRICE}`, data)
}

// 批量加入购物车
export function addAllToCart(data) {
  return HTTP.post(`${api.post_ADD_ALL_TO_CART}`, data)
}

// aigc 图片变高清接口，使用aigc的文生图接口，参数固定
export function postAiHdImage(params) {
  return HTTP.post(`${api.post_AI_HD_IMAGE}`, params);
}

export function getAiHdImageStatus(queue_id) {
  return HTTP.get(`${api.get_AI_HD_IMAGE_STATUS}?queue_id=` + queue_id);
}

export function getAiHdImageResult(queue_id) {
  return HTTP.get(`${api.get_AI_HD_IMAGE_RESULT}/` + queue_id);
}

export function deleteAiHdImageTask(queue_id) {
  return HTTP.delete(`${api.delete_AI_HD_IMAGE_TASK}/` + queue_id);
}

/**
 * @description 获取店铺载体列表
 * @param {number} id 
 * @param {Object} data page limit  
 */
export function getMerchantCarrier(id, data) {
	return HTTP.get('/store/merchant/getCarrierIndex/' + id ,data)
}

/**
 * @description: 添加抠图任务，
 * @param {Array} data 需要抠图的图片地址
 * @param {Number String} mer_id 商户id
 * @return {*}
 */
export function addSegmentImageTasks(image_url, mer_id) {
  return HTTP.post('/creation/segmentImage', { image_url, mer_id })
}

/**
 * @description: 获取抠图任务结果
 * @param {Array} queue_ids 任务ids
 * @return {*}
 */
export function getSegmentImageResult(queue_ids) {
  return HTTP.post('/creation/getSegmentResult', { queue_ids })
}

// 获取创作中心菜单功能
export function getCreationMenu(data) {
  return HTTP.get(`/creation/getCreationFunc`, data);
}

export default HTTP
