<template>
  <div class="flex flex-col h-full w-full justify-center items-center">
    <div class="absolute editor-grid w-full h-full -z-10 grid grid-cols-18 divide-x divide-y">
      <div v-for="i in Array(648)" :key="i"></div>
    </div>
    <!-- <button style="z-index: 100000" @click="handleClick">dianji</button> -->
    <EditorContainer />
    <div class="rounded-t-xl bg-white z-20" :class="$route.name === 'savePreview' ? 'absolute h-screen left-0 top-0 w-full' : ''">
      <router-view v-slot="{ Component, route }">
        <keep-alive>
          <component :key="getFirstLevelRoute(route).name" v-if="getFirstLevelRoute(route).meta.keepAlive" :is="Component"></component>
        </keep-alive>
        <component :is="Component" :key="getFirstLevelRoute(route).name" v-if="!getFirstLevelRoute(route).meta.keepAlive" />
      </router-view>
    </div>
    <carrier-info></carrier-info>
    <search-box></search-box>
    <!-- <div :class="{ course_container: activeShow }" class="mask_content">
      <course-mask @closeShow="handleClick"></course-mask>
    </div> -->
  </div>
</template>

<script>
let userTips = null;
let tipStatus = localStorage.getItem("userTips");
if (tipStatus) {
  localStorage.getItem("userTips", true);
  userTips = true;
} else {
  userTips = false;
}

import courseMask from "@/components/content/courseMask/courseMask.vue";
import searchBox from "@/components/content/searchBox/searchBox.vue";
import carrierInfo from "@/components/content/carrierInfo/carrierInfo.vue";
import EditorContainer from "@/components/EditorContainer.vue";
import MenuContainer from "@/components/MenuContainer.vue";

export default {
  name: "HomeView",
  data() {
    return {
      activeShow: userTips,
    };
  },
  methods: {
    handleClick() {
      this.activeShow = !this.activeShow;
    },
    getFirstLevelRoute(route) {
      if (!Array.isArray(route.matched) || route.matched.length === 0) {
        return route;
      }
      return route.matched[0];
    },
  },
  components: {
    courseMask,
    searchBox,
    EditorContainer,
    MenuContainer,
    carrierInfo,
  },
};
</script>

<style lang="scss">
.mask_content {
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
}
.course_container {
  display: none;
}
</style>
