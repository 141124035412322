/*
 * @Description: 元素功能列表
 * @version:
 * @Date: 2023-08-01 16:24:21
 * @LastEditTime: 2023-12-05 16:45:01
 */

const NOT_USE_ID = 15 // 不共用贴纸的ID
const USE_IDS = [ 1, 2, 4, 5, 6, 10, 15, 20, "1", "2", "4", "5", "6", "10", "15", "20"] // 可以共用元素的id列表
/* 
	typeId: 
	1: 头像
	2: 字体
	4: 形状
	5: 贴纸（共用贴纸、画笔）【画笔完成后被视为贴纸，引用共用贴纸的属性】
	6: 素材
	10: 照片上传
	15: 贴纸（不共用贴纸）
	20： AIGD
	头像、贴纸、素材、照片，有【删除】【复制】【放大】
	字体，有【删除】【复制】【放大】【编辑】
	形状，有【删除】【复制】【放大】【编辑】【四边中点的缩放】
*/

/*  
	mt 顶部中间的控制点
	mb 底部中间的控制点
	ml 左侧中间的控制点
	mr 右侧中间的控制点
	bl 左下角的控制点
	br 右下角的控制点
	tl 左上角的控制点
	tr 右上角的控制点 
	duplicateControl 自定义的复制控制点
	editControl 自定义的编辑控制点
	removeControl 自定义的删除控制点
	scaleAndRotateControl 自定义的缩放和旋转控制点
 */

// "scale 等比缩放", "scaleXY 随意拉伸", "move 移动", "rotate 旋转", "clone 复制本身", "color 更改颜色", "outbound 出边界", "overlayFont 叠加字体", "overlaySticker 叠加多个同系列/不同系列/不同IP图案"
// "scale 等比缩放", "move 移动", "rotate 旋转", "outbound 出边界", "overlayFont 叠加字体"; 这5个功能全部都有，无需特别处理。
const graphFeats = ["scale", "scaleXY", "move", "rotate", "clone", "color", "outbound", "overlayFont", "overlaySticker"]

const GRAPH_FEATS_MAPS = [
	{
		name: "both_use_sticker", // 共用系列贴纸
		feats: graphFeats.filter(feat => !["scaleXY", "color"].includes(feat)), // 除了 随意拉伸 、更改颜色 外的所有功能。
		typeId: 5,
		type: 5,
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: true,
			editControl: false,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
	{
		name: "not_both_use_sticker", // 不共用贴纸
		disableBoth: true, // 禁共用
		feats: graphFeats.filter(feat => ["scale", "move", "rotate", "outbound", "overlayFont"].includes(feat)), // 只有 等比缩放、移动、旋转、出边界、叠加字体 功能。
		typeId: 15,
		type: 15,
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: false,
			editControl: false,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
	{
		name: "shape", // 形状
		feats: graphFeats, // 所有功能。
		typeId: 4,
		type: 4,
		controls: {
			mt: true,
			mb: true,
			ml: true,
			mr: true,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: true,
			editControl: true,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
	{
		name: "font", // 字体
		feats: graphFeats.filter(feat => !["scaleXY"].includes(feat)), // 除了 随意拉伸 外的所有功能。
		typeId: 2,
		type: 2,
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: true,
			editControl: true,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
	{
		name: "avatar", // 头像
		feats: graphFeats.filter(feat => !["scaleXY", "color"].includes(feat)), // 除了 随意拉伸 、更改颜色 外的所有功能。
		typeId: 1,
		type: 1,
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: true,
			editControl: false,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
	{
		name: "photo", // 照片上传
		feats: graphFeats.filter(feat => !["scaleXY", "color"].includes(feat)), // 除了 随意拉伸 、更改颜色 外的所有功能。
		typeId: 10, // ?. 这个typeId需要待定？
		type: 10, // ?. 这个typeId需要待定？
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: true,
			editControl: false,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
	{
		name: "aigd", // aigd的素材
		feats: graphFeats.filter(feat => !["scaleXY", "color"].includes(feat)), // 除了 随意拉伸 、更改颜色 外的所有功能。
		typeId: 20, // ?. 这个typeId需要待定？
		type: 20, // ?. 这个typeId需要待定？
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: false,
			editControl: false,
			removeControl: false,
			scaleAndRotateControl: false,
		},
	},
	{
		name: "elements", // 我的素材
		feats: graphFeats.filter(feat => !["scaleXY", "color"].includes(feat)), // 除了 随意拉伸 、更改颜色 外的所有功能。
		typeId: 6,
		type: 6,
		controls: {
			mt: false,
			mb: false,
			ml: false,
			mr: false,
			bl: false,
			br: false,
			tl: false,
			tr: false,
			duplicateControl: true,
			editControl: false,
			removeControl: true,
			scaleAndRotateControl: true,
		},
	},
]

export default {
	config: GRAPH_FEATS_MAPS,
	notUseId: NOT_USE_ID,
	useIds: USE_IDS
}
