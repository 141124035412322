/*
 * @Description:
 * @version:
 * @Date: 2023-06-13 15:29:33
 * @LastEditTime: 2024-02-27 19:38:54
 */
export const SET_PATTERN_LIST = "SET_PATTERN_LIST"
export const SET_FONT_LIST = "SET_FONT_LIST"
export const CHANGE_MENU_STATUS = "CHANGE_MENU_STATUS"
export const CARRIER_SWITCH_STATUS = "CARRIER_SWITCH_STATUS"
export const CARRIER_SWITCH_MAIN = "CARRIER_SWITCH_MAIN"
export const CHANGE_SEARCH_STATUS = "CHANGE_SEARCH_STATUS"
export const SET_QUERY_STRING = "SET_QUERY_STRING"
export const SET_CARRIER_ID = "SET_CARRIER_ID"
export const SET_UNIQUE = "SET_UNIQUE"
export const SET_DESIGN_JSON = "SET_DESIGN_JSON"
export const SET_COLOR_LIBRARY = "SET_COLOR_LIBRARY"
export const SET_COLOR_CONFIG = "SET_COLOR_CONFIG"
export const SET_ACTIVE_COLOR_KEY = "SET_ACTIVE_COLOR_KEY"
export const CHANGE_ADD_SVG = "CHANGE_ADD_SVG"
export const SET_PRODUCT_ID = "SET_PRODUCT_ID"
export const SET_SLIDER_VAL = "SET_SLIDER_VAL"
export const SET_AVATAR_PART_MAP = "SET_AVATAR_PART_MAP"
export const SET_AVATAR_SVG = "SET_AVATAR_SVG"
export const SET_AVATAR_COLOR = "SET_AVATAR_COLOR"
export const SET_AVATAR_ELEMENT_IDS = "SET_AVATAR_ELEMENT_IDS"
export const CHANGE_AVATAR_ELEMENT_ID = "CHANGE_AVATAR_ELEMENT_ID"
export const SET_CANVAS_OBJECTS = "SET_CANVAS_OBJECTS"
export const CHANGE_EXCLUDE_STICKER = "CHANGE_EXCLUDE_STICKER"
export const SET_OUTPUT_CANVAS_IMAGE = "SET_OUTPUT_CANVAS_IMAGE"
export const SET_OUTPUT_PREVIEW_IMAGE = "SET_OUTPUT_PREVIEW_IMAGE"
export const SET_OUTPUT_AVATAR_IMAGE = "SET_OUTPUT_AVATAR_IMAGE"
export const SET_OUTPUT_AVATAR_SVG = "SET_OUTPUT_AVATAR_SVG"
export const SET_BACKGROUND_ID = "SET_BACKGROUND_ID"
export const SET_AVATAR_ID = "SET_AVATAR_ID"
export const SET_MC_CONFIG = "SET_MC_CONFIG"
export const SET_CURRENT_PERSPECTIVE = "SET_CURRENT_PERSPECTIVE"
export const SET_CURRENT_IP_ID = "SET_CURRENT_IP_ID"
export const SET_LEFT_SLEEVE_IMAGE = "SET_LEFT_SLEEVE_IMAGE"
export const SET_RIGHT_SLEEVE_IMAGE = "SET_RIGHT_SLEEVE_IMAGE"
export const SET_CARRIER_CONFIG = "SET_CARRIER_CONFIG"
export const SET_OUTPUT_CANVAS_IMAGES = "SET_OUTPUT_CANVAS_IMAGES"
export const SET_OUTPUT_PREVIEW_IMAGES = "SET_OUTPUT_PREVIEW_IMAGES"
export const SET_DESIGN_JSONS = "SET_DESIGN_JSONS"
export const SET_ELEMENT_IDS = "SET_ELEMENT_IDS"
export const SET_IP_IDS = "SET_IP_IDS"
export const SET_ZOOM_TOGGLE = "SET_ZOOM_TOGGLE"
export const CHANGE_AVATAR_TYPE = "CHANGE_AVATAR_TYPE" // 切换头像类型
export const INIT_AVATAR_DATA = "INIT_AVATAR_DATA" // 初始化avatarId 和 extAvatarId
export const SET_STICKER_INFO = "SET_STICKER_INFO"
export const SET_OBJECT_IS_EMPTY = "SET_OBJECT_IS_EMPTY" // 设置贴纸列表
export const SET_CHILD_STATUS = "SET_CHILD_STATUS"
export const SET_IS_DRAWING_MODE = "SET_IS_DRAWING_MODE"
export const SET_ACTIVE_COLOR = "SET_ACTIVE_COLOR"
export const CLEAR_STICKER_ID = "CLEAR_STICKER_ID"
export const SET_CARRIER_SIZE = "SET_CARRIER_SIZE"

export const CHANGE_INTERCEPT_STATUS = "CHANGE_INTERCEPT_STATUS"
export const CHANGE_INTERCEPT_IMG = "CHANGE_INTERCEPT_IMG"
export const SET_UPLOADED_IMAGE = "SET_UPLOADED_IMAGE"
export const SET_CATE_ID = "SET_CATE_ID"
export const SET_SHARE_CANVAS_DATA = "SET_SHARE_CANVAS_DATA"
export const SET_SHARE_POSTER_IMG = "SET_SHARE_POSTER_IMG"

export const SET_ADDED_NOT_USE_STICKER = "SET_ADDED_NOT_USE_STICKER" // 是否添加了不共用贴纸
export const SET_REMOVE_NOT_USE_STICKER = "SET_REMOVE_NOT_USE_STICKER" // 是否需要清除不共用贴纸
export const SET_HISTORY_STEPS = "SET_HISTORY_STEPS" // 设置历史记录步数

export const SET_AIGD_INFO = "SET_AIGD_INFO";// 设置AIGD信息
export const SET_AIGD_MODE = "SET_AIGD_MODE";// 设置是否是AIGD设计模式
export const SET_AIGC_INFO = "SET_AIGC_INFO";// 设置AIGC信息
export const SET_AIGC_INFOS = "SET_AIGC_INFOS";// 设置多条AIGC信息


export const SET_ADMIN_MESSAGE = "SET_ADMIN_MESSAGE" // 设置中台消息

export const SET_ARRANGE_COUNT = "SET_ARRANGE_COUNT" // 设置排版数量

export const SET_MENUS = "SET_MENUS";// 创作中心功能菜单

export const SET_TIPS_TYPE = "SET_TIPS_TYPE";// 设置提示类型
export const SET_LAST_DESIGN_TIMESTAMP = "SET_LAST_DESIGN_TIMESTAMP";// 设置最后一次设计时间
